import React from 'react';
import { gsap } from 'gsap';
import { Image, Wrapper } from './Ingredients.styles';
import AnimatedText from '../AnimatedText/AnimatedText';
import { withUI, UI } from '../../../utils/UIContext';
import { IImage } from '../Grid/Grid.models';

interface IIngredientsProps {
  images: IImage[];
  timeout?: number;
  ui: UI;
}

interface IIngredientsState {
  activeImage: number;
  hovered: boolean;
  isAnimating: boolean;
}

class Ingredients extends React.Component<IIngredientsProps, IIngredientsState> {
  state: IIngredientsState = {
    activeImage: 0,
    hovered: false,
    isAnimating: false
  };

  private images: HTMLImageElement[] = [];
  timeoutID!: number | undefined;

  changeIndex = () => {
    if (this.state.isAnimating) return;
    clearTimeout(this.timeoutID);

    this.setState({ isAnimating: true });
    const lastIndex = this.props.images.length - 1;
    const activeImage = this.state.activeImage === lastIndex ? 0 : Math.min(this.state.activeImage + 1, lastIndex);

    this.setState({ activeImage });
  };

  changeImage = (init?: boolean, prevIndex?: number) => {
    if (!this.images) return;

    const { activeImage } = this.state;
    const lastImage = activeImage === this.images.length - 1;

    const onComplete = () => {
      this.setState({ isAnimating: false });
      gsap.set(this.images[prevIndex ? prevIndex : 0], { webkitClipPath: `circle(0% at 100% 100%)` });
      gsap.set(this.images[0], { zIndex: lastImage ? 2 : '' });
    };

    if (init) {
      gsap.set(this.images[activeImage], { webkitClipPath: `circle(100% at 50% 50%)` });
    } else {
      gsap.fromTo(
        this.images[activeImage],
        { webkitClipPath: `circle(0% at 100% 100%)`, duration: 1 },
        { webkitClipPath: `circle(100% at 50% 50%)`, duration: 1, onComplete }
      );
    }
  };

  handleMouseEnter = () => {
    if (this.props.ui.isMobile) return;
    this.setState({ hovered: true });
    this.changeIndex();
  };

  handleMouseLeave = () => {
    if (this.props.ui.isMobile) return;
    this.setState({ hovered: false });
  };

  componentDidMount() {
    this.changeImage(true); // INIT IMAGES
    this.timeoutID = setTimeout(() => this.changeIndex(), this.props.timeout || 4000);
  }

  componentDidUpdate(_prevProps: IIngredientsProps, prevState: IIngredientsState) {
    const { activeImage, hovered, isAnimating } = this.state;

    if (activeImage !== prevState.activeImage) {
      this.changeImage(false, prevState.activeImage);
      this.timeoutID = setTimeout(() => this.changeIndex(), this.props.timeout || 4000);
    }

    if (isAnimating !== prevState.isAnimating && !isAnimating && hovered) {
      this.timeoutID = setTimeout(() => this.changeIndex(), 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { activeImage, hovered } = this.state;
    const { images } = this.props;

    return (
      <Wrapper onMouseEnter={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()}>
        {images.map(
          (img: IImage, key: number) =>
            img.alt && <AnimatedText alwaysAnimated hovered={hovered && activeImage === key} text={img.alt} key={key} />
        )}

        {images.map((img: IImage, key: number) => (
          <picture key={key}>
            <source data-srcset={img.webp} type="image/webp" />
            <source data-srcset={`${img.src}, ${img.src2x} 2x`} type="image/jpeg" />

            <Image
              data-sizes="auto"
              data-src={img.src}
              data-srcset={`${img.src}, ${img.src2x} 2x`}
              className="lazyload"
              ref={(el: HTMLImageElement) => (this.images[key] = el)}
            />
          </picture>
        ))}
      </Wrapper>
    );
  }
}

export default withUI(Ingredients);
