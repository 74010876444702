import React from 'react';
import { Address, LettersContainer, Section } from './Contacts.styles';
import ContactsForm from '../../form/ContactsForm';
import WhereYouCanTry from '../../where-you-can-try/WhereYouCanTry';
import Letters from '../../shared/Letters/Letters';
import { withUI, UI } from '../../../utils/UIContext';

export interface IContactsProps {
  ui: UI;
  scrollTo: Function;
}

class Contacts extends React.Component<IContactsProps> {
  render() {
    const { isMobile, windowWidth } = this.props.ui;
    const changeLetter = windowWidth < 1440 && windowWidth >= 1024;

    return (
      <>
        <Section>
          <Address aria-label="Our address">
            <h2>
              The Carbonation Limited,
              <br />
              701 Max&nbsp;Trade Centre,
              <br />
              23 Luk&nbsp;Hop Street,
            </h2>

            <section>
              <p>
                <span>
                  Son Po Kong <br />
                  Kowloon, <br /> Hong Kong
                </span>
              </p>
              <div>
                <a href="/" rel="noopener noreferrer" target="_blank">
                  Facebook
                </a>

                <a href="https://www.instagram.com/thecarbonation/" rel="noopener noreferrer" target="_blank">
                  Instagram
                </a>
              </div>
            </section>
          </Address>
          {!isMobile && (
            <LettersContainer>
              {changeLetter && (
                <Letters
                  autoplay
                  disableExternalActions={true}
                  letters={['/letters/7A_C.json', '/letters/3A_T.json', '/letters/2A_C.json', '/letters/1A_T.json']}
                  timeoutBetweenLoop={1000}
                  timeoutBetweenLetters={10000}
                ></Letters>
              )}
              {!changeLetter && (
                <>
                  <Letters
                    autoplay
                    disableExternalActions={true}
                    letters={['/letters/7A_C.json', '/letters/2A_C.json']}
                    timeoutBetweenLoop={3000}
                    timeoutBetweenLetters={10000}
                  ></Letters>
                  <Letters
                    autoplay
                    disableExternalActions={true}
                    letters={['/letters/1A_T.json', '/letters/2A_T.json']}
                    timeoutBetweenLoop={9000}
                    timeoutBetweenLetters={10000}
                  ></Letters>
                </>
              )}
            </LettersContainer>
          )}
        </Section>

        <ContactsForm scrollTo={this.props.scrollTo}></ContactsForm>

        <WhereYouCanTry scrollTo={this.props.scrollTo}></WhereYouCanTry>
      </>
    );
  }
}

export default withUI(Contacts);
