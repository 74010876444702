import React from 'react';
import { RadioBtn } from './RadioButton.styles';

interface IRadioButtonProps {
  ariaLabel?: string;
  checked: boolean;
  hovered: boolean;
  text: string;
  value: string;
  onMouseEnter?: (key: number) => void;
  onMouseLeave?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class RadioButton extends React.Component<IRadioButtonProps> {
  render() {
    const { ariaLabel, checked, hovered, text, value, onMouseEnter, onMouseLeave, onChange } = this.props;

    return (
      <RadioBtn
        aria-checked={checked}
        aria-label={ariaLabel}
        checked={checked}
        hovered={hovered}
        name="typeOfMessage"
        role="radio"
        tabIndex={0}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <input aria-checked={checked} checked={checked} name="message" required type="radio" value={value} onChange={onChange} />
        <span>{text}</span>
      </RadioBtn>
    );
  }
}

export default RadioButton;
