import React from 'react';
import styled from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

export const BottleContainer = styled.div`
  position: relative;
  width: calc((100vw - 100px) / 4);
  height: 100%;
  padding: 12px 12px 0;
  overflow: hidden;

  ${createMediaQuery().up(1440)} {
    width: calc((100vw - 140px) / 6);
    padding: 23px 23px 0;
  }

  ${createMediaQuery().mobile()} {
    width: calc((100vw - 60px) / 2);
  }

  & > div {
    display: flex;
    flex-wrap: nowrap;
    transform: translate3d(0, 0, 0);
  }

  picture {
    width: 100%;
    height: 100%;
    flex: 0 0 auto;
  }
`;

export const Img = styled(({alt, forwardRef, ...rest}) => <img alt={alt} ref={forwardRef} {...rest} />)`
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Wrapper = styled.div<any>`
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: ${props => (props.smallSize ? `30%` : '0')};

  ${BottleContainer} {
    width: ${props => (props.smallSize ? `calc((100vw - 100px) / 4 * 0.3)` : '')};
    padding: ${props => (props.smallSize ? 0 : '')};
  }

  ${createMediaQuery().up(1440)} {
    padding: 0;
    padding-left: ${props => (props.smallSize ? `30%` : '0')};

    ${BottleContainer} {
      width: ${props => (props.smallSize ? `calc((100vw - 140px) / 6 * 0.4)` : '')};
    }
  }

  h6 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;
