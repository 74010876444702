import styled from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

export const transition = 1000;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ProgressDot = styled.i<any>`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: ${props => (!props.active ? '#000' : 'transparent')};
  border: 4px #000 solid;
  border-radius: 50%;
  transform: scale(${props => (!props.visible ? 0 : props.active ? 1 : 0.429)});
  transition: transform 700ms ease, background 250ms ease;
  transition-delay: ${props => (props.visible && !props.active ? 100 : 0)}ms;
`;

export const Progress = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;

  ${ProgressDot} + ${ProgressDot} {
    margin-left: 20px;
  }
`;

export const Image = styled.img<any>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: calc(100% - 50px);
  z-index: 1;
  clip-path: circle(0% at 100% 100%);
  webkitclippath: circle(0% at 100% 100%);
  object-fit: cover;

  ${createMediaQuery().mobile()} {
    height: 100%;
    min-height: auto;
  }
`;

export const Heading = styled.h2<any>`
  position: absolute;
  top: 46px;
  left: 48px;
  color: ${props => props.color};
  transition: color ${transition}ms ease;
  z-index: 10;

  ${createMediaQuery().mobile()} {
    top: 24px;
    left: 24px;
  }
`;
