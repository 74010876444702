import { UI } from '../../utils/UIContext';

export interface ITypeMessage {
  text: string;
  value: string;
}

export interface IContactsFormProps {
  ui: UI;
  scrollTo: Function;
  route: any;
}

export interface IFields {
  [key: string]: string;
}

export interface IContactsFormState {
  formIsValid: boolean;
  hoveredRadioIndex: number;
  messageSend: boolean; // TODO Temp
  phoneIsValid: boolean;
  typeOfMessageExpanded: boolean;
  sendingMessage: boolean;
  typeOfMessage: string;
  fields: IFields;
  status: 'SUCCESS' | 'ERROR' | '';
}

export const typeOfMessages: ITypeMessage[] = [
  { text: 'I want to try', value: 'i want to try' },
  { text: 'I want to make an order', value: 'i want to make an order' },
  { text: 'I have a question', value: 'i have a question' },
  { text: 'Others', value: 'others' }
];
