import React from 'react';
import { Switch, Route, matchPath, withRouter } from 'react-router-dom';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { History, Location, UnregisterCallback } from 'history';
import { Main, enter, exit } from './Pages.styles';
import Navigation from '../navigation/Navigation';
import Bubbles from '../shared/Bubbles/Bubbles';
import { routes, IRoute } from '../../routes/Routes';
import { withUI, UI } from '../../utils/UIContext';

interface IWrapper {
  children: React.ReactNode;
  forwardRef: any;
  resetToDiv: boolean;
  onScrollFrame: (values: positionValues) => void;
}

const Wrapper = ({ forwardRef, resetToDiv, children, ...rest }: IWrapper) => {
  return resetToDiv ? (
    <div>{children}</div>
  ) : (
    <Scrollbars autoHide ref={forwardRef} universal {...rest}>
      {children}
    </Scrollbars>
  );
};

interface IPageProps {
  ui: UI;
  history: History;
  location: Location;
}

interface IPageState {
  blendMode: boolean;
  enter: number;
  exit: number;
}

class Page extends React.Component<IPageProps, IPageState> {
  state = {
    blendMode: false,
    enter: enter,
    exit: exit
  };
  private scrollbar!: any;
  blendTimeoutID: number | undefined;
  scrollTimeoutID: number | undefined;
  unlisten!: UnregisterCallback;

  setBlendModeForNavigation = (pathname: string, mount?: boolean) => {
    const route = routes.filter((r: IRoute) => !!matchPath(pathname, { path: r.path, exact: true }));

    if (!route.length!) return;

    if (route[0].blendMode && !mount) {
      this.blendTimeoutID = setTimeout(() => this.setState({ blendMode: !!route[0].blendMode }), enter);
    } else {
      this.setState({ blendMode: !!route[0].blendMode });
    }
  };

  scrollTo = (top: number, disableSmooth?: boolean) => {
    clearTimeout(this.scrollTimeoutID);
    const options: ScrollToOptions = { top: top, left: 0, behavior: disableSmooth ? 'auto' : 'smooth' };

    if (!this.scrollbar) {
      window.scrollTo(options);
    } else {
      this.scrollbar.view.scrollTo(options);
    }
  };

  handleScroll = (values: positionValues) => {
    this.props.ui.setPageScrollTop(values.scrollTop);
  };

  componentDidMount() {
    if (this.props.ui.isMobile || this.props.ui.isTablet) {
      window.addEventListener('scroll', () => this.props.ui.setPageScrollTop(window.pageYOffset));
    }

    this.setBlendModeForNavigation(this.props.location.pathname, true);

    this.unlisten = this.props.history.listen((location: Location) => {
      if (this.props.location.pathname !== location.pathname) {
        this.props.ui.changePage(true);
        this.setState({ enter, exit });
        this.scrollTimeoutID = setTimeout(() => {
          if (this.props.ui.isMobile || this.props.ui.isTablet) {
            window.scrollTo({ top: 0, left: 0 });
          } else {
            this.scrollTo(0, true);
          }
        }, exit);
      } else {
        this.setState({ enter: 0, exit: 0 }, () => this.setState({ enter, exit }));
      }
      this.setBlendModeForNavigation(location.pathname);
    });
  }

  componentWillUnmount() {
    if (this.props.ui.isMobile || this.props.ui.isTablet) {
      window.removeEventListener('scroll', this.props.ui.setPageScrollTop(window.pageYOffset));
    }
    clearTimeout(this.blendTimeoutID);
    clearTimeout(this.scrollTimeoutID);
    this.unlisten();
  }

  render() {
    return (
      <>
        <Navigation blendMode={this.state.blendMode} />

        <Wrapper
          resetToDiv={this.props.ui.isMobile || this.props.ui.isTablet}
          forwardRef={(el: any) => (this.scrollbar = el)}
          onScrollFrame={this.handleScroll}
        >
          <TransitionGroup>
            <CSSTransition
              classNames="fade"
              key={this.props.location.key}
              timeout={{ enter: this.state.enter, exit: this.state.exit }}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Main>
                <Switch location={this.props.location}>
                  {routes.map((r: IRoute, key: number) => {
                    const Component = r.component;
                    return (
                      <Route exact key={key} path={r.path} render={() => <Component scrollTo={this.scrollTo} />} />
                    );
                  })}
                </Switch>
              </Main>
            </CSSTransition>
            <Bubbles active={this.props.ui.animateChangePage}></Bubbles>
          </TransitionGroup>
        </Wrapper>
      </>
    );
  }
}

export default withRouter(withUI(Page));
