import React from 'react';
import { gsap } from 'gsap';
import { Image, Wrapper } from './About.styles';
import { IImage } from '../../shared/Grid/Grid.models';
import { withUI, UI } from '../../../utils/UIContext';

interface IGalleryProps {
  activeIndex: number;
  hovered: boolean;
  images: IImage[];
  timeout?: number;
  ui: UI;
  hasAnimation?: (hasAnimation: boolean) => void;
}

interface IGalleryState {
  isAnimating: boolean;
}

class Gallery extends React.Component<IGalleryProps, IGalleryState> {
  state: IGalleryState = {
    isAnimating: false
  };

  private images: HTMLImageElement[] = [];
  timeoutID!: number | undefined;

  changeImage = (init?: boolean, prevIndex?: number) => {
    if (!this.images) return;

    clearTimeout(this.timeoutID);
    this.setState({ isAnimating: true });
    const { activeIndex } = this.props;

    if (activeIndex !== prevIndex) {
      gsap.set(this.images[activeIndex], { webkitClipPath: `circle(0% at 100% 100%)`, zIndex: 3 });
      gsap.set(this.images[prevIndex ? prevIndex : 0], { zIndex: 2 });

      this.images.forEach((item: HTMLImageElement, index: number) => {
        if (index !== activeIndex && index !== prevIndex) gsap.set(item, { zIndex: 1 });
      });
    }

    const onComplete = () => this.setState({ isAnimating: false });

    if (init) {
      gsap.set(this.images[activeIndex], { webkitClipPath: `circle(100% at 50% 50%)` });
      this.setState({ isAnimating: false });
    } else {
      gsap.fromTo(
        this.images[activeIndex],
        { webkitClipPath: `circle(0% at 100% 100%)`, duration: 1 },
        { webkitClipPath: `circle(100% at 50% 50%)`, duration: 1, onComplete }
      );
    }
  };

  componentDidMount() {
    this.changeImage(true);
  }

  componentDidUpdate(prevProps: IGalleryProps, prevState: IGalleryState) {
    const { activeIndex, hasAnimation } = this.props;
    const { isAnimating } = this.state;

    if (activeIndex !== prevProps.activeIndex) {
      this.changeImage(false, prevProps.activeIndex);
    }

    if (isAnimating !== prevState.isAnimating) {
      hasAnimation && hasAnimation(isAnimating);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { images } = this.props;

    return (
      <Wrapper>
        {images.map((img: IImage, key: number) => (
          <picture key={key}>
            <source data-srcset={img.webp} type="image/webp" />
            <source data-srcset={`${img.src}, ${img.src2x} 2x`} type="image/jpeg" />
            <Image
              alt={img.alt || ' '}
              data-sizes="auto"
              data-src={img.src}
              data-srcset={`${img.src}, ${img.src2x} 2x`}
              className="lazyload"
              forwardRef={(el: HTMLImageElement) => (this.images[key] = el)}
            />
          </picture>
        ))}
      </Wrapper>
    );
  }
}

export default withUI(Gallery);
