import React from "react";
import styled from "styled-components";
import { GridContainer } from "../../shared/Grid/Grid.styles";
import createMediaQuery from "../../../assets/styles/media";

export const InstagramImage = styled(
  ({ alt, forwardRef, vertical, ...rest }) => (
    <img ref={forwardRef} alt={alt} {...rest} />
  )
)`
  position: absolute;
  top: ${(props) => (props.vertical ? "50%" : "auto")};
  left: ${(props) => (props.vertical ? "auto" : "50%")};
  transform: translate3d(
    ${(props) => (props.vertical ? 0 : "-50%")},
    ${(props) => (props.vertical ? "-50%" : 0)},
    0
  );
  width: ${(props) => (props.vertical ? "100%" : "auto")};
  height: ${(props) => (props.vertical ? "auto" : "100%")};
`;

export const Wrapper = styled.div`
  ${GridContainer} {
    grid-template-columns: repeat(4, 1fr);

    ${createMediaQuery().up(1440)} {
      grid-template-columns: repeat(6, 1fr);
    }

    ${createMediaQuery().mobile()} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ${GridContainer} {
    &:nth-child(1) {
      grid-template-areas:
        'letter0 letter1 bottle2 image3'
        'text4 letter5 bottle2 text6'
        'letter7 image8 image9 letter10';
      grid-template-rows: repeat(3, calc((100vw - 100px) /4));
        

      ${createMediaQuery().up(1440)} {
        grid-template-areas:
          'letter0 letter1 bottle2 image3 letter7 image8'
          'text4 letter5 bottle2 text6 image9 letter10';
        grid-template-rows: repeat(2, calc((100vw - 140px) /6));
      }

      ${createMediaQuery().mobile()} {
        grid-template-areas:
          'letter0 text4'
          'letter1 letter5'
          'bottle2 image3'
          'bottle2 text6';
        grid-template-rows: repeat(4,minmax(calc((100vw - 60px) / 2),auto));
      }
    }

    &:nth-child(2) {
      grid-template-areas:
        'letter0 bottle1 image2 letter3'
        'letter4 bottle1 text5 image6'
        'letter7 image8 letter9 text10'
        'address11 address12 external_link13 letter14';
      grid-template-rows: repeat(4, calc((100vw - 100px) /4));
      padding-bottom: 20px;
  
      ${createMediaQuery().up(1440)} {
        grid-template-areas:
          'letter0 bottle1 image2 letter3 letter7 image8'
          'letter4 bottle1 text5 image6 letter9 text10'
          'address11 address12 external_link13 letter14 letter15 letter16';
        grid-template-rows: repeat(3, calc((100vw - 140px) /6));
      }
  
      ${createMediaQuery().mobile()} {
        grid-template-areas:
          'letter0 letter3'
          'bottle1 image2'
          'bottle1 text4'
          'text6 letter5'
          'address7 address8'
          'external_link9 external_link9';
        grid-template-rows: repeat(6,minmax(calc((100vw - 60px) /2),auto))
      }
  }
`;

export const Text = styled.div`
  margin-top: 23px;
  margin-left: 46px;
  color: #000;
  white-space: pre-line;

  ${createMediaQuery().down(1024)} {
    margin-top: 33px;
    margin-left: 33px;
  }

  ${createMediaQuery().mobile()} {
    margin-top: 24px;
    margin-left: 24px;
  }
`;

export const ExternalLink = styled.ul`
  margin-top: 23px;

  ${createMediaQuery().down(1024)} {
    margin-top: 33px;
    margin-left: 33px;
  }

  ${createMediaQuery().mobile()} {
    margin-top: 24px;
    margin-left: 24px;
  }

  & > li {
    text-align: left;
    margin-left: 46px;

    & + li {
      margin-top: 20px;
    }

    ${createMediaQuery().mobile()} {
      display: inline-block;
      width: 50%;
      margin-left: 0;

      & + li {
        margin-top: 0;
        padding-left: calc(15.29% / 2);
      }
    }
  }

  & > li > a {
    position: relative;

    &:hover::before {
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      margin: auto 0;
      background-color: #000;
      pointer-events: none;
      transition: opacity 150ms ease;
      opacity: 1;
    }
  }
`;

export const Img = styled.img<any>`
  width: 100%;
`;
