import styled from "styled-components";
import { desktopLeftIndent } from "../pages/Pages.styles";
import createMediaQuery from "../../assets/styles/media";

export const Item = styled.li<any>`
  position: relative;
  opacity: ${(props) => (props.hovered ? 1 : 0.1)};
  transition: opacity 500ms ease;

  ${createMediaQuery().down(1025)} {
    opacity: 1;
  }

  ${createMediaQuery().mobile()} {
    width: max-content;
  }

  ${(props) =>
    props.active &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom:0;
      display: block;
      width: 100%;
      height: 2px;
      margin: auto 0;
      pointer-events: none;
    }
  `}
`;

export const MobileButton = styled.button<any>`
  position: absolute;
  top: 30px;
  left: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 7;
  background-color: transparent;
  outline: 0;

  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 10px;
    height: 10px;
    margin: auto;
    border: solid 2px;
    border-radius: 50%;
    transition: transform 300ms ease;
    transform: translate3d(0, 0, 0);

    ${(props) =>
      !props.mobileMenuActive &&
      `
      &:nth-child(1) {
        transform: translate3d(-8px, -8px, 0);
      }

      &:nth-child(2) {
        transform: translate3d(8px, -8px, 0);
      }

      &:nth-child(3) {
        transform: translate3d(-8px, 8px, 0);
      }

      &:nth-child(4) {
        transform: translate3d(8px, 8px, 0);
      }
    }
  `}
`;

export const Nav = styled.nav<any>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 86px;
  z-index: 5;
  mix-blend-mode: ${(props) => (props.blendMode ? "difference" : "")};

  & > a {
    flex: 0 0 auto;
  }

  & > div {
    flex: 1 0 auto;
  }

  ${createMediaQuery().mobile()} {
    display: block;
    height: auto;
    mix-blend-mode: ${(props) =>
      props.mobileMenuActive ? "unset" : props.blendMode ? "difference" : ""};

    & ${MobileButton} span {
      mix-blend-mode: ${(props) =>
        props.mobileMenuActive ? "unset" : props.blendMode ? "difference" : ""};
      border-color: ${(props) =>
        props.mobileMenuActive || props.blendMode ? "#fff" : "#000"};
    }

    & > a {
      flex: unset;
    }

    & > div {
      flex: unset;
    }
  }

  & ${Item}::before {
    background-color: ${(props) => (props.blendMode ? "#fff" : "#000")};

    ${createMediaQuery().mobile()} {
      background-color: ${(props) =>
        props.mobileMenuActive || props.blendMode ? "#fff" : "#000"};
    }
  }

  svg {
    position: absolute;
    top: 11px;
    left: 15px;
    z-index: 6;

    g path {
      fill: ${(props) => (props.blendMode ? "#fff" : "")};
    }

    ${createMediaQuery().mobile()} {
      right: 0;
      left: 0;
      margin: 0 auto;
    }
  }
`;

export const Menu = styled.div<any>`
  display: flex;
  margin-left: ${desktopLeftIndent}px;
  transform: translate3d(0, ${(props) => (props.collapsed ? -81 : 0)}px, 0);
  transition: transform 500ms ease;
  opacity: 1;

  ${createMediaQuery().mobile()} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${(props) => (props.visible ? "flex" : "none")};
    opacity: ${(props) => (props.mobileMenuActive ? 1 : 0)};
    transition: opacity ${(props) => (props.mobileMenuActive ? 400 : 1000)}ms
      ease;
    flex-direction: column;
    margin-left: 0;
    padding: 146px 20px 32px;
    background-color: #000;
    z-index: 5;
  }

  // COMMON
  & > ul,
  & > div {
    flex: 1 0 auto;
  }

  ul > li {
    display: inline-block;
    color: ${(props) => (props.blendMode ? "#fff" : "#000")};
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.5px;
    font-family: ItalianPlateNo1Expanded-Bold, Arial;
    font-weight: bold;
    font-style: normal;
    font-stretch: expanded;

    ${createMediaQuery().up(1920)} {
      font-size: 18px;
    }

    ${createMediaQuery().mobile()} {
      color: #fff;
    }

    & + li {
      margin-left: 30px;

      ${createMediaQuery().up(1280)} {
        margin-left: 20px;
      }
    }

    & > a {
      color: inherit;
      text-decoration: none;
    }
  }

  // MENU ITEMS
  & > ul > li {
    ${createMediaQuery().mobile()} {
      display: block;
      font-size: 30px;
      z-index: 6;

      & + li {
        margin-top: 40px;
        margin-left: 0;
      }
    }
  }

  // SOCIALS CONTAINER
  & > div {
    margin-left: auto;
    margin-right: 20px;
    text-align: right;

    ${createMediaQuery().mobile()} {
      flex: 0 1 auto;
      margin-top: auto;
      margin-left: 0;
      text-align: left;

      // SOCIALS ITEMS
      & > ul > li {
        position: relaive;
        font-family: ItalianPlateNo1Expanded-Regular, Arial;
        font-weight: normal;
        font-size: 20px;
        line-height: 1.2;

        &::before {
          content: "";
          position: absolute;
          bottom: -2px;
          right: 0;
          left: 0;
          height: 3px;
          background-color: #fff;
          transition: background 150ms ease;
        }
      }
    }
  }
`;
