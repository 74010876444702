import React from 'react';
import AnimateHeight from 'react-animate-height';
import { IContactsFormProps, IContactsFormState, ITypeMessage, IFields, typeOfMessages } from './ContactsForm.models';
import { Fieldset } from './ContactsForm.styles';
import IconSmile from '../icons/IconSmile';
import Bubbles from '../shared/Bubbles/Bubbles';
import Button from '../shared/Button/Button';
import PhoneInput from '../shared/PhoneInput/PhoneInput';
import RadioButton from '../shared/RadioButton/RadioButton';
import Textarea from '../shared/Textarea/Textarea';
import Textfield from '../shared/Textfield/Textfield';
import { withUI } from '../../utils/UIContext';

const animationDuration = 500;
const sendingMessageDuration = 3000;

class ContactsForm extends React.Component<IContactsFormProps, IContactsFormState> {
  state: IContactsFormState = {
    hoveredRadioIndex: -1,

    // Expanded State
    typeOfMessageExpanded: false,

    // Validation States
    formIsValid: false,
    phoneIsValid: false,

    // States of Message Sending
    messageSend: false,
    sendingMessage: false,

    // Selected Type of Message
    typeOfMessage: '', // i want to try

    // fields
    fields: {
      message: '', // Oh, hy guys, i want to know how can i try your product? Can you come over to my bar, and introduce some samples, would you be abel to leave some for me to try afterwards? If yes, how many? \n Thanks!
      email: '', // test@test.com
      name: '', // Vasya
      phone: '', // +852 3333 3333
    },
    status: '',
  };

  formRef!: HTMLFormElement;
  sendingMessageTimeoutID!: number;

  handleSubmit = (event: any) => {
    event.preventDefault();

    this.setState({ sendingMessage: true }, () => {
      this.sendingMessageTimeoutID = setTimeout(() => {
        this.setState({ messageSend: true, sendingMessage: false, typeOfMessageExpanded: false });
        this.clearFields();
      }, sendingMessageDuration);
    });

    const form = event.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();

    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };

    xhr.send(data);
  };

  clearFields() {
    const fields: IFields = this.state.fields;
    Object.keys(fields).forEach((key: string) => (fields[key] = ''));

    this.setState({ fields, typeOfMessage: '' });
  }

  checkPhoneValid = (phoneIsValid: boolean | undefined) => {
    if (phoneIsValid === undefined) return;

    this.setState({ phoneIsValid: phoneIsValid });
  };

  onAnimationEnd = () => {
    this.props.scrollTo(this.formRef.offsetTop);
  };

  handleMouseEnterRadioBtn = (key: number) => {
    this.setState({ hoveredRadioIndex: key });
  };

  handleMouseLeaveRadioBtn = () => {
    this.setState({ hoveredRadioIndex: -1 });
  };

  handleChangeForm = () => {
    if (!this.formRef) return;
    this.setState({ formIsValid: this.formRef.checkValidity() });
  };

  handleExpandField = () => {
    this.setState({ messageSend: false, typeOfMessageExpanded: !this.state.typeOfMessageExpanded });
  };

  handleChangeMessageType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ typeOfMessage: e.currentTarget.value });
  };

  handleChangePhone = (value: string) => {
    let fields = this.state.fields;
    fields['phone'] = value;
    this.setState({ fields });
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    const fields: IFields = this.state.fields;
    fields[name] = value;

    this.setState({ fields });
  };

  componentDidMount() {
    this.handleChangeForm();
  }

  componentWillUnmount() {
    clearTimeout(this.sendingMessageTimeoutID);
  }

  render() {
    const { message, name, email, phone } = this.state.fields;
    const {
      hoveredRadioIndex,
      typeOfMessageExpanded,
      formIsValid,
      phoneIsValid,
      sendingMessage,
      messageSend,
      typeOfMessage,
    } = this.state;

    const bottomPositionForm = this.formRef && this.props.ui.windowHeight - this.formRef.getBoundingClientRect().bottom;

    const hovered = (k: number) =>
      hoveredRadioIndex !== -1
        ? hoveredRadioIndex === k
        : !!typeOfMessage && hoveredRadioIndex === -1
        ? false
        : hoveredRadioIndex === -1;

    return (
      <>
        <form
          action="https://formspree.io/f/xvovygye"
          // action="https://script.google.com/macros/s/AKfycbzMLenJZuSuhobCmKpgG7-JnpMciet94aOAqlL_sBmoEkAmF5Dn/exec"
          method="POST"
          ref={(el: HTMLFormElement) => (this.formRef = el)}
          onChange={this.handleChangeForm}
          onSubmit={this.handleSubmit}
        >
          <Button
            active={typeOfMessageExpanded}
            ariaLabel="Expand form fields"
            expand
            type="button"
            onClick={() => this.handleExpandField()}
          >
            {messageSend ? (
              <p>Your message was send!{this.props.ui.isMobile && <br></br>} Thank you : )</p>
            ) : (
              <p>Want to send us a message?</p>
            )}
            {messageSend && <span>Send another one</span>}
          </Button>

          <AnimateHeight
            duration={animationDuration}
            height={typeOfMessageExpanded && !messageSend ? 'auto' : 0}
            onAnimationEnd={() => this.onAnimationEnd()}
          >
            <Fieldset aria-required="true" padding>
              {typeOfMessages.map((m: ITypeMessage, i: number) => (
                <RadioButton
                  ariaLabel={m.text}
                  checked={m.value === typeOfMessage}
                  hovered={hovered(i)}
                  key={i}
                  text={m.text}
                  value={m.value}
                  onMouseEnter={() => this.handleMouseEnterRadioBtn(i)}
                  onMouseLeave={() => this.handleMouseLeaveRadioBtn()}
                  onChange={this.handleChangeMessageType}
                ></RadioButton>
              ))}
              <IconSmile active={typeOfMessage}></IconSmile>
            </Fieldset>
          </AnimateHeight>

          <AnimateHeight
            duration={animationDuration}
            height={typeOfMessageExpanded && typeOfMessage && !messageSend ? 'auto' : 0}
            onAnimationEnd={() => this.onAnimationEnd()}
          >
            <Fieldset aria-required="true">
              <Textarea
                name={'message'}
                placeholder={'Type it here…'}
                value={message}
                onChange={this.handleChange}
              ></Textarea>

              <Textfield
                autoComplete={'name'}
                name={'name'}
                placeholder={'Your Name'}
                type={'text'}
                value={name}
                onChange={this.handleChange}
              />

              <Textfield
                autoComplete={'email'}
                name={'email'}
                placeholder={'@mail'}
                type={'email'}
                value={email}
                onChange={this.handleChange}
              />

              <PhoneInput
                autoComplete={'tel'}
                name={'phone'}
                defaultCountry={'hk'}
                value={phone}
                onChange={this.handleChangePhone}
                checkPhoneValid={this.checkPhoneValid}
              />
            </Fieldset>

            <AnimateHeight duration={animationDuration} height={formIsValid && phoneIsValid ? 'auto' : 0}>
              <Button
                ariaLabel="Send us a message"
                disabled={(!formIsValid && !phoneIsValid) || sendingMessage}
                type="submit"
              >
                {`Send${sendingMessage ? 'ing...' : ''}`}
              </Button>
            </AnimateHeight>
          </AnimateHeight>
          <Bubbles active={sendingMessage} bottom={bottomPositionForm} withTimeout={this.props.ui.isMobile}></Bubbles>
        </form>

        <div
          style={{
            visibility: 'hidden',
            position: 'absolute',
            pointerEvents: 'none',
            zIndex: -1000,
            width: 0,
            height: 0,
          }}
        >
          <PhoneInput
            autoComplete={'tel'}
            defaultCountry={'hk'}
            // name={'disabled'}
            value={''}
            onChange={() => {}}
            checkPhoneValid={() => {}}
          />
        </div>
      </>
    );
  }
}

export default withUI(ContactsForm);
