import React from 'react';
import styled from 'styled-components';
// import createMediaQuery from '../../../assets/styles/media';

export const delayStep = 100;
export const transition = 2000;

export const BubblesContainer = styled(({ changePosition, forwardRef, ...rest }) => <div ref={forwardRef} {...rest} />)`
  position: ${props => (props.changePosition ? 'fixed' : 'absolute')};
  top: 0;
  right: 0;
  bottom: 0px;
  left: 0;
  overflow: hidden;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    pointer-events: none;
  }
`;

export const Bubble = styled(({ left, ...rest }) => <div {...rest} />)`
  position: absolute;
  bottom: -100px;
  left: ${props => props.left + '%' || 0};
  width: 100px;
  height: 100px;
  border: solid 23px #000;
  border-radius: 50%;
  z-index: 9;
  background-color: #fff;
  will-change: transform;
`;
