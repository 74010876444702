import React from 'react';
import { Character, Text } from './AnimatedText.styles';

interface IAnimatedTextProps {
  alwaysAnimated?: boolean;
  hovered?: boolean;
  text: string;
}

interface IAnimatedTextState {
  direction: 'fromDown' | 'fromMiddle';
}

class AnimatedText extends React.Component<IAnimatedTextProps, IAnimatedTextState> {
  state: IAnimatedTextState = {
    direction: 'fromDown'
  };

  private text!: HTMLDivElement;

  onTransitionEnd = () => {
    if (!this.text && this.state.direction !== 'fromMiddle') return;

    const element = this.text && this.text.lastChild;

    element &&
      element.addEventListener('transitionend', () => {
        this.setState({ direction: 'fromDown' });
      });
  };

  componentDidMount() {
    this.onTransitionEnd();
  }

  componentDidUpdate(prevProps: IAnimatedTextProps) {
    if (this.props.hovered !== prevProps.hovered) {
      this.setState({ direction: this.props.hovered ? 'fromDown' : 'fromMiddle' });
    }
  }

  render() {
    const { alwaysAnimated, hovered, text } = this.props;
    const { direction } = this.state;

    return (
      <Text alwaysAnimated={alwaysAnimated} hovered={hovered} ref={(el: HTMLDivElement) => (this.text = el)}>
        <b>
          {text.split('').map((c: string, i: number) => (
            <Character alwaysAnimated={alwaysAnimated} from={direction} index={i} key={i} whiteSpace={c === '\n'}>
              {c}
            </Character>
          ))}
        </b>
      </Text>
    );
  }
}

export default AnimatedText;
