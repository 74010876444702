import styled from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

export const gap = 20;
export const mobileGap = 21;
export const indent = 20;

export const GridContainer = styled.div<any>`
  display: grid;
  width: 100%;
  grid-gap: ${gap}px;
  padding: 0 20px 20px;
  padding-bottom: ${props => (props.isTablet ? 'calc(20px + 20pt)' : 0)};

  & + & {
    padding-top: ${gap}px;
  }

  ${createMediaQuery().mobile()} {
    grid-gap: ${mobileGap}px;
    padding: 20px 20px 50px;
  }
`;

export const Item = styled.div<any>`
  top: ${props => (props.position === 'sticky' ? props.topIndent + 'px' || 0 : 'auto')};
  display: ${props => (props.visible ? (props.visible === 'desktopLarge' || props.visible === 'mobile' ? 'none' : '') : '')};
  position: ${props => props.position};
  grid-area: ${props => props.gridArea};
  height: ${props => (props.position === 'sticky' ? (props.heightItem ? props.heightItem + 'px' : 'max-content') : 'auto')};
  padding: ${props => (!props.hasInnerIndent ? '0' : '46px 46px 0')};

  ${createMediaQuery().up(1440)} {
    display: ${props => (props.visible === 'desktopLarge' ? 'block' : '')};
  }

  ${createMediaQuery().down(1024)} {
    padding: ${props => (!props.hasInnerIndent ? '0' : '33px 33px 0')};
  }

  ${createMediaQuery().mobile()} {
    display: ${props => (props.visible ? (props.visible === 'desktop' || props.visible === 'desktopLarge' ? 'none' : 'block') : '')};
    padding: ${props => (!props.hasInnerIndent ? '0' : '24px 24px 0')};
  }

  a {
    color: inherit;
    min-height: 100%;
    background-position: center;
    background-size: cover;
  }

  & > ${GridContainer} {
    padding: 0;
  }
`;
