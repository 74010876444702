import React from 'react';
import { withUIProvider } from './utils/UIContext';
import Page from './components/pages/Page';

class App extends React.Component {
  render() {
    return <Page></Page>;
  }
}

export default withUIProvider(App);
