import styled from 'styled-components';
import { Container as Letters } from '../../shared/Letters/Letter.styles';
import createMediaQuery from '../../../assets/styles/media';
import { GridContainer, gap, indent } from '../../shared/Grid/Grid.styles';
export const modifierH = 2;

export const columns = 4;
const columns1440 = 6;
export const columnsMobile = 2;
export const galleryMobileTopIndent = 121;
export const modifierW = 1.5;

export const CocktailDescription = styled.div`
  ${createMediaQuery().mobile()} {
    max-width: calc((100vw - ${gap + indent * 2}px) / ${columnsMobile});
    transform: translateY(28px);
  }
`;

export const Steps = styled.div`
  div + div {
    margin-top: 30px;
  }

  ${createMediaQuery().mobile()} {
    margin-top: calc(((100vw - ${gap + indent * 2}px) / ${columnsMobile}) * 2 + ${gap}px + 60px);
    padding-bottom: 48px;
  }
`;

export const GalleryWrapper = styled.div`
  width: calc(100% - ((100vw - ${gap * (columns - 1) + indent * 2}px) / ${columns}) / 2);
  height: 100%;
  transform-origin: top left;

  ${createMediaQuery().up(1440)} {
    width: calc(100% - ((100vw - ${gap * (columns1440 - 1) + indent * 2}px) / ${columns1440}) / 2);
    height: calc(100% - ((100vw - ${gap * (columns1440 - 1) + indent * 2}px) / ${columns1440}) / 2);
  }

  ${createMediaQuery().mobile()} {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(((100vw - ${gap + indent * 2}px) / ${columnsMobile}) * ${modifierW} + ${gap}px);
    height: calc(((100vw - ${gap + indent * 2}px) / ${columnsMobile}) * ${modifierH} + ${gap}px);
  }

  & > ${Letters} {
    position: absolute;
    top: 0;
    right: 0;
    width: calc((100vw - ${gap * (columns - 1) + indent * 2}px) / ${columns});
    height: calc((100vw - ${gap * (columns - 1) + indent * 2}px) / ${columns});
    z-index: 4;

    &:nth-child(2) {
      top: auto;
      bottom: 0;
    }

    ${createMediaQuery().up(1440)} {
      width: calc((100vw - ${gap * (columns1440 - 1) + indent * 2}px) / ${columns1440});
      height: calc((100vw - ${gap * (columns1440 - 1) + indent * 2}px) / ${columns1440});

      &:nth-child(2) {
        bottom: calc((100vw - ${gap * (columns1440 - 1) + indent * 2}px) / ${columns1440});
      }
    }

    ${createMediaQuery().mobile()} {
      right: auto;
      left: calc(((100vw - ${gap + indent * 2}px) / ${columnsMobile}) + ${gap}px);
      width: calc((100vw - ${gap * (columnsMobile - 1) + indent * 2}px) / ${columnsMobile});
      height: calc((100vw - ${gap * (columnsMobile - 1) + indent * 2}px) / ${columnsMobile});

      &:last-child {
        top: calc(((100vw - ${gap * (columnsMobile - 1) + indent * 2}px) / ${columnsMobile}) + ${gap}px);
      }
    }
  }
`;

export const Container = styled.div`
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: calc(20px + 20pt);
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: calc(20px + 20pt);
  }
  
  ${GridContainer} {
    grid-template-areas:
      'text0 gallery1 gallery1 steps2'
      'ingredients3 gallery1 gallery1 steps2'
      'bottle4 letter5 bold_text6 steps2';
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, minmax(calc((100vw - 100px) / 4), auto));

    ${createMediaQuery().up(1440)} {
      grid-template-areas:
        'text0 gallery1 gallery1 gallery1 steps2 bottle4'
        'ingredients3 gallery1 gallery1 gallery1 steps2 letter5'
        'ingredients3 gallery1 gallery1 gallery1 steps2 bold_text6';
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(3, minmax(calc((100vw - 140px) / 6), auto));
    }

    ${createMediaQuery().mobile()} {
      grid-template-areas:
        'gallery0 steps1'
        'gallery0 steps1';
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, minmax(calc((100vw - 60px) / 2), auto));
    }
  }

  & > div + div {
    margin-top: 85px;
  }

  h2 {
    padding: 46px 0 0 46px;
  }

  h2 + p {
    padding: 0 46px 0;
  }

  ${createMediaQuery().down(1024)} {
    h2 {
      padding: 33px 0 0 33px;
    }

    h2 + p {
      padding: 0 33px 0;
    }
  }

  ${createMediaQuery().mobile()} {
    padding-bottom: 50px;

    // TODO TEST
    & > grid:nth-child(2) ${Steps} {
      margin-top: calc(((100vw - ${gap + indent * 2}px) / ${columnsMobile}) * 2 + ${gap * 2}px);
      padding-bottom: calc(
        ((100vw - ${gap + indent * 2}px) / ${columnsMobile}) * 2 + ${gap}px - ${galleryMobileTopIndent}px
      );
    }

    & > div + div {
      margin-top: 15px;
    }

    h2 {
      padding: 24px 0 0 24px;
    }

    h2 + p {
      padding: 0 24px 0;
    }
  }
`;

// REMOVED
// export const Dot = styled.div<any>`
//   position: absolute;
//   top: 4px;
//   left: -48px;
//   display: inline-block;
//   width: 16px;
//   height: 16px;
//   background-color: ${props => (!props.active ? '#000' : 'transparent')};
//   border: 5px #000 solid;
//   border-radius: 50%;
//   transform: scale(${props => (props.active ? 1 : 0)});
//   transition: transform 700ms ease, background 250ms ease;
//   transition-delay: ${props => (!props.active ? 100 : 0)}ms;
// `;

// export const Step = styled.div`
//   position: relative;
// `;
