import React from 'react';
import { Item } from './Grid.styles';

interface IGridItemProps {
  children: React.ReactNode;
  className?: any;
  gridArea?: string;
  hasInnerIndent?: boolean;
  heightItem?: number | null;
  position?: string;
  topIndent?: number;
  visible?: string;
}

const GridItem = (props: IGridItemProps) => {
  const { children, className, gridArea, hasInnerIndent, heightItem, position, topIndent, visible } = props;

  return (
    <Item
      gridArea={gridArea}
      hasInnerIndent={hasInnerIndent}
      className={className}
      heightItem={heightItem}
      position={position}
      topIndent={topIndent}
      visible={visible}
    >
      {children}
    </Item>
  );
};

export default GridItem;
