import React from 'react';
import { NavLink } from 'react-router-dom';
import { gsap } from 'gsap';
import {
  CocktailDescription,
  Container,
  GalleryWrapper,
  Steps,
  columnsMobile,
  galleryMobileTopIndent,
  modifierW,
  modifierH
} from './Cocktails.styles';
import { grid } from './CocktailsGridItems';
import AnimatedBottle from '../../shared/AnimatedBottle/AnimatedBottle';
import Gallery from '../../shared/Gallery/Gallery';
import Grid from '../../shared/Grid/Grid';
import GridItem from '../../shared/Grid/GridItem';
import { IGrid, IGridItem, IStep, ILetters } from '../../shared/Grid/Grid.models';
import { gap, indent } from '../../shared/Grid/Grid.styles';
import Letters from '../../shared/Letters/Letters';
import { withUI, UI } from '../../../utils/UIContext';

const TIMEOUT = 5000;
const RANGE_FOR_SCROLLTOP = 250;

interface ICocktailsProps {
  ui: UI;
}
interface ICocktailsState {
  activeCocktail: number;
  activeStep: number[];
  direction: 'down' | 'up' | '';
  progress: number[];
  scrollPercent: number[];
  scale: { width: number; height: number }[];
}

class Cocktails extends React.Component<ICocktailsProps, ICocktailsState> {
  state: ICocktailsState = {
    activeCocktail: 0,
    activeStep: [0, 0, 0],
    direction: '',
    progress: [0, 0, 0],
    scrollPercent: [0, 0, 0],
    scale: [
      { width: 0, height: 0 },
      { width: 0, height: 0 },
      { width: 0, height: 0 }
    ]
  };

  cocktailDescriptionForMobile: IGridItem[] = [];
  cocktailDescriptionRef: HTMLDivElement[] = [];
  galleryRef: HTMLDivElement[] = [];
  gridRef: HTMLDivElement[] = [];
  stepsRef: HTMLDivElement[] = [];
  timeoutID!: number;

  gridMobileFilter = (item: IGridItem) => {
    const { isMobile } = this.props.ui;
    return isMobile ? !['text', 'ingredients', 'bold_text', 'bottle', 'letter'].includes(item.type) : item;
  };

  getCocktailNameForMobile = () => {
    grid.forEach((grid: IGrid) => {
      grid.items.map((item: IGridItem) => (item.type === 'text' ? this.cocktailDescriptionForMobile.push(item) : null));
    });
  };

  autoChangeSteps = () => {
    const { activeCocktail, activeStep } = this.state;

    const lastIndex =
      (grid as any)[activeCocktail].items.filter((item: IGridItem) => item.type === 'gallery')[0].gallery.length - 1;
    activeStep[activeCocktail] =
      activeStep[activeCocktail] === lastIndex ? 0 : Math.min(activeStep[activeCocktail] + 1, lastIndex);
    this.setState({ activeStep: [...activeStep] });
  };

  scaleGallery = () => {
    if (!this.props.ui.isMobile) return;

    const { activeCocktail, progress, scale } = this.state;
    const gallery = this.galleryRef[activeCocktail];

    /** width and height for the gallery when it collapsed */
    const width = (window.innerWidth - gap - indent * 2) / columnsMobile;
    const height = width * modifierW + gap;
    /**  */

    /** width and height for the gallery when it is expanded (initial state for mobile) */
    const widthExpanded = width * modifierW + gap;
    const heightExpanded = width * modifierH + gap;
    /**  */

    /** Set state for scle width and height */
    scale[activeCocktail] = { width, height };
    this.setState({ scale: [...scale] });
    /**  */

    /** Set scroll percentage */
    // height of first gallery
    const gridHeight = this.gridRef[0].getBoundingClientRect().height;
    const gridHeight2 = this.gridRef[1].getBoundingClientRect().height;
    // scrollTop  for certain gallery
    let scrollTop =
      activeCocktail === 1
        ? this.props.ui.pageScrollTop - gridHeight
        : activeCocktail === 2
        ? this.props.ui.pageScrollTop - gridHeight - gridHeight2
        : this.props.ui.pageScrollTop;
    //scroll percent for tween progress
    const scrollPercent = Math.min(scrollTop / RANGE_FOR_SCROLLTOP, 1);
    /**  */

    progress[activeCocktail] = scrollPercent;
    this.setState({ progress: [...progress] });

    gsap
      .fromTo(
        gallery,
        { duration: 0.1, width: widthExpanded, height: heightExpanded },
        { width: scale[activeCocktail].width, height: scale[activeCocktail].height, paused: true }
      )
      .progress(progress[activeCocktail]);
    gsap
      .fromTo(this.cocktailDescriptionRef[activeCocktail], { duration: 0.3, y: 28 }, { y: 20, paused: true })
      .progress(progress[activeCocktail]);
  };

  hideCocktailDescription = () => {
    if (!this.props.ui.isMobile) return;

    const { activeCocktail } = this.state;

    const trigger = this.stepsRef[activeCocktail].getBoundingClientRect().bottom;
    const description = this.cocktailDescriptionRef[activeCocktail];

    /** Set scroll percentage */
    let top = window.innerHeight - trigger;
    // progress percent for tween progress
    const scrollPercent = activeCocktail === 2 ? 0 : Math.max(Math.min(top / RANGE_FOR_SCROLLTOP, 1), 0);
    /**  */

    gsap.fromTo(description, { duration: 0.1, opacity: 1 }, { opacity: 0, paused: true }).progress(scrollPercent);
  };

  changeSteps = () => {
    if (!grid) return;

    const { isMobile } = this.props.ui;
    const { direction } = this.state;
    let { activeCocktail } = this.state;

    const gridRef = this.gridRef[activeCocktail] && this.gridRef[activeCocktail];
    const triggerForDirectionUp = isMobile ? galleryMobileTopIndent : window.innerHeight / 2;

    /** scroll down */
    if (direction === 'down') {
      /** change active cocktail */
      if (gridRef.getBoundingClientRect().bottom <= galleryMobileTopIndent) {
        activeCocktail = Math.min(activeCocktail + 1, 2);
        this.setState({ activeCocktail });
      } /** */
    }

    /** scroll up */
    if (direction === 'up') {
      /** change active cocktail */
      if (gridRef.getBoundingClientRect().top > triggerForDirectionUp) {
        activeCocktail = Math.max(activeCocktail - 1, 0);
        this.setState({ activeCocktail });
      } /** */
    }
  };

  componentDidMount() {
    this.getCocktailNameForMobile();
    this.timeoutID = setTimeout(() => this.autoChangeSteps(), TIMEOUT);
  }

  componentDidUpdate(prevProps: ICocktailsProps, prevState: ICocktailsState) {
    const { pageScrollTop } = this.props.ui;
    const { activeCocktail, activeStep } = this.state;

    if (this.props.ui.pageScrollTop !== prevProps.ui.pageScrollTop) {
      this.scaleGallery();
      this.hideCocktailDescription();
    }

    if (pageScrollTop !== prevProps.ui.pageScrollTop) {
      this.changeSteps();

      this.setState({ direction: pageScrollTop > prevProps.ui.pageScrollTop ? 'down' : 'up' });
    }

    if (activeCocktail !== prevState.activeCocktail) {
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => this.autoChangeSteps(), TIMEOUT);
    }

    if (activeStep !== prevState.activeStep) {
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => this.autoChangeSteps(), TIMEOUT);
    }
  }

  componentWillMount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { isMobile } = this.props.ui;
    const { activeCocktail, progress } = this.state;
    const width = (window.innerWidth - gap - indent * 2) / columnsMobile;
    const heightItemGallery = width * modifierH + gap;

    return (
      <Container>
        {grid.map((grid: IGrid, index: number) => (
          <Grid forwardRef={(el: HTMLDivElement) => (this.gridRef[index] = el)} key={index}>
            {grid.items
              .filter((item: IGridItem) => this.gridMobileFilter(item))
              .map((item: IGridItem, key: number) => (
                <GridItem
                  gridArea={`${item.type}` + key}
                  hasInnerIndent={item.type !== 'letter' && item.type !== 'gallery' && item.type !== 'text'}
                  heightItem={isMobile ? heightItemGallery : null}
                  key={key}
                  position={item.type === 'gallery' ? (isMobile ? 'sticky' : 'relative') : 'static'}
                  topIndent={121}
                >
                  {['text', 'ingredients', 'bold_text'].includes(item.type) && (
                    <>
                      {item.type === 'text' ? (
                        <h2>
                          <b>{item.heading}</b>
                        </h2>
                      ) : (
                        <h6>
                          <b>{item.heading}</b>
                        </h6>
                      )}
                      {item.text && <p>{item.text}</p>}
                    </>
                  )}
                  {item.type === 'gallery' && (
                    <GalleryWrapper ref={(el: HTMLDivElement) => (this.galleryRef[index] = el)}>
                      <Gallery activeIndex={this.state.activeStep[index]} images={item.gallery}></Gallery>

                      {isMobile && this.cocktailDescriptionForMobile[index] && (
                        <CocktailDescription ref={(el: HTMLDivElement) => (this.cocktailDescriptionRef[index] = el)}>
                          <h2>
                            <b>{this.cocktailDescriptionForMobile[index].heading}</b>
                          </h2>
                          <p>{this.cocktailDescriptionForMobile[index].text}</p>
                        </CocktailDescription>
                      )}
                      {item.lettersGallery &&
                        item.lettersGallery.map((l: ILetters, i: number) => (
                          <Letters
                            autoplay={activeCocktail === index && !isMobile}
                            disableExternalActions
                            letters={l.src}
                            key={i}
                            progress={Math.max(progress[index], 0)}
                            speed={isMobile ? 0.1 : 0.7}
                            timeoutBetweenLoop={isMobile ? 0 : l.timeout}
                          ></Letters>
                        ))}
                    </GalleryWrapper>
                  )}

                  {item.type === 'steps' && (
                    <Steps ref={(el: HTMLDivElement) => (this.stepsRef[index] = el)}>
                      {item.steps &&
                        item.steps
                          .filter((s: IStep, k: number) => (!isMobile ? k !== 0 : s))
                          .map((step: IStep, i: number) => (
                            <div key={i}>
                              <h6>
                                <b>{step.heading}</b>
                              </h6>

                              <p>{step.text}</p>
                            </div>
                          ))}
                    </Steps>
                  )}

                  {item.type === 'bottle' && (
                    <NavLink to={'/products'}>
                      <AnimatedBottle
                        heading={item.heading}
                        images={item.images}
                        smallSize
                        timeout={3000}
                        withHover
                      ></AnimatedBottle>
                    </NavLink>
                  )}

                  {item.type === 'letter' && item.letters && (
                    <Letters autoplay letters={item.letters.src} timeoutBetweenLoop={item.time}></Letters>
                  )}
                </GridItem>
              ))}
          </Grid>
        ))}
      </Container>
    );
  }
}

export default withUI(Cocktails);
