import React from 'react';
import { gsap, SteppedEase } from 'gsap';
import { BottleContainer, Img, Wrapper } from './AnimatedBottle.styles';
import { IImage } from '../../shared/Grid/Grid.models';
import { withUI, UI } from '../../../utils/UIContext';

const FRAMES = 30;
const SPEED = 0.7;

interface IAnimatedBottleProps {
  autoplay?: boolean;
  heading?: string;
  smallSize?: boolean;
  images: IImage[];
  timeout?: number;
  withHover?: boolean;
  ui: UI;
}

interface IAnimatedBottleState {
  isReady: boolean;
  hovered: boolean;
  isAnimating: boolean;
  reverse: boolean;
}

class AnimatedBottle extends React.Component<IAnimatedBottleProps, IAnimatedBottleState> {
  state: IAnimatedBottleState = {
    isReady: false,
    hovered: false,
    isAnimating: false,
    reverse: false
  };

  images: HTMLImageElement[] = [];
  imagesContainer!: HTMLDivElement;
  timeout!: number;

  observer!: IntersectionObserver;

  autoPlay = (timeoutBetween?: number) => {
    if (!this.props.autoplay || this.props.ui.isMobile) return;

    clearTimeout(this.timeout);
    const tb = timeoutBetween ? timeoutBetween : 0;
    const timeout = this.props.timeout ? this.props.timeout + tb : 1500;

    this.timeout = setTimeout(() => this.animateBottles(), timeout);
  };

  onLoad = () => {
    if (!this.images) return;

    this.images.forEach((img: HTMLImageElement) => {
      if (img.dataset.src && img.dataset.srcset && navigator.userAgent !== 'ReactSnap') {
        img.src = img.dataset.src;
        img.srcset = img.dataset.srcset;
        img.removeAttribute('data-srcset');
        img.classList.remove('lazyload');
        img.classList.add('lazyloaded');
      }
    });

    this.setState({ isReady: true });
  };

  onAnimationEnd = () => {
    if (this.props.autoplay) gsap.set(this.imagesContainer, { x: 0 });

    this.setState({ isAnimating: false, reverse: this.props.autoplay ? false : !this.state.reverse });
  };

  animateBottles = () => {
    if (this.state.isAnimating || !this.imagesContainer || this.props.ui.isMobile) return;

    const { reverse } = this.state;

    // Sets
    const ease = (SteppedEase as any).config(FRAMES);
    const shift = this.imagesContainer.getBoundingClientRect().width * FRAMES;
    const x = reverse ? 0 : -shift;

    // Timeline
    const t = gsap.timeline();
    const duration = SPEED;

    t.to(this.imagesContainer, { duration, x, ease, onComplete: () => this.onAnimationEnd() });
    t.play();

    this.setState({ isAnimating: true });
  };

  hadleMouseEnter = () => {
    if (this.props.ui.isMobile || !this.state.isReady) return;

    if (this.props.autoplay) clearTimeout(this.timeout);

    if (this.props.withHover) {
      this.setState({ hovered: true });
      this.animateBottles();
    }
  };

  handleMouseLeave = () => {
    if (this.props.ui.isMobile || !this.state.isReady) return;

    if (this.props.withHover) {
      this.setState({ hovered: false });

      this.animateBottles();
    }
  };

  componentDidMount() {
    this.autoPlay();
  }

  componentDidUpdate(_prevProps: IAnimatedBottleProps, prevState: IAnimatedBottleState) {
    if (this.state.isAnimating !== prevState.isAnimating) {
      if (!this.state.hovered && !this.state.isAnimating) {
        gsap.set(this.imagesContainer, { x: 0 });
        this.setState({ reverse: false });
      }

      if (this.props.autoplay && !this.state.isAnimating && !this.props.ui.isMobile) {
        this.autoPlay();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { heading, images, smallSize } = this.props;

    return (
      <Wrapper
        smallSize={smallSize}
        onMouseEnter={() => this.hadleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        {heading && (
          <h6>
            <b>{heading}</b>
          </h6>
        )}

        <BottleContainer>
          <div ref={(el: HTMLDivElement) => (this.imagesContainer = el)}>
            {images
              .filter((item: IImage, key: number) =>
                this.props.ui.isMobile || navigator.userAgent === 'ReactSnap' ? key < 1 : item
              )
              .map((img: IImage, key: number) => (
                <picture key={key}>
                  <source data-srcset={img.webp} type="image/webp" />
                  <source data-srcset={`${img.src}, ${img.src2x} 2x`} type="image/jpeg" />

                  <Img
                    alt=" "
                    data-sizes="auto"
                    data-src={img.src}
                    data-srcset={`${img.src}, ${img.src2x} 2x`}
                    className={'lazyload'}
                    forwardRef={(ref: HTMLImageElement) => (this.images[key] = ref)}
                    onLoad={() => key === 1 && this.onLoad()}
                  />
                </picture>
              ))}
          </div>
        </BottleContainer>
      </Wrapper>
    );
  }
}

export default withUI(AnimatedBottle);
