import React from 'react';
import { gridItems } from './AboutGridItems';
import { Grid } from './About.styles';
import WhereYouCanBuy from './WhereYouCanBuy';
import Gallery from '../../shared/Gallery/Gallery';
import GridItem from '../../shared/Grid/GridItem';
import { IGridItem } from '../../shared/Grid/Grid.models';
import Image from '../../shared/Image/Image';
import Ingredients from '../../shared/Ingredients/Ingredients';
import Letters from '../../shared/Letters/Letters';
import Person from '../../shared/Person/Person';
import { withUI, UI } from '../../../utils/UIContext';

interface IAboutProps {
  ui: UI;
}

interface IAboutState {
  activeGalleryIndex: number;
}

class About extends React.Component<IAboutProps, IAboutState> {
  state: IAboutState = { activeGalleryIndex: 0 };

  timeoutID!: number;

  autoChangeImages = () => {
    const { activeGalleryIndex } = this.state;
    const gallery = gridItems.filter((item: IGridItem) => item.type === 'gallery')[0];
    const lastIndex = gallery.gallery ? gallery.gallery.length - 1 : 0;

    this.setState({
      activeGalleryIndex: activeGalleryIndex === lastIndex ? 0 : Math.min(activeGalleryIndex + 1, lastIndex)
    });
  };

  componentDidMount() {
    this.timeoutID = setTimeout(() => this.autoChangeImages(), 4000);
  }

  componentDidUpdate(_prevProps: IAboutProps, prevState: IAboutState) {
    if (this.state.activeGalleryIndex !== prevState.activeGalleryIndex) {
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => this.autoChangeImages(), 8000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { isMobile, windowWidth } = this.props.ui;
    const heightItemGallery = window.innerHeight - 151;

    return (
      <Grid>
        {gridItems
          .filter((item: IGridItem) => (!isMobile ? item.whenVisible !== 'mobile' : item.whenVisible !== 'desktop'))
          .map((item: IGridItem, index: number) => {
            const isGallery = item.type === 'gallery';

            return (
              <GridItem
                gridArea={`${item.type}` + index}
                hasInnerIndent={item.type === 'text' || item.type === 'main_text'}
                heightItem={isGallery && windowWidth >= 1440 ? heightItemGallery : 0}
                key={index}
                position={isGallery && windowWidth >= 1440 ? 'sticky' : 'static'}
                topIndent={isGallery && windowWidth >= 1440 ? 121 : 0}
              >
                {/** MAIN TEXT HEADING */ item.type === 'main_text' && (
                  <h2>
                    <b>{item.heading}</b>
                  </h2>
                )}

                {/** TEXT HEADING */ item.type === 'text' && item.heading && (
                  <h6>
                    <b>{item.heading}</b>
                  </h6>
                )}

                {/** TEXT */ item.text && <p>{item.text}</p>}

                {/** PERSONS */ item.type === 'person' && item.letters && !isMobile && (
                  <Person name={item.heading} sources={item.letters.src} image={item.image} timeoutBetweenLoop={item.time}></Person>
                )}

                {/** LETTER */ item.type === 'letter' && item.letters && (
                  <Letters autoplay={true} letters={item.letters.src} timeoutBetweenLoop={item.time}></Letters>
                )}

                {/** GALLERY */ item.type === 'gallery' && (
                  <Gallery activeIndex={this.state.activeGalleryIndex} heading={item.heading} images={item.gallery} />
                )}

                {/** IMAGE */ item.type === 'image' && !item.ingredients && (
                  <Image hasInnerIndent heading={item.image ? item.image.alt : ''} image={item.image}></Image>
                )}

                {/** INGREDIENTS */ item.type === 'image' && item.ingredients && (
                  <Ingredients images={item.ingredients} timeout={item.time}></Ingredients>
                )}
              </GridItem>
            );
          })}
        <GridItem gridArea={'additional'}>
          <WhereYouCanBuy />
        </GridItem>
      </Grid>
    );
  }
}

export default withUI(About);
