import Home from '../components/pages/Home/Home';
import About from '../components/pages/About/About';
import Products from '../components/pages/Products/Products';
import Cocktails from '../components/pages/Cocktails/Cocktails';
import Contacts from '../components/pages/Contacts/Contacts';
// import Alphabets from '../components/pages/Alphabets/Alphabets';
// import Test from '../components/pages/Test/Test';
// import Test2 from '../components/pages/Test2/Test2';

export interface IRoute {
  blendMode?: boolean;
  path: string;
  name: string;
  component: Function;
}

export const routes: IRoute[] = [
  {
    path: '/',
    name: '',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    blendMode: true
  },
  {
    path: '/cocktails',
    name: 'Cocktails',
    component: Cocktails
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  }
  // ,{
  //   path: '/test2',
  //   name: 'Test2',
  //   component: Test2
  // }
  // {
  //   path: '/alphabets',
  //   name: 'Alphabets',
  //   component: Alphabets
  // }
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: Test
  // },
];
