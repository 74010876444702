import React from 'react';
import Lottie from 'lottie-web';
import { Container } from './Letter.styles';
import { withUI, UI } from '../../../utils/UIContext';

const SPEED = 0.7;

interface ILetterProps {
  autoplay?: boolean;
  disableExternalActions?: boolean;
  letters: string[];
  progress?: number;
  speed?: number;
  timeoutBetweenLetters?: number;
  timeoutBetweenLoop?: number;
  ui: UI;
}

interface ILetterState {
  activeIndex: number;
  letterIsVisible: boolean;
  stoped: boolean | undefined;
  timeoutBetweenLetters: number;
}

class Letters extends React.Component<ILetterProps, ILetterState> {
  state: ILetterState = {
    activeIndex: 0,
    letterIsVisible: false,
    stoped: true,
    timeoutBetweenLetters: this.props.timeoutBetweenLetters || 2000
  };

  private array: any[] = [];
  private container!: HTMLDivElement;
  letterObserver!: IntersectionObserver;
  timeoutID!: number | undefined;

  init() {
    this.props.letters.forEach((path: string, index: number) => {
      if (this.container && path) {
        const params: any = {
          container: this.container,
          autoplay: false,
          loop: false,
          path: path,
          renderer: 'svg',
          rendererSettings: {
            progressiveLoad: true
          }
        };

        this.array.push(Lottie.loadAnimation(params));
        this.array[index].setSpeed(this.props.speed || SPEED);

        this.array[index].addEventListener('data_ready', () => {
          if (index !== this.state.activeIndex) {
            this.array[index].goToAndStop(this.array[index].totalFrames / 2, true);
          }
        });

        this.array[this.state.activeIndex].addEventListener('enterFrame', this.enterEvent);
      }
    });

    if (this.props.autoplay) {
      this.timeoutID = setTimeout(() => {
        this.setState({ stoped: false });
        this.array[this.state.activeIndex].play();
      }, this.props.timeoutBetweenLoop || 0);
    }
  }

  enterEvent = () => {
    if (!this.array) return;

    const { activeIndex, stoped, timeoutBetweenLetters } = this.state;
    const { autoplay, timeoutBetweenLoop } = this.props;
    const lastIndex = this.props.letters.length - 1;
    const timeout = stoped ? timeoutBetweenLoop : timeoutBetweenLetters;

    if (!autoplay) return;

    if (Math.ceil(this.array[activeIndex].currentFrame) === this.array[activeIndex].totalFrames / 2) {
      if (stoped) this.setState({ stoped: false });
      this.array[activeIndex].pause();
      this.setState({ activeIndex: activeIndex === lastIndex ? 0 : Math.min(activeIndex + 1, lastIndex) });

      this.array[this.state.activeIndex].goToAndPlay(46, true);
    } else if (this.array[activeIndex].currentFrame === this.array[activeIndex].totalFrames - 1) {
      this.array[this.state.activeIndex].goToAndStop(0, true);
    } else if (this.array[this.state.activeIndex].currentFrame === 0) {
      clearTimeout(this.timeoutID);

      this.timeoutID = setTimeout(() => {
        this.array[this.state.activeIndex].pause();
        this.array[this.state.activeIndex].play();
      }, timeout);
    }
  };

  handleMouseEnter = () => {
    if (!this.state.stoped || this.props.disableExternalActions || !this.array[this.state.activeIndex]) return;
    clearTimeout(this.timeoutID);
    this.array[this.state.activeIndex].play();
  };

  letterInitObserver = () => {
    this.letterObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.setState({ letterIsVisible: true });
          observer.unobserve(entry.target);
        } else {
          this.setState({ letterIsVisible: false });
        }
      });
    });
  };

  componentDidMount() {
    this.letterInitObserver();
    this.letterObserver.observe(this.container);
  }

  componentDidUpdate(prevProps: ILetterProps, prevState: ILetterState) {
    const { activeIndex, letterIsVisible } = this.state;
    const lastIndex = this.props.letters.length - 1;

    if (letterIsVisible !== prevState.letterIsVisible) {
      this.init();
    }

    if (activeIndex !== prevState.activeIndex) {
      this.array[this.state.activeIndex].addEventListener('enterFrame', this.enterEvent);
    }

    if (activeIndex !== prevState.activeIndex && activeIndex === 0 && prevState.activeIndex === lastIndex) {
      this.setState({ stoped: true });
    }

    if (this.props.progress !== prevProps.progress) {
      const progress = this.props.progress ? (this.props.progress * 89) / 2 : 89;
      this.array[this.state.activeIndex].goToAndStop(progress, true);
    }

    if (this.props.autoplay !== prevProps.autoplay) {
      if (this.props.autoplay) {
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() => this.array[this.state.activeIndex].play(), this.props.timeoutBetweenLoop);
      } else {
        this.setState({ stoped: true });
      }
    }
  }

  componentWillUnmount() {
    if (this.array[this.state.activeIndex]) {
      this.array[this.state.activeIndex].removeEventListener('data_ready', this.enterEvent);
      this.array[this.state.activeIndex].removeEventListener('enterFrame', this.enterEvent);
      this.array[this.state.activeIndex].destroy();
    }
    clearTimeout(this.timeoutID);
  }

  render() {
    return (
      <Container
        ref={(el: HTMLDivElement) => (this.container = el)}
        onMouseEnter={() => this.handleMouseEnter()}
      ></Container>
    );
  }
}

export default withUI(Letters);
