import styled from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

const indent = 23;
const indentTablet = 17;
const indentMobile = 12;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  h6 {
    top: ${indent}px;
    left: ${indent}px;
    z-index: 4;
    pointer-events: none;

    ${createMediaQuery().down(1024)} {
      top: ${indentTablet}px;
      left: 23px;
    }

    ${createMediaQuery().mobile()} {
      top: ${indentMobile}px;
      left: 23px;
    }
  }
`;

export const Image = styled.img<any>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: calc(100% - ${indent * 2}px);
  margin: auto;
  clip-path: circle(0% at 100% 100%);
  webkitclippath: circle(0% at 100% 100%);
  border-radius: 50%;

  ${createMediaQuery().down(1024)} {
    width: calc(100% - ${indentTablet * 2}px);
  }

  ${createMediaQuery().mobile()} {
    width: calc(100% - ${indentMobile * 2}px);
  }
`;
