import styled from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

export const Container = styled.div`
  position: relative; // TEMP
  width: 105%;
  height: 50%;
  z-index: 1;

  svg {
    position: absolute;
    top: -5%;
    right: -5%;
    bottom: -5%;
    left: -5%;
    margin: auto;
    pointer-events: none;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  picture {

  width: auto;
  height: 100%;
  z-index: 2;
  }

  h6 {
    top: 23px;
    left: 46px;
    z-index: 3;

    ${createMediaQuery().down(1024)} {
      top: 15px;
      left: 33px;
    }

    ${createMediaQuery().mobile()} {
      top: 12px;
      left: 24px;
    }
  }

  p {
    position: absolute;
    z-index: 10;
  }

  & > section {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    text-align: center;

    svg {
      display: block;
    }
  }

  & > section > svg circle {
    ${createMediaQuery().mobile()} {
      & > div {
        display: inline-block;
        width: 50%;

        & + div {
          padding-left: 10px;
        }
      }
    }
  }
`;

export const Image = styled.image<any>`
  width: auto;
  height: 100%;
  z-index: 2;
`;
