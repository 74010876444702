import styled, { keyframes } from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

export const indicatorTransition = 1400;
export const size = 11.85;

const animationBubble = keyframes`
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
`;

export const Wrapper = styled.div<any>`
  & > svg {
    opacity: ${props => (props.active ? 0 : 1)};
    transition: opacity ${props => (props.active ? 300 : 500)}ms ease;
    transition-delay: ${props => (!props.active ? 100 : 0)}ms;
  }
`;

export const Indicator = styled.div<any>`
  position: absolute;
  top: 0;
  right: 46px;
  bottom: 30px;
  margin: auto 0;
  height: ${size * 6}px;
  padding: ${size}px 0;
  transform: translateY(${props => (props.active ? -size * 4 + 20 : size * 4)}px);
  transition: transform ${props => (props.active ? indicatorTransition : 0)}ms linear;

  ${createMediaQuery().mobile()} {
    right: 20px;
  }

  span {
    display: block;
    width: ${size}px;
    height: ${size}px;
    border-radius: 50%;
    border: 3px solid #000;
    transform: scale(0);
    animation-timing-function: linear;
    animation: ${props => (props.active ? animationBubble : null)} 900ms 0s both;

    &:nth-child(even) {
      margin-left: ${size}px;
    }

    &:nth-child(1) {
      animation-delay: ${props => props.delay[0] || 0}s;
    }

    &:nth-child(2) {
      animation-delay: ${props => props.delay[1] || 0}s;
    }

    &:nth-child(3) {
      margin-top: 20px;
      animation-delay: ${props => props.delay[2] || 0}s;
    }

    &:nth-child(4) {
      animation-delay: ${props => props.delay[3] || 0}s;
    }
  }
`;
