import React from 'react';
import { Dot, List, GridWhereYouCanBuy } from './About.styles';
import Gallery from './Gallery';
import GridItem from '../../shared/Grid/GridItem';
import { IGridItem } from '../../shared/Grid/Grid.models';
import { locations } from '../../../assets/listOfLocations';
import { withUI, UI } from '../../../utils/UIContext';

const images = locations.map((i: any) => i.image);

interface IProps {
  ui: UI;
}

interface IState {
  activeLocation: number;
  galleryIsAnimated: boolean;
  hovered: boolean;
}

class WhereYouCanBuy extends React.Component<IProps, IState> {
  state: IState = {
    activeLocation: 0,
    galleryIsAnimated: false,
    hovered: false
  };

  timeoutID!: number | undefined;

  changeActiveLocation = () => {
    if (this.state.galleryIsAnimated) return;

    const lastIndex = images.length - 1;
    const activeLocation =
      this.state.activeLocation === lastIndex ? 0 : Math.min(this.state.activeLocation + 1, lastIndex);

    this.setState({ activeLocation });
  };

  changeLocation = () => {
    if (this.state.galleryIsAnimated || this.state.hovered) return;

    clearTimeout(this.timeoutID);

    const lastIndex = images.length - 1;
    const activeLocation =
      this.state.activeLocation === lastIndex ? 0 : Math.min(this.state.activeLocation + 1, lastIndex);

    this.setState({ activeLocation });
  };

  galleryHasAnimation = (isAnimated: boolean) => {
    this.setState({ galleryIsAnimated: isAnimated });
  };

  handleMouseEnter = (index: number) => {
    clearTimeout(this.timeoutID);
    this.setState({ activeLocation: index });
  };

  componentDidMount() {
    this.timeoutID = setTimeout(() => this.changeLocation(), 4000);
  }

  componentDidUpdate(_prevProps: IProps, prevState: IState) {
    const { activeLocation, hovered } = this.state;

    if (
      (activeLocation !== prevState.activeLocation && !hovered) ||
      (activeLocation === prevState.activeLocation && prevState.hovered)
    ) {
      this.timeoutID = setTimeout(() => this.changeLocation(), 4000);
    }
  }

  componentWillUnmout() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { activeLocation, hovered } = this.state;

    return (
      <GridWhereYouCanBuy>
        {!this.props.ui.isMobile && (
          <GridItem gridArea={'text'} hasInnerIndent>
            <p>
              <b>
                Where can
                <br />
                you get a<br />
                taste?
              </b>
            </p>
          </GridItem>
        )}

        <GridItem gridArea={'gallery'}>
          <Gallery
            activeIndex={activeLocation}
            hovered={hovered}
            images={images}
            prevent={true}
            timeout={3000}
            hasAnimation={this.galleryHasAnimation}
          ></Gallery>
        </GridItem>

        <GridItem gridArea={'locations'} hasInnerIndent>
          {this.props.ui.isMobile && (
            <p>
              <b>You can try the drink in Hong Kong’s bars:</b>
            </p>
          )}
          <List
            onMouseEnter={() => this.setState({ hovered: true })}
            onMouseLeave={() => this.setState({ hovered: false })}
          >
            <li>
              <ul>
                {locations
                  .filter((_item: IGridItem, key: number) => key <= 3)
                  .map((item: IGridItem, key: number) => (
                    <li key={key} onMouseEnter={() => this.handleMouseEnter(key)}>
                      <Dot active={activeLocation === key} />
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.boldText}
                      </a>
                    </li>
                  ))}
              </ul>
            </li>
            <li>
              <ul>
                {locations
                  .filter((_item: IGridItem, key: number) => key > 3)
                  .map((item: IGridItem, key: number) => (
                    <li key={key + 4} onMouseEnter={() => this.handleMouseEnter(key + 4)}>
                      <Dot active={activeLocation === key + 4} />
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.boldText}
                      </a>
                    </li>
                  ))}
              </ul>
            </li>
          </List>
        </GridItem>
      </GridWhereYouCanBuy>
    );
  }
}

export default withUI(WhereYouCanBuy);
