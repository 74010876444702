import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { PhoneInputContainer } from './PhoneInput.styles';
import EnterTextIndicator from '../EnterTextIndicator/EnterTextIndicator';

export interface PhoneInputProps {
  autoComplete?: string;
  defaultCountry?: string;
  form?: string;
  name?: string;
  placeholder?: string;
  value: string;
  checkPhoneValid: (phoneValid: boolean | undefined) => void;
  onChange: (value: string) => void;
  onBlur?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

class PhoneInput extends React.Component<PhoneInputProps> {
  state = { error: false, pristine: true };
  private input = React.createRef<any>();

  validate = () => {
    if (!this.input) return undefined;

    if (this.input.current.state.selectedCountry.format) {
      return this.input.current.state.selectedCountry.format.length === this.props.value.length;
    } else {
      return /^\+?\d{10,15}/.test(this.props.value);
    }
  };

  handleBlur = () => {
    this.setState({ pristine: false }, () => this.props.checkPhoneValid(this.validate()));
  };

  componentDidMount() {
    this.props.onChange(this.input.current.numberInputRef.value);
    this.props.checkPhoneValid(this.validate());
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.value !== prevProps.value) {
      this.props.checkPhoneValid(this.validate());
    }
  }

  render() {
    const { autoComplete, defaultCountry, form, name, placeholder, value, onChange } = this.props;
    const inputExtraProps = {
      autoComplete: autoComplete,
      'aria-label': 'Phone number',
      form: form,
      name: name,
      placeholder: placeholder,
      required: true
    };

    return (
      <PhoneInputContainer>
        <ReactPhoneInput
          defaultCountry={defaultCountry}
          inputExtraProps={inputExtraProps}
          ref={this.input}
          value={value}
          onChange={onChange}
          onBlur={this.handleBlur}
        />

        <EnterTextIndicator
          active={value || !this.state.pristine}
          error={!this.state.pristine && !this.validate()}
          value={value}
        ></EnterTextIndicator>
      </PhoneInputContainer>
    );
  }
}

export default PhoneInput;
