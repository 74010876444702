import React from 'react';

class IconSmile extends React.Component<any> {
  render() {
    const { error, active } = this.props;
    const color = error ? '#ca0303' : '#000';
    const style = { display: !active ? 'none' : '' };
    const stylePath = { transformOrigin: error ? '0 17px' : '', transform: error ? 'scaleY(-1)' : '' };

    return (
      <svg style={style} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g fill="none" fillRule="evenodd" transform="translate(2 2)">
          <circle cx="13" cy="13" r="13" stroke={color} strokeWidth="3" />
          <path stroke={color} strokeWidth="3" d="M9 14a4 4 0 1 0 8 0" style={stylePath} />
          <circle cx="9.5" cy="10.5" r="1.5" fill={color} />
          <circle cx="16.5" cy="10.5" r="1.5" fill={color} />
        </g>
      </svg>
    );
  }
}

export default IconSmile;
