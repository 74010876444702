import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Menu, Item } from './Navigation.styles';
import { routes, IRoute } from '../../routes/Routes';
import { UI, withUI } from '../../utils/UIContext';

const Routes = routes.filter((r: IRoute) => r.name);

interface IMenuBarProps {
  blendMode?: boolean;
  mobileMenuActive?: boolean;
  isMenuCollapsed: boolean;
  location: Location;
  visible: boolean;
  ui: UI;
  onClick: Function;
}

interface IMenuBarState {
  hoverIndex: number[];
}

class MenuBar extends React.Component<IMenuBarProps, IMenuBarState> {
  state = {
    hoverIndex: [-1, -1]
  };

  timeoutID: number | undefined;

  handleMouseEnterItem = (key: number, isSocials?: boolean, prevent?: boolean) => {
    if (prevent || this.props.ui.isMobile) return;
    clearTimeout(this.timeoutID);

    const hoverIndex = this.state.hoverIndex;
    hoverIndex[isSocials ? 1 : 0] = key;
    this.setState({ hoverIndex });
  };

  handleMouseLeaveItem = () => {
    if (this.props.ui.isMobile) return;
    this.timeoutID = setTimeout(() => this.setState({ hoverIndex: [-1, -1] }), 150);
  };

  render() {
    const { blendMode, mobileMenuActive, visible } = this.props;
    const { pathname } = this.props.location;
    const { isMobile } = this.props.ui;
    const { hoverIndex } = this.state;
    const hovered = (k: number, i: number = 0) => (hoverIndex[i] !== -1 ? hoverIndex[i] === k : hoverIndex[i] === -1);

    return (
      <Menu
        blendMode={blendMode}
        mobileMenuActive={mobileMenuActive}
        visible={visible}
        collapsed={this.props.isMenuCollapsed}
        onClick={this.props.onClick}
      >
        <ul role="menubar">
          {Routes.map((r: IRoute, key: number) => (
            <Item
              active={r.path === pathname}
              hovered={hovered(key)}
              key={key}
              role="menuitem"
              onMouseEnter={() => this.handleMouseEnterItem(key, false, r.path === pathname)}
              onMouseLeave={this.handleMouseLeaveItem}
            >
              <NavLink to={r.path}>{r.name}</NavLink>
            </Item>
          ))}
        </ul>
        <div>
          <ul>
            <Item hovered={hovered(0, 1)} onMouseEnter={() => this.handleMouseEnterItem(0, true)} onMouseLeave={this.handleMouseLeaveItem}>
              <a href="/" rel="noopener noreferrer" target="_blank">
                {isMobile ? 'Facebook' : 'Fb'}
              </a>
            </Item>
            <Item hovered={hovered(1, 1)} onMouseEnter={() => this.handleMouseEnterItem(1, true)} onMouseLeave={this.handleMouseLeaveItem}>
              <a href="https://www.instagram.com/thecarbonation/" rel="noopener noreferrer" target="_blank">
                {isMobile ? 'Instagram' : 'In'}
              </a>
            </Item>
          </ul>
        </div>
      </Menu>
    );
  }
}

export default withRouter(withUI(MenuBar));
