import React from 'react';
import { Btn, IconExpand } from './Button.styles';

interface ButtonProps {
  ariaLabel?: string;
  active?: boolean;
  children?: any;
  disabled?: boolean;
  expand?: boolean; // TODO после окончательного дизайна влзхможно нужно будет убрать
  forwardRef?: any;
  theme?: 'black' | 'white' | '';
  type: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

class Button extends React.Component<ButtonProps> {
  render() {
    const { active, ariaLabel, disabled, expand, forwardRef, theme, type, onClick } = this.props;

    return (
      <Btn
        aria-disabled={disabled}
        aria-label={ariaLabel}
        disabled={disabled}
        expand={expand}
        forwardRef={forwardRef}
        tabIndex={0}
        theme={theme}
        type={type}
        onClick={onClick}
      >
        {expand && (
          <IconExpand color={theme === 'black' ? '#fff' : ''} expanded={active}>
            <span></span>
            <span></span>
          </IconExpand>
        )}
        {this.props.children}
      </Btn>
    );
  }
}

export default Button;
