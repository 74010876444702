import styled from "styled-components";
import createMediaQuery from "../../assets/styles/media";

export const enter = 3000;
export const exit = 2000;

export const desktopRightIndent = 20;
export const desktopLeftIndent = 100;

export const Main = styled.main`
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  padding-top: 81px;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
    transition-delay: 2000ms;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 1500ms ease-in;
  }

  // FONTS
  h2,
  h6,
  p {
    white-space: pre-line;
    text-align: left;
    font-family: ItalianPlateNo1Expanded-Regular, Arial;
    font-weight: normal;
    font-style: normal;
    font-stretch: expanded;
  }

  h2 {
    font-size: 50px;
    line-height: 52px;

    ${createMediaQuery().up(1920)} {
      font-size: 63px;
      line-height: 65px;
    }

    ${createMediaQuery().up(3000)} {
      font-size: 84px;
      line-height: 88px;
    }

    ${createMediaQuery().up(4096)} {
      font-size: 115px;
      line-height: 115px;
    }

    ${createMediaQuery().down(1024)} {
      font-size: 34px;
      line-height: 36px;
    }

    ${createMediaQuery().mobile()} {
      font-size: 22px;
      line-height: 24px;
    }
  }

  h2 + p {
    margin-top: 40px;

    ${createMediaQuery().down(1440)} {
      margin-top: 30px;
    }

    ${createMediaQuery().mobile()} {
      margin-top: 20px;
    }
  }

  h6 {
    font-size: 24px;
    line-height: 26px;

    ${createMediaQuery().up(1920)} {
      font-size: 30px;
      line-height: 33px;
    }

    ${createMediaQuery().up(3000)} {
      font-size: 40px;
      line-height: 50px;
    }

    ${createMediaQuery().up(4096)} {
      font-size: 55px;
      line-height: 60px;
    }

    ${createMediaQuery().down(1024)} {
      font-size: 16px;
      line-height: 18px;
    }

    ${createMediaQuery().mobile()} {
      font-size: 14px;
      line-height: 16px;
    }
  }

  h6 + p {
    margin-top: 30px;

    ${createMediaQuery().down(1440)} {
      margin-top: 20px;
    }
  }

  p {
    font-size: 20px;
    line-height: 26px;

    ${createMediaQuery().up(1920)} {
      font-size: 25px;
      line-height: 33px;
    }

    ${createMediaQuery().up(3000)} {
      font-size: 38px;
      line-height: 50px;
    }

    ${createMediaQuery().up(4096)} {
      font-size: 50px;
      line-height: 64px;
    }

    ${createMediaQuery().down(1024)} {
      font-size: 16px;
      line-height: 22px;
    }

    ${createMediaQuery().mobile()} {
      font-size: 14px;
      line-height: 20px;
    }
  }

  a {
    font-family: ItalianPlateNo1Expanded-Regular, Arial;
    font-weight: normal;
    color: #000;
    font-size: 20px;
    line-height: 26px;
    text-decoration: none;

    ${createMediaQuery().up(1920)} {
      font-size: 25px;
      line-height: 33px;
    }

    ${createMediaQuery().down(1024)} {
      font-size: 16px;
      line-height: 22px;
    }

    ${createMediaQuery().up(3000)} {
      font-size: 38px;
      line-height: 50px;
    }

    ${createMediaQuery().up(4096)} {
      font-size: 50px;
      line-height: 64px;
    }

    ${createMediaQuery().mobile()} {
      font-size: 14px;
      line-height: 20px;
    }
  }

  b {
    font-family: ItalianPlateNo1Expanded-Bold, Arial;
    font-weight: bold;
  }
`;
