import styled from "styled-components";
import createMediaQuery from "../../../assets/styles/media";

export const RadioBtn = styled.label<any>`
  display: inline-block;
  padding: 21px 37px
  border-radius: 33px;
  border: solid 2px #000;
  cursor: pointer;
  opacity: ${(props) => (props.hovered || props.checked ? 1 : 0.1)};
  transition: opacity 300ms ease;
  outline: 0;

  ${createMediaQuery().down(1024)} {
    padding: 18px 30px;
  }

  ${createMediaQuery().mobile()} {
    display: block;
    width: max-content;
  }

  &:focus {
    opacity: 1;
  }

  & + label {
    margin-left: 10px;

    ${createMediaQuery().mobile()} {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  input {
    display: none;
  }

  span  {
    font-family: ItalianPlateNo1Expanded-Bold, Arial;
    font-weight: bold;
    font-size: 14px;
    font-style: normal;
    font-stretch: expanded;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
  }
`;
