import React from 'react';
import styled from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

const indent = 23;
const indentTablet = 17;
const indentMobile = 12;

export const Wrapper = styled(({ hasInnerIndent, ...rest }) => <div {...rest} />)`
  position: relative;
  width: 100%
  padding: ${props => (!props.hasInnerIndent ? '0' : `${indent}px`)};

  ${createMediaQuery().down(1024)} {
    padding: ${props => (!props.hasInnerIndent ? '0' : `${indentTablet}px`)};
  }

  ${createMediaQuery().mobile()} {
    padding: ${props => (!props.hasInnerIndent ? '0' : `${indentMobile}px`)};
  }

  h6 {
    top: ${props => (!props.hasInnerIndent ? '0' : '23px')};
    left: ${props => (!props.hasInnerIndent ? '0' : '23px')};

    ${createMediaQuery().down(1024)} {
      top: ${props => (!props.hasInnerIndent ? '0' : '17px')};
      left: ${props => (!props.hasInnerIndent ? '0' : '23px')};
    }

    ${createMediaQuery().mobile()} {
      top: ${props => (!props.hasInnerIndent ? '0' : '12px')};
      left: ${props => (!props.hasInnerIndent ? '0' : '23px')};
    }
  }
`;

export const Img = styled(({ alt, active, delay, withInitialAnimation, ...rest }) => <img alt={alt} {...rest} />)`
  width: 100%;
  transition: opacity ${props => (props.withInitialAnimation ? 700 : 300)}ms ease,
    clip-path ${props => (props.active ? 700 : 330)}ms linear,
    -webkit-clip-path ${props => (props.active ? 700 : 330)}ms linear;
  transition-delay: ${props => props.delay || 0}ms;
  border-radius: 50%;

  ${props =>
    props.withInitialAnimation &&
    ` opacity: ${props.active ? 1 : 0};
      clip-path: circle(${props.active ? 100 : 0}% at ${props.active ? '50% 0%' : `50% calc(85% + ${indent * 2}px)`});
      -webkit-clip-path: circle(${props.active ? 100 : 0}% at ${
      props.active ? '50% 0%' : `50% calc(85% + ${indent * 2}px)`
    });

    ${createMediaQuery().down(1024)} {
        clip-path: circle(${props.active ? 100 : 0}% at ${
      props.active ? '50% 0%' : `50% calc(85% + ${indentTablet * 2}px)`
    });
      -webkit-clip-path: circle(${props.active ? 100 : 0}% at ${
      props.active ? '50% 0%' : `50% calc(85% + ${indentTablet * 2}px)`
    });
      }

    ${createMediaQuery().mobile()} {
      clip-path: circle(${props.active ? 100 : 0}% at ${
      props.active ? '50% 0%' : `50% calc(85% + ${indentMobile * 2}px)`
    });
      -webkit-clip-path: circle(${props.active ? 100 : 0}% at ${
      props.active ? '50% 0%' : `50% calc(85% + ${indentMobile * 2}px)`
    });
      }
    `};

  ${Wrapper}:hover & {
    opacity: ${props => (props.withInitialAnimation ? 1 : 0.8)};

    ${createMediaQuery().mobile()} {
      opacity: 1;
    }
  }
`;
