import React, { useEffect, useCallback } from 'react';
import { gsap } from 'gsap';
import { StartElement } from './Products.styles';
import { ILetters } from '../../shared/Grid/Grid.models';
import Letters from '../../shared/Letters/Letters';
import { withUI, UI } from '../../../utils/UIContext';

const DURATION = 0.45;

interface Props {
  activeSide: '' | 'leftSide' | 'rightSide';
  alignBottom: boolean;
  colWidth: number;
  element: ILetters;
  side: 'leftSide' | 'rightSide';
  ui: UI;
}

const StartElements = (props: Props) => {
  const { activeSide, alignBottom, colWidth, element, side } = props;
  const { isMobile, windowWidth } = props.ui;

  const elementRef = React.useRef<HTMLDivElement>(null);

  const startElementShift = useCallback((resize?: boolean) => {
    if (!elementRef.current || isMobile) return;

    const el = elementRef.current;
    const shift = colWidth / 2;
    const onComplete = () => gsap.set(el, { display: 'none' });

    const duration = resize ? 0 : DURATION;
    
    if (activeSide === 'leftSide') {
      if (side === 'leftSide') {
        gsap.to(el, { opacity: 0 });
      } else if (side === 'rightSide') {
        alignBottom
          ? gsap.to(el, { x: windowWidth >= 1440 ? shift : -20, opacity: 1, duration: duration })
          : gsap.to(el, { opacity: 0, duration: duration, onComplete });
      }
    } else if (activeSide === 'rightSide') {
      if (side === 'leftSide') {
        alignBottom
          ? gsap.to(el, { x: windowWidth >= 1440 ? shift : colWidth, opacity: 1, duration: duration })
          : gsap.to(el, { opacity: 0, duration: duration, onComplete });
      } else if (side === 'rightSide') {
        gsap.to(el, { opacity: 0 });
      }
    }
  }, [activeSide,alignBottom, colWidth, isMobile, side,windowWidth]);

  useEffect(() => {
    startElementShift();
  }, [startElementShift]);


  // useEffect(() => {
  //   startElementShift(true);
  // }, [windowWidth, startElementShift]);

  return (
    <StartElement bottom={alignBottom} ref={elementRef}>
      <Letters autoplay={!isMobile ? activeSide !== side : false} letters={element.src} timeoutBetweenLoop={element.timeout}></Letters>
    </StartElement>
  );
};

export default withUI(StartElements);
