import React from 'react';
import Lottie from 'lottie-web';
import { Container } from './Person.styles';

interface ILetterProps {
  autoplay: boolean;
  hovered: boolean;
  letters: any[];
  timeoutBetweenLoop?: number;
  animatingLetters: (complete: boolean) => void;
}

interface ILetterState {
  activeIndex: number;
  isAnimating: boolean;
}

class Letter extends React.Component<ILetterProps, ILetterState> {
  state: ILetterState = {
    activeIndex: 0,
    isAnimating: false
  };

  private array: any[] = [];
  private container!: HTMLDivElement;
  timeoutID!: number;

  // startAutoplayAnimation() {
  //   const { timeoutBetweenLoop } = this.props;
  //   const t = !!timeoutBetweenLoop ? timeoutBetweenLoop : 2000;

  //   if (this.props.autoplay) {
  //     this.timeoutID = setTimeout(() => this.setState({ activeIndex: 0 }), t);
  //   }
  // }

  initLetters() {
    this.props.letters.forEach((path: string, index: number) => {
      if (this.container && path) {
        const params: any = {
          container: this.container,
          autoplay: false,
          loop: false,
          path: path,
          renderer: 'svg',
          rendererSettings: {
            progressiveLoad: true // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
          }
        };

        this.array.push(Lottie.loadAnimation(params));
        this.array[index].setSpeed(0.7);

        this.array[index].addEventListener('data_ready', () => {
          if (index !== this.state.activeIndex) {
            this.array[index].goToAndStop(this.array[index].totalFrames / 2, true);
          }
        });
      }
    });

    if (this.props.autoplay) {

      this.timeoutID = setTimeout(() => {
        this.array[this.state.activeIndex].addEventListener('enterFrame', this.enterEvent);
        this.array[this.state.activeIndex].play();
      }, this.props.timeoutBetweenLoop || 0);
    }
  }

  enterEvent = () => {
    if (!this.array) return;

    const { activeIndex } = this.state;
    const { hovered } = this.props;
    const lastIndex = this.props.letters.length - 1;

    if (Math.ceil(this.array[activeIndex].currentFrame) === this.array[activeIndex].totalFrames / 2) {
      this.array[activeIndex].pause();
      this.setState({
        activeIndex: activeIndex === lastIndex ? 0 : Math.min(activeIndex + 1, lastIndex),
        isAnimating: true
      });

      if (!hovered) this.array[this.state.activeIndex].goToAndPlay(46, true);
    } else if (this.array[activeIndex].currentFrame === this.array[activeIndex].totalFrames - 1) {
      this.array[this.state.activeIndex].goToAndStop(0, true);
    } else if (this.array[this.state.activeIndex].currentFrame === 0) {
      clearTimeout(this.timeoutID);
      this.setState({ isAnimating: false });

      this.timeoutID = setTimeout(() => {
        this.array[this.state.activeIndex].pause();
        this.array[this.state.activeIndex].play();
      }, 0);
    }
  };

  componentDidMount() {
    this.initLetters();
    // this.startAutoplayAnimation();
  }

  componentDidUpdate(prevProps: ILetterProps, prevState: ILetterState) {
    const { animatingLetters, hovered } = this.props;
    const { activeIndex, isAnimating } = this.state;

    if (activeIndex !== prevState.activeIndex) {
      this.array[this.state.activeIndex].addEventListener('enterFrame', this.enterEvent);
    }

    if (hovered !== prevProps.hovered) {
      this.array[activeIndex].play();

      if (hovered) {
        isAnimating ? this.array[activeIndex].setSpeed(1.4) : this.array[activeIndex].setSpeed(0.7);
      }
    }

    if (isAnimating !== prevState.isAnimating) {
      animatingLetters(isAnimating);
    }
  }

  componentWillUnmount() {
    this.array[this.state.activeIndex].removeEventListener('enterFrame', this.enterEvent);
    this.array[this.state.activeIndex].destroy();
    clearTimeout(this.timeoutID);
  }

  render() {
    return <Container ref={(el: HTMLDivElement) => (this.container = el)}></Container>;
  }
}

export default Letter;
