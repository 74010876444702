import React from 'react';
import { Wrapper, Img } from './Image.styles';
import AnimatedText from '../AnimatedText/AnimatedText';
import { IImage } from '../../shared/Grid/Grid.models';
import { withUI, UI } from '../../../utils/UIContext';

interface IImageProps {
  active?: boolean;
  delay?: number;
  hasInnerIndent?: boolean;
  heading?: string;
  headingAlwaysVisible?: boolean;
  image: IImage;
  ui: UI;
  withInitialAnimation?: boolean;
}
interface IImageState {
  hovered: boolean;
}

class Image extends React.Component<IImageProps, IImageState> {
  state = { hovered: false };

  handleMouseEnter = () => {
    if (this.props.ui.isMobile) return;
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    if (this.props.ui.isMobile) return;
    this.setState({ hovered: false });
  };

  render() {
    const { active, delay, heading, hasInnerIndent, headingAlwaysVisible, image, withInitialAnimation } = this.props;

    if (!image || !image.src) return null;

    return (
      <Wrapper
        hasInnerIndent={hasInnerIndent}
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        {heading && <AnimatedText hovered={this.state.hovered} text={heading} />}
        <picture>
          <source data-srcset={image.webp} type="image/webp" />
          <source data-srcset={`${image.src}, ${image.src2x} 2x`} type="image/jpeg" />
          <Img
            alt={image.alt}
            active={active || headingAlwaysVisible}
            delay={delay}
            data-sizes="auto"
            data-src={image.src}
            data-srcset={`${image.src}, ${image.src2x} 2x`}
            className="lazyload"
            withInitialAnimation={withInitialAnimation}
          />
        </picture>
      </Wrapper>
    );
  }
}

export default withUI(Image);
