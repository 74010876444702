import { IGrid } from '../../shared/Grid/Grid.models';
import { cofeeTonic, ginTonic, whiskeyHighball } from '../../../assets/listOfCocktails';
import { SW_small, TW_small } from '../../../assets/listOfBottles';

export const grid: IGrid[] = [
  {
    items: [
      {
        type: 'text',
        heading: 'Gin &\nTonic',
        text: 'The classic gin and tonic is a light, refreshing and foolproof\n4-ingredient cocktail.'
      },
      {
        type: 'gallery',
        gallery: ginTonic,
        lettersGallery: [
          {
            src: ['/letters/3A_T.json', '/letters/1A_T.json'],
            timeout: 6000
          },
          {
            src: ['/letters/1A_C.json', '/letters/2A_C.json'],
            timeout: 3000
          }
        ]
      },
      {
        type: 'steps',
        steps: [
          {
            heading: 'Ingredients:',
            text:
              '2oz. of your favourite gin\n\n4oz. of Carbonation Tonic Water\n\nA twist of lime peel\nIce\n\nBest served in a Collins glass'
          },
          {
            heading: 'Step 1',
            text: 'Be sure to chill the glass beforehand. Pour your gin into the glass.'
          },
          {
            heading: 'Step 2',
            text: 'Add plenty of ice with the gin.'
          },
          {
            heading: 'Step 3',
            text:
              'Pour in the chilled Carbonation Tonic Water then using a bar-spoon, gently give it a single stir from the bottom to the top of the glass.'
          },
          {
            heading: 'Step 4',
            text: 'Add a twist of lime to finish it off. '
          }
        ]
      },
      {
        type: 'ingredients',
        heading: 'Ingredients:',
        text:
          '2oz. of your\nfavourite gin\n\n4oz. of\nCarbonation Tonic Water\n\nA twist of lime peel\nIce\n\nBest served in\na Collins glass'
      },
      {
        type: 'bottle',
        images: TW_small,
        heading: 'Tonic\nWater'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/1A_C.json', '/letters/2A_C.json', '/letters/7A_C.json'] }
      },
      {
        type: 'bold_text',
        heading: 'Always chill your Carbonation Tonic Water before mixing.'
      }
    ]
  },
  {
    items: [
      {
        type: 'text',
        heading: 'Cofee Tonic',
        text:
          'Smooth and creamy pick me up for your mornings, afternoons and evenings. Perfect for those who enjoy a slightly less sweet version. '
      },
      {
        type: 'gallery',
        gallery: cofeeTonic,
        lettersGallery: [
          {
            src: ['/letters/7A_C.json', '/letters/2A_C.json'],
            timeout: 8700
          },
          {
            src: ['/letters/3A_T.json', '/letters/1A_T.json'],
            timeout: 4300
          }
        ]
      },
      {
        type: 'steps',
        steps: [
          {
            heading: 'Ingredients:',
            text: '2 shots of\nEspresso\n\n4oz. of Carbonation Tonic Water\n\nIce'
          },
          {
            heading: 'Step 1',
            text: 'Fill the glass with ice.'
          },
          {
            heading: 'Step 2',
            text: 'Pour double shot of Espresso on top of the ice.'
          },
          {
            heading: 'Step 3',
            text: 'Gently top up with chilled\nCarbonation Tonic Water.'
          }
        ]
      },
      {
        type: 'ingredients',
        heading: 'Ingredients:',
        text: '2 shots of\nEspresso\n\n4oz. of Carbonation Tonic Water\n\nIce'
      },
      {
        type: 'bottle',
        images: TW_small,
        heading: 'Tonic\nWater'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/1A_G.json', '/letters/2A_C.json'] }
      },
      {
        type: 'bold_text',
        heading: 'Considerably bubbly once you open me up and get to know me.'
      }
    ]
  },
  {
    items: [
      {
        type: 'text',
        heading: 'Smokey Highball',
        text:
          'Perennial classic of whiskey mixed with soda water but with a unique twist from our heritage lychee wood scent.'
      },
      {
        type: 'gallery',
        gallery: whiskeyHighball,
        lettersGallery: [
          {
            src: ['/letters/2A_H.json', '/letters/3A_H.json'],
            timeout: 4300
          },
          {
            src: ['/letters/5A_W.json', '/letters/3A_W.json'],
            timeout: 8700
          }
        ]
      },
      {
        type: 'steps',
        steps: [
          {
            heading: 'Ingredients:',
            text: '2oz of Bourbon Whiskey\n\n4oz. of\nCarbonation Smoked Water\n\nIce'
          },
          {
            heading: 'Step 1',
            text: 'Add you choice of bourbon to a chilled glass.'
          },
          {
            heading: 'Step 2',
            text: 'Fill the glass with an abundance of ice.'
          },
          {
            heading: 'Step 3',
            text: 'Add Carbonation Smoked Water and gently give it a single stir.'
          },
          {
            heading: 'Step 4',
            text: 'Garnish with a twist of lemon.'
          }
        ]
      },
      {
        type: 'ingredients',
        heading: 'Ingredients:',
        text: '2oz of Bourbon Whiskey\n\n4oz. of\nCarbonation Smoked Water\n\nIce'
      },
      {
        type: 'bottle',
        images: SW_small,
        heading: 'Smoked\nWater'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/1A_C.json', '/letters/7A_C.json', '/letters/2A_C.json'] }
      },
      {
        type: 'bold_text',
        heading: 'Remember,\nlife is\na marathon\n& not a sprint.'
      }
    ]
  }
];
