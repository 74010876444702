import React from "react";
import styled from "styled-components";
import { desktopLeftIndent } from "../../pages/Pages.styles";
import createMediaQuery from "../../../assets/styles/media";

export const Btn = styled(({ expand, forwardRef, theme, type, ...rest }) => (
  <button ref={forwardRef} {...rest} />
))`
  position: relative;
  min-width: 0;
  cursor: pointer;
  user-select: none;
  outline: 0;

  width: 100%;
  background-color: ${(props) =>
    props.type === "submit" || props.theme === "black"
      ? "#000"
      : props.theme === "white"
      ? "#fff"
      : "transparent"};
  border-top: 2px solid
    ${(props) => (props.theme === "black" ? "#fff" : "#000")};
  ${(props) =>
    props.type === "submit"
      ? "padding: 40px 0"
      : `padding: 34px 38px 30px ${desktopLeftIndent}px`};

  // FONT STYLE
  font-family: ItalianPlateNo1Expanded-Regular, Arial;
  font-weight: normal;
  font-size: 28px;
  font-style: normal;
  font-stretch: expanded;
  line-height: normal;
  letter-spacing: 1px;
  color: ${(props) =>
    props.type === "submit" || props.theme === "black" ? "#fff" : "#000"};
  text-align: ${(props) => (props.type === "submit" ? "center" : "left")};

  ${createMediaQuery().down(1024)} {
    font-size: 24px;
  }

  ${createMediaQuery().mobile()} {
    font-size: 16px;
    line-height: normal;
  }

  ${createMediaQuery().mobile()} {
    ${(props) =>
      props.type === "submit"
        ? "padding: 40px 0"
        : "padding: 34px 20px 35px 68px;"};
  }

  p {
    font-family: ItalianPlateNo1Expanded-Bold, Arial;
    font-weight: bold;
    font-size: inherit;

    & + span {
      margin: 26px 0 0;

      ${createMediaQuery().mobile()} {
        margin: 10px 0 0;
      }
    }
  }

  // expanded
  ${(props) =>
    props.expand &&
    `&::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      display: block;
      height: 2px;
      background-color: #000;
    }
    
    & > span {
      font-family: ItalianPlateNo1Expanded-Regular, Arial;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
    }
  `};
`;

export const IconExpand = styled(({ color, expanded, ...rest }) => (
  <div {...rest} />
))`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  left: 40px;
  margin: auto;
  pointer-events: none;

  ${createMediaQuery().up(1920)} {
    width: 30px;
    height: 30px;
    left: 35px;
  }

  ${createMediaQuery().up(1280)} {
    width: 34px;
    height: 34px;
    left: 33px;
  }

  ${createMediaQuery().mobile()} {
    width: 20px;
    height: 20px;
    left: 20px;
  }

  span {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    margin: auto;
    background-color: ${(props) => props.color || "#000"};

    &:first-child {
      width: inherit;
      height: 4px;

      ${createMediaQuery().mobile()} {
        height: 2px;
      }
    }

    &:last-child {
      left: calc(50% - 2px);
      width: 4px;
      height: inherit;
      transform: rotate(${(props) => (props.expanded ? "-90deg" : "0deg")});
      transition: transform 250ms ease;
      transform-origin: center;

      ${createMediaQuery().mobile()} {
        left: calc(50% - 1px);
        width: 2px;
      }
    }
  }
`;
