import { IGrid } from '../../shared/Grid/Grid.models';

export const products: IGrid[] = [
  {
    side: 'leftSide',
    startElement: [
      {
        src: [
          '/letters/1A_T_invert.json',
          '/letters/3A_T_invert.json',
          '/letters/5A_T_invert.json',
          '/letters/2A_T_invert.json',
          '/letters/7A_T_invert.json',
          '/letters/6A_T_invert.json'
        ],
        timeout: 4000
      },
      {
        src: [
          '/letters/7A_W_invert.json',
          '/letters/2A_W_invert.json',
          '/letters/1A_W_invert.json',
          '/letters/5A_W_invert.json',
          '/letters/6A_W_invert.json',
          '/letters/3A_W_invert.json'
        ],
        timeout: 3700
      }
    ],
    items: [
      {
        type: 'column',
        column: [
          { type: 'main_text', heading: 'Tonic \n Water', text: 'A mixture of young and old.' },
          {
            type: 'text',
            text:
              'Pairing locally grown organic lemons picked right on the day of production, together with the distinctive aged flavour of dried tangerine peels.\n\nFlavoured with natural cane sugar specifically to avoid the cloying mouthfeel. '
          },
          {
            type: 'image',
            image: {
              alt: 'Lemons',
              src: '/images/ingredients/organic-lemons.jpg',
              src2x: '/images/ingredients/organic-lemons@2x.jpg',
              webp: '/images/ingredients/organic-lemons@2x.webp'
            },
            whenVisible: 'desktop'
          }
        ]
      },
      {
        type: 'column',
        column: [
          {
            type: 'bottle',
            images: [
              {
                alt: 'Tonic Water',
                src: '/images/bottles/Static_botles_TW.png',
                src2x: '/images/bottles/Static_botles_TW@2x.png',
                webp: '/images/bottles/Static_botles_TW@2x.webp'
              },
              {
                alt: 'Tonic Water',
                src: '/images/bottles/Static_botles_TW.jpg',
                src2x: '/images/bottles/Static_botles_TW@2x.jpg',
                webp: '/images/bottles/Static_botles_TW@2x.webp'
              },
              {
                alt: 'Tonic Water',
                src: '/images/bottles/Static_botles_TW_mobile.jpg',
                src2x: '/images/bottles/Static_botles_TW_mobile@3x.jpg',
                webp: '/images/bottles/Static_botles_TW_mobile@3x.webp'
              }
            ]
          }
        ]
      },
      {
        type: 'column',
        column: [
          {
            type: 'image',
            image: {
              alt: 'Gin Tonic',
              src: '/images/cocktails/gin-and-tonic.jpg',
              src2x: '/images/cocktails/gin-and-tonic@2x.jpg',
              webp: '/images/cocktails/gin-and-tonic@2x.webp'
            },
            delay: 150
          },
          {
            type: 'bold_text',
            boldText: 'Gin and Tonic',
            text: 'We may need to have a word if you still don’t know what this is.',
            url: '/cocktails'
          }
        ]
      }
    ]
  },
  {
    side: 'rightSide',
    startElement: [
      {
        src: [
          '/letters/S.json',
          '/letters/4A_S.json',
          '/letters/2A_S.json',
          '/letters/5A_S.json',
          '/letters/7A_S.json',
          '/letters/1A_S.json'
        ],
        timeout: 5300
      },
      {
        src: [
          '/letters/1A_W.json',
          '/letters/3A_W.json',
          '/letters/7A_W.json',
          '/letters/2A_W.json',
          '/letters/4A_W.json',
          '/letters/5A_W.json'
        ],
        timeout: 3000
      }
    ],
    items: [
      {
        type: 'column',
        column: [
          { type: 'main_text', heading: 'Smoked \n Water', text: 'Soda water with a twist.' },
          {
            type: 'text',
            text:
              'This crisp, highly carbonated water is elevated with a slight hint of smokiness from heritage lychee wood chips. \n\nThe addition of rich minerals from pink Himalayan salt adds a depth of flavour along with premium quality bicarbonate of soda.'
          },
          {
            type: 'image',
            image: {
              alt: 'Lychee',
              src: '/images/ingredients/lychee-wood-scent.jpg',
              src2x: '/images/ingredients/lychee-wood-scent@2x.jpg',
              webp: '/images/ingredients/lychee-wood-scent@2x.webp'
            },
            delay: 300,
            whenVisible: 'desktop'
          }
        ]
      },
      {
        type: 'column',
        column: [
          {
            type: 'bottle',
            images: [
              {
                alt: 'Smoked Water',
                src: '/images/bottles/Static_botles_SW.png',
                src2x: '/images/bottles/Static_botles_SW@2x.png',
                webp: '/images/bottles/Static_botles_SW@2x.webp'
              },
              {
                alt: 'Smoked Water',
                src: '/images/bottles/Static_botles_SW.jpg',
                src2x: '/images/bottles/Static_botles_SW@2x.jpg',
                webp: '/images/bottles/Static_botles_SW@2x.webp'
              },
              {
                alt: 'Smoked Water',
                src: '/images/bottles/Static_botles_SW_mobile.jpg',
                src2x: '/images/bottles/Static_botles_SW_mobile@3x.jpg',
                webp: '/images/bottles/Static_botles_SW_mobile@3x.webp'
              }
            ]
          }
        ]
      },
      {
        type: 'column',
        column: [
          {
            type: 'image',
            image: {
              alt: 'Highball',
              src: '/images/cocktails/whiskey-highball.jpg',
              src2x: '/images/cocktails/whiskey-highball@2x.jpg',
              webp: '/images/cocktails/whiskey-highball@2x.webp'
            },
            delay: 200
          },
          {
            type: 'bold_text',
            boldText: 'Smokey Whiskey Highball',
            text: 'Perennial classic elevated with a smokey twist.',
            url: '/cocktails'
          }
        ]
      }
    ]
  }
];
