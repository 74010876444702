import React from 'react';
import ReactDOM from 'react-dom';
import { Nav, MobileButton } from './Navigation.styles';
import MenuBar from './MenuBar';
import Logotype from './Logotype';
import { withUI, UI } from '../../utils/UIContext';

interface INavigationProps {
  blendMode?: boolean;
  ui: UI;
}

interface INavigationState {
  isMenuCollapsed: boolean;
  isMobileMenuOpened: boolean;
  isMobileMenuVisible: boolean;
}

class Navigation extends React.Component<INavigationProps, INavigationState> {
  state: INavigationState = {
    isMenuCollapsed: false,
    isMobileMenuOpened: false,
    isMobileMenuVisible: false
  };

  nav = React.createRef<HTMLElement>();
  timeoutID: number | undefined;

  open() {
    document.body.style.overflow = 'hidden';
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(() => this.setState({ isMobileMenuVisible: true }), 0);
  }

  close() {
    document.body.style.overflow = '';
    clearTimeout(this.timeoutID);
    this.setState({ isMobileMenuVisible: true }, () => {
      this.timeoutID = setTimeout(() => {
        this.setState({ isMobileMenuVisible: false });
      }, 1000);
    });
  }

  menuCollapsed(isMenuCollapsed: boolean) {
    this.setState({ isMenuCollapsed });
  }

  handleOpenMobileMenu = () => {
    if (!this.props.ui.isMobile) return;
    this.setState({ isMobileMenuOpened: !this.state.isMobileMenuOpened });
  };

  handleMenuMouseEnter = () => {
    if (this.props.ui.isMobile) return;
    this.menuCollapsed(false);
  };

  handleMenuMouseLeave = () => {
    const nav = this.nav.current;
    if ((nav && this.props.ui.pageScrollTop <= nav.clientHeight) || this.props.ui.isMobile) return;
    this.menuCollapsed(true);
  };

  componentDidUpdate(prevProps: INavigationProps, prevState: INavigationState) {
    const { isMobile, pageScrollTop } = this.props.ui;

    if (isMobile) {
      if (prevState.isMobileMenuOpened !== this.state.isMobileMenuOpened && this.state.isMobileMenuOpened) {
        this.open();
      } else if (!this.state.isMobileMenuOpened && prevState.isMobileMenuOpened) {
        this.close();
      }
    }

    if (prevProps.ui.pageScrollTop !== pageScrollTop && this.nav.current) {
      const isMenuCollapsed = isMobile ? false : pageScrollTop > this.nav.current.clientHeight;
      this.menuCollapsed(isMenuCollapsed);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { animateChangePage, isMobile } = this.props.ui;
    const isOpen = this.state.isMobileMenuOpened && this.state.isMobileMenuVisible;
    const changeColor = isMobile && this.state.isMobileMenuOpened;

    return (
      <Nav
        blendMode={this.props.blendMode}
        mobileMenuActive={isOpen}
        ref={this.nav}
        onMouseEnter={this.handleMenuMouseEnter}
        onMouseLeave={this.handleMenuMouseLeave}
      >
        {isMobile && (
          <MobileButton
            aria-label="Menu button"
            mobileMenuActive={this.state.isMobileMenuOpened}
            onClick={this.handleOpenMobileMenu}
          >
            {Array.from({ length: 4 }).map((d: any, key: number) => (
              <span key={key}></span>
            ))}
          </MobileButton>
        )}

        <Logotype
          animate={animateChangePage}
          changeColor={changeColor}
          onClick={() => this.setState({ isMobileMenuOpened: false })}
        />

        {isMobile && (this.state.isMobileMenuOpened || this.state.isMobileMenuVisible)
          ? ReactDOM.createPortal(
              <MenuBar
                mobileMenuActive={isOpen}
                visible={this.state.isMobileMenuVisible || this.state.isMobileMenuOpened}
                onClick={this.handleOpenMobileMenu}
              ></MenuBar>,
              this.nav.current || document.body
            )
          : !isMobile && (
              <MenuBar blendMode={this.props.blendMode} isMenuCollapsed={this.state.isMenuCollapsed}></MenuBar>
            )}
      </Nav>
    );
  }
}

export default withUI(Navigation);
