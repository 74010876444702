import React from 'react';
import { gsap } from 'gsap';
import { Image, Wrapper } from './Person.styles';
import Letter from './Letter';
import AnimatedText from '../AnimatedText/AnimatedText';
import { IImage } from '../Grid/Grid.models';
import { withUI, UI } from '../../../utils/UIContext';

interface IPersonProps {
  name: string;
  sources: any[];
  image: IImage;
  timeoutBetweenLoop?: number;
  ui: UI;
  animatingLetters?: (complete: boolean) => void;
}

interface IPersonState {
  hovered: boolean;
  id: string;
  isAnimating: boolean;
  lettersIsAnimating: boolean;
  opened: boolean;
}

class Person extends React.Component<IPersonProps, IPersonState> {
  state: IPersonState = {
    hovered: false,
    id:
      Date.now().toString(36) +
      Math.random()
        .toString(36)
        .substr(2, 5),
    isAnimating: false,
    lettersIsAnimating: false,
    opened: false
  };

  private imageMask!: any;
  timeoutID!: number;

  animatingLetters = (lettersIsAnimating: boolean) => {
    this.setState({ lettersIsAnimating });
  };

  initialState() {
    if (this.imageMask) {
      gsap.set(this.imageMask.children[0], { r: 0, y: '25%' });
      gsap.set(this.imageMask.children[1], { r: 0, y: '75%' });
      this.setState({ isAnimating: false, opened: false });
    }
  }

  onComplete() {
    this.setState({ isAnimating: false });
  }

  animation = (hovered?: boolean) => {
    if (!this.imageMask || this.state.isAnimating) return;

    this.setState({ isAnimating: true });
    const onComplete = () => this.setState({ opened: true, isAnimating: false });
    const topCircle = this.imageMask.children[0];
    const bottomCircle = this.imageMask.children[1];

    if (hovered) {
      gsap.to(topCircle, { r: '25%', y: '0', duration: 0.5, delay: 0.5, onComplete });
      gsap.to(bottomCircle, { r: '25%', y: '0', duration: 0.5, delay: 0.3 });
    } else {
      gsap.to(topCircle, { r: '0%', y: '-40%', duration: 0.5, delay: 0.2, onComplete: () => this.initialState() });
      gsap.to(bottomCircle, { r: '0%', y: '-10%', duration: 0.5, delay: 0 });
    }
  };

  handleMouseEnter = () => {
    if (this.props.ui.isMobile) return;
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    if (this.props.ui.isMobile) return;
    this.setState({ hovered: false, opened: false });
  };

  componentDidMount() {
    this.initialState();
  }

  componentDidUpdate(_prevProps: IPersonProps, prevState: IPersonState) {
    const { hovered, isAnimating, lettersIsAnimating, opened } = this.state;

    if (hovered !== prevState.hovered && !isAnimating) {
      if (hovered && !lettersIsAnimating) {
        this.animation(true);
      } else if (!hovered) {
        this.animation();
      }
    }

    if (opened !== prevState.opened && opened && !hovered) {
      this.animation();
    }

    if (isAnimating !== prevState.isAnimating && !isAnimating && !opened && hovered) {
      this.animation(true);
    }

    if (lettersIsAnimating !== prevState.lettersIsAnimating) {
      if (!lettersIsAnimating && hovered) {
        this.animation(true);
      }
      if (!hovered && opened) {
        this.animation();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { hovered, id, opened } = this.state;
    const { name, sources, image, timeoutBetweenLoop } = this.props;

    return (
      <Wrapper onMouseEnter={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()}>
        {name && <AnimatedText hovered={opened} text={name} />}
        <section>
          <svg width={'100%'} height={'100%'}>
            <defs>
              <clipPath id={id} ref={(el: any) => (this.imageMask = el)}>
                <circle cx="50%" cy="75%" r="25%" fill="#C4C4C4" />
                <circle cx="50%" cy="25%" r="25%" fill="#C4C4C4" />
              </clipPath>
            </defs>
            <Image width="100%" height="100%" href={image.src} clipPath={`url(#${id})`} />
          </svg>
        </section>

        {sources &&
          sources.map((s: any, index: number) => (
            <Letter
              animatingLetters={this.animatingLetters}
              autoplay
              hovered={hovered}
              key={index}
              letters={s}
              timeoutBetweenLoop={timeoutBetweenLoop}
            ></Letter>
          ))}
      </Wrapper>
    );
  }
}

export default withUI(Person);
