import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import * as serviceWorker from './serviceWorker';
import lazysizes from 'lazysizes';
import 'intersection-observer';

import 'lazysizes/plugins/parent-fit/ls.parent-fit';

lazysizes.cfg.expFactor = 1;
lazysizes.cfg.expand = 1;
lazysizes.cfg.loadMode = 1;

smoothscroll.polyfill();

const rootElement = document.getElementById('root');

// if (rootElement!.hasChildNodes()) {
//   ReactDOM.hydrate(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     rootElement
//   );
// } else {
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);
// }

// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
