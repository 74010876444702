import styled from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

export const Character = styled.span<any>`
  display: ${props => (props.whiteSpace ? 'inline' : 'inline-block')};
  transition: opacity 180ms ease, transform 200ms ease;
  opacity: 0;
  transition-delay: ${props => props.index * 50 || 0}ms;
  white-space: pre;
  transform: translate3d(
      0,
      ${props => (props.from === 'fromDown' ? '15px' : props.from === 'fromMiddle' ? '-15px' : 0)},
      0
    )
    scale(0.2);

  ${props =>
    !props.alwaysAnimated &&
    `
    ${createMediaQuery().mobile()} {
      opacity: 1;
      transform: none;
    }`}
`;

export const Text = styled.h6<any>`
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  word-break: break-word;
  z-index: 1;

  ${props =>
    props.hovered && props.from !== 'fromMiddle'
      ? `${Character} { 
          opacity: 1;
          transform: translate3d(0, 0, 0) scale(1);
        }`
      : `&:hover ${Character} {
          opacity: 1;
          transform: translate3d(0, 0, 0) scale(1);
       }`}

  ${props =>
    !props.alwaysAnimated &&
    `
    ${createMediaQuery().mobile()} {
        &:hover ${Character}, ${Character} {
          opacity: 1;
          transform: none;
        }
      }`}
`;
