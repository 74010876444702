import styled from 'styled-components';
import { GridContainer } from '../shared/Grid/Grid.styles';
import createMediaQuery from '../../assets/styles/media';
import { Item } from '../shared/Grid/Grid.styles';

export const Image = styled.img<any>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  clip-path: circle(0% at 100% 100%);
  transition: all 400ms linear;
  border-radius: 50%;

  ${createMediaQuery().mobile()} {
    position: static;
    display: inline-block;
    width: calc(50% - 10px);
    margin-top: 24px;
    margin-right: 10px;
    clip-path: circle(${props => (props.active ? 100 : 0)}% at ${props => (props.active ? '50% 50%' : '100% 100%')});
    transition-delay: ${props => (props.active ? props.delay : 0)}s;
  }

  ${Item}:hover & {
    clip-path: circle(100% at 50% 50%);

    ${createMediaQuery().down(1024)} {
      clip-path: circle(0);
    }
    ${createMediaQuery().mobile()} {
      clip-path: unset;
    }
  }
`;

export const ItemContainer = styled.div<any>`
  width: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  transform: translateY(${props => (props.active ? 0 : 40)}px);
  transition: opacity 700ms ease, transform 400ms ease;
  transition-delay: ${props => (props.active ? props.delay : 0)}s;
  cursor: pointer;

  ${createMediaQuery().mobile()} {
    width: calc(50% - 10px);
    display: inline-block;
    margin-top: 41px;
    margin-left: 10px;
    padding-right: 24px;
    vertical-align: top;
  }

  h6,
  p {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms ease, transform 500ms ease;
  }

  ${Item}:hover & h6,
  ${Item}:hover & p {
    opacity: 0;
    transform: translateY(-30px);

    ${createMediaQuery().down(1024)} {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Wrapper = styled.div<any>`
  padding-top: 20px;
  padding-bottom: 20px;

  & ${GridContainer} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, minmax(calc((100vw - 100px) / 4), auto));

    ${createMediaQuery().up(1440)} {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(1, minmax(calc((100vw - 140px) / 6), auto));
    }

    ${createMediaQuery().mobile()} {
      grid-template-columns: 1fr;
    }
  }
`;
