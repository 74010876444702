import React from 'react';
import { gsap } from 'gsap';
import { StyledArrow, size } from './Arrow.styles';

const DURATION = 0.6;

interface ArrowProps {
  color?: string;
}

class Arrow extends React.Component<ArrowProps> {
  arrow!: HTMLDivElement;

  startAnimateArrow = () => {
    if (!this.arrow) return;

    const bubbles = this.arrow.children;

    gsap.to(bubbles, {
      y: -size,
      scale: 0,
      duration: DURATION,
      stagger: {
        amount: DURATION / 2,
        ease: 'Linear.easeNone'
      },
      onComplete: () => this.endAnimateArrow()
    });
  };

  endAnimateArrow = () => {
    if (!this.arrow) return;

    const bubbles = this.arrow.children;

    gsap.fromTo(
      bubbles,
      {
        y: size,
        scale: 0,
        duration: DURATION
      },
      {
        y: 0,
        scale: 1,
        stagger: {
          amount: DURATION / 2,
          ease: 'Linear.easeNone'
        }
      }
    );
  };

  componentDidMount() {
    this.arrow.parentElement && this.arrow.parentElement.addEventListener('mouseenter', this.startAnimateArrow);
  }

  componentWillUnmount() {
    this.arrow.parentElement && this.arrow.parentElement.removeEventListener('mouseenter', this.startAnimateArrow);
  }

  render() {
    const { color } = this.props;
    return (
      <StyledArrow color={color} ref={(el: HTMLDivElement) => (this.arrow = el)}>
        <span></span>
        <span></span>
        <span></span>
      </StyledArrow>
    );
  }
}

export default Arrow;
