import styled from "styled-components";
import { desktopLeftIndent } from "../../pages/Pages.styles";
import createMediaQuery from "../../../assets/styles/media";

export const fieldsHeight = 34;
export const fieldsHeightMobile = 24;

export const TextAreaContainer = styled.div<any>`
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: ${desktopLeftIndent}px;
  border-top: 2px solid #000;

  ${createMediaQuery().mobile()} {
    padding-left: 20px;
  }
`;

export const TextArea = styled.textarea<any>`
  display: block;
  width: 100%;
  height: ${fieldsHeight}px;
  margin: 32px 0;
  padding-right: 85px;
  font-family: ItalianPlateNo1Expanded-Bold, Arial;
  font-weight: bold;
  font-size: 28px;
  font-style: normal;
  font-stretch: expanded;
  line-height: ${fieldsHeight}px;
  letter-spacing: 1px;
  color: #000;
  overflow: hidden;

  &:invalid {
    box-shadow: 0 0 0 0;
  }

  ${createMediaQuery().down(1024)} {
    font-size: 24px;
  }

  ${createMediaQuery().mobile()} {
    padding-right: 60px;
    font-size: 20px;
    line-height: ${fieldsHeightMobile}px;
  }

  &::placeholder {
    opacity: 0.2;
  }
`;

export const ErrorMessage = styled.p<any>`
  display: none; // TODO Temp
  position: absolute;
  top: 100%;
  left: 0;
  margin: 20px 0 0 0;
`;
