import React from 'react';
import { NavLink } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import { gsap } from 'gsap';
import BottleImage from './BottleImage';
import { products } from './ProductsGridItems';
import { Block, Column, Container, Grid, Wrapper } from './Products.styles';
import StartElements from './StartElements';
import Arrow from '../../shared/Arrow/Arrow';
import GridItem from '../../shared/Grid/GridItem';
import { gap, indent } from '../../shared/Grid/Grid.styles';
import { IGrid, IGridItem, ILetters } from '../../shared/Grid/Grid.models';
import Image from '../../shared/Image/Image';
import { withUI, UI } from '../../../utils/UIContext';

const DURATION = 0.45;
const scrollOptions = { top: 0, left: 0, behavior: 'smooth' };

const GridContainer = (props: any) => {
  const { children, isMobile, forwardRef, ...rest } = props;

  return isMobile ? (
    <div ref={forwardRef}>{children}</div>
  ) : (
    <Scrollbars ref={forwardRef} universal {...rest}>
      {children}
    </Scrollbars>
  );
};

interface IProductsProps {
  ui: UI;
}

interface IProductsState {
  activeSide: string;
  colCount: number;
  colWidth: number;
  isAnimating: boolean;
}

class Products extends React.Component<IProductsProps, IProductsState> {
  state: IProductsState = {
    activeSide: '',
    colCount: 6,
    colWidth: 0,
    isAnimating: true
  };

  timeoutID: number | undefined;
  private textRef: HTMLParagraphElement[] = [];
  private linkRef: HTMLDivElement[] = [];
  private block!: HTMLDivElement;
  private headingRef: HTMLDivElement[] = [];
  private leftSide!: any;
  private rightSide!: any;

  textAnimation = (resize?: boolean) => {
    if (this.props.ui.isMobile) return;

    const { windowWidth } = this.props.ui;
    const { colWidth } = this.state;

    const x = (colWidth + indent * 2) / 2;

    const leftText = this.textRef[0];
    const rightText = this.textRef[1];

    const leftLink = this.linkRef[0];
    const rightLink = this.linkRef[1];

    const duration = resize ? 0 : DURATION;

    // IF SIDE LEFT ACTIVE
    if (this.state.activeSide === 'leftSide') {
      /** Left side animation */
      gsap.to(leftLink, { y: 0, opacity: 1, delay: DURATION / 3, duration: duration });
      gsap.to(leftText, { x: 0, y: 0, opacity: 1, duration: duration });

      /** Right side animation */
      gsap.to(rightLink, { opacity: 0, duration: duration });
      gsap.to(rightText, { x: 0, y: 20, opacity: 0, duration: duration });
    } else if (this.state.activeSide === 'rightSide') {
      /** Left side animation */
      gsap.to(leftLink, { opacity: 0, duration: duration });
      gsap.to(leftText, { opacity: 0, x: windowWidth <= 1440 ? x : 0, y: 20, duration: duration });

      /** Right side animation */
      gsap.to(rightLink, { opacity: 1, delay: DURATION / 3, duration: duration });
      gsap.to(rightText, { x: 0, y: 0, opacity: 1, duration: duration });
    } else {
      /** INIT */
      gsap.set([leftLink, rightLink], { opacity: 0 });
      gsap.set([leftText, rightText], { opacity: 0, y: 20 });
    }
  };

  shiftSides = (resize?: boolean) => {
    if (this.props.ui.isMobile) return;

    const { windowWidth } = this.props.ui;
    const { colWidth } = this.state;

    const shift = colWidth + indent;

    const leftSide = this.leftSide.view.parentElement;
    const leftHeading = this.headingRef[0].children[0];
    const leftText = this.headingRef[0].children[1];

    const rightSide = this.rightSide.view.parentElement;
    const rightGrid = this.rightSide.view.children[0];
    const rightHeading = this.headingRef[1].children[0];
    const rightText = this.headingRef[1].children[1];

    if (!leftSide || !rightSide) return;

    const duration = resize ? 0 : DURATION;
    const onComplete = () => this.setState({ isAnimating: false });
    this.setState({ isAnimating: true });

    // IF SIDE LEFT ACTIVE
    if (this.state.activeSide === 'leftSide') {
      /** Right side animations */
      gsap.to(rightSide, { x: -indent, duration: duration, onComplete }); // RIGHT SIDE ANIMATION X
      gsap.to(rightGrid, { x: windowWidth < 1440 ? -shift : 0, duration: duration }); // RIGHT SIDE GRID ANIMATION X
      gsap.to(rightText, { y: indent, opacity: 0, duration: duration }); // RIGHT SIDE TEXT ANIMATION X

      /** Left side animations */
      gsap.to([leftSide, leftHeading], { x: 0, duration: duration });
      gsap.to(leftText, { y: 0, opacity: 1, duration: duration }); // LEFT SIDE TEXT ANIMATION

      this.rightSide.view.scrollTo(scrollOptions);
    } // IF SIDE RIGHT ACTIVE
    else if (this.state.activeSide === 'rightSide') {
      /** Right side animations */
      gsap.to(rightSide, { x: -shift * 2 - indent * 2, duration: duration, onComplete });
      gsap.to([rightGrid, rightHeading], { x: 0, duration: duration });
      gsap.to(rightText, { y: 0, opacity: 1, duration: duration });

      /** Left side animations */
      gsap.to([leftSide, leftHeading], { x: windowWidth < 1440 ? -shift : 0, duration: duration });
      gsap.to(leftText, { y: indent, opacity: 0, duration: duration });

      this.leftSide.view.scrollTo(scrollOptions);
    }
  };

  initCols = () => {
    const { windowWidth } = this.props.ui;
    const colCount = windowWidth >= 1440 ? 6 : windowWidth >= 768 ? 4 : 2; // TODO IMPROVE
    const colWidth = (windowWidth - gap * (colCount - 1) - indent * 2) / colCount;
    this.setState({ colCount, colWidth });
  };

  filterProducts = (item: IGridItem) =>
    this.props.ui.isMobile ? item.whenVisible !== 'desktop' : item.whenVisible !== 'mobile';

  handleEvent = (side: string) => {
    if (this.props.ui.isMobile) return;
    this.setState({ activeSide: side });
  };

  componentDidMount() {
    this.initCols();

    this.timeoutID = setTimeout(() => {
      this.textAnimation();
    }, 0);
  }

  componentDidUpdate(prevProps: IProductsProps, prevState: IProductsState) {
    const { windowWidth } = this.props.ui;

    if (this.state.activeSide !== prevState.activeSide) {
      this.shiftSides();
      this.textAnimation();
    }

    if (windowWidth !== prevProps.ui.windowWidth) {
      this.shiftSides(true);
      this.textAnimation(true);
      this.initCols();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { isMobile, isTablet } = this.props.ui;
    const { activeSide, colWidth } = this.state;

    return (
      <Wrapper>
        <Container>
          {products.map((block: IGrid, index: number) => (
            <Block
              active={activeSide === block.side}
              key={index}
              ref={(ref: HTMLDivElement) => (index === 1 ? (this.block = ref) : null)}
              side={block.side}
              onMouseEnter={() => (!isTablet && block.side ? this.handleEvent(block.side) : null)}
              onClick={() => (isTablet && block.side ? this.handleEvent(block.side) : null)}
            >
              <GridContainer
                autoHide={true}
                isMobile={isMobile}
                style={{ minHeight: document.body.clientHeight }}
                forwardRef={(el: HTMLDivElement) => (block.side ? (this[block.side] = el) : null)}
              >
                {!isMobile &&
                  block.startElement &&
                  block.startElement.map((startElement: ILetters, startElementIndex: number) => (
                    <StartElements
                      activeSide={activeSide}
                      alignBottom={startElementIndex === 0}
                      colWidth={colWidth}
                      element={startElement}
                      key={startElementIndex}
                      side={block.side}
                    ></StartElements>
                  ))}

                <Grid active={activeSide === block.side} side={block.side}>
                  {block.items.map((item: IGridItem, key: number) => (
                    <GridItem
                      gridArea={`${item.type}` + key}
                      key={key}
                      position={!isMobile ? 'sticky' : 'relative'}
                      topIndent={!isMobile ? 121 : 0}
                    >
                      {item.column &&
                        item.column
                          .filter((item: IGridItem) => this.filterProducts(item))
                          .map((col: IGridItem, i: number) => (
                            <Column key={i} columnType={col.type} horizontal={isMobile && key === 2}>
                              {col.heading && (
                                <div ref={(el: HTMLDivElement) => (this.headingRef[index] = el)}>
                                  <h2>
                                    <b>{col.heading}</b>
                                  </h2>
                                  <p>{col.text}</p>
                                </div>
                              )}

                              {col.text && col.type === 'text' && (
                                <p ref={(el: HTMLHeadingElement) => (this.textRef[index] = el)}>{col.text}</p>
                              )}

                              {col.type === 'image' && (
                                <Image
                                  active={isMobile || activeSide === block.side}
                                  hasInnerIndent
                                  image={col.image}
                                  delay={col.delay}
                                  withInitialAnimation
                                ></Image>
                              )}

                              {col.type === 'bottle' && col.images && (
                                <BottleImage
                                  activeSide={activeSide}
                                  colWidth={colWidth}
                                  image={
                                    isMobile
                                      ? col.images[2]
                                      : isTablet || navigator.userAgent === 'ReactSnap'
                                      ? col.images[1]
                                      : col.images[0]
                                  }
                                  side={block.side}
                                ></BottleImage>
                              )}

                              {col.boldText && (
                                <div ref={(el: HTMLDivElement) => (this.linkRef[index] = el)}>
                                  <NavLink to={col.url ? col.url : '/products'}>
                                    <h6>
                                      <b>{col.boldText}</b>
                                    </h6>
                                    <p>{isMobile ? 'View cocktail recipes' : col.text}</p>
                                    <Arrow color={block.side === 'rightSide' ? '#000' : ''}></Arrow>
                                  </NavLink>
                                </div>
                              )}
                            </Column>
                          ))}
                    </GridItem>
                  ))}
                </Grid>
              </GridContainer>
            </Block>
          ))}
        </Container>
      </Wrapper>
    );
  }
}

export default withUI(Products);
