import React from 'react';
import { Subscription } from 'rxjs';
import { TextAreaContainer, TextArea, fieldsHeightMobile, fieldsHeight } from './Textarea.styles';
import { TextareaProps, TextareaState } from './Textarea.models';
import EnterTextIndicator from '../EnterTextIndicator/EnterTextIndicator';
import { withUI } from '../../../utils/UIContext';

class Textarea extends React.Component<TextareaProps, TextareaState> {
  state = {
    error: false,
    maxlength: this.props.maxlength || Infinity,
    pristine: true,
    remaining: this.props.value ? this.props.value.length : 0, // TODO Temp
    value: this.props.value
  };

  resizeSubscription!: Subscription;
  textarea = React.createRef<HTMLTextAreaElement>();
  timeoutID!: number;

  setTextAreaHeight = () => {
    if (!this.textarea.current) return;
    const height = this.props.ui.isMobile ? fieldsHeightMobile : fieldsHeight;
    this.textarea.current.style.height = `${height}px`;
    this.textarea.current.style.height = `${this.textarea.current.scrollHeight || height}px`;
  };

  handleChange = (event: any) => {
    event.persist();
    const node = this.textarea.current;
    const { value } = event.target;
    const { maxlength } = this.state;
    const limit = maxlength !== Infinity && maxlength <= value.length - 1;
    const remaining = limit ? maxlength : value.length;
    const error = node ? !node.checkValidity() : false;

    this.setState({ error, remaining, value: limit ? value.slice(0, maxlength - value.length) : value }, () => {
      this.props.onChange(event);
    });

    this.setTextAreaHeight();
  };

  handleBlur = () => {
    const node = this.textarea.current;
    const error = node ? !node.checkValidity() : false;
    this.setState({ error }, () => this.props.onBlur);
  };

  componentDidMount() {
    setTimeout(() => this.setTextAreaHeight(), 0);
    this.resizeSubscription = this.props.ui.resizeObservable.subscribe(this.setTextAreaHeight);
  }

  componentDidUpdate(prevProps: TextareaProps) {
    if (prevProps.value !== this.props.value && this.props.value === '') {
      this.setState({ value: '' }, () => (this.timeoutID = setTimeout(() => this.setTextAreaHeight(), 100)));
    }
  }

  componentWillUnmount() {
    this.resizeSubscription.unsubscribe();
    clearTimeout(this.timeoutID);
  }

  render() {
    const { form, maxlength, name, placeholder } = this.props;
    const { error, pristine, remaining, value } = this.state;

    return (
      <TextAreaContainer>
        <TextArea
          autocorrect="off"
          aria-invalid={error}
          aria-required={true}
          autoComplete="false"
          form={form}
          maxlength={maxlength}
          name={name}
          placeholder={placeholder}
          ref={this.textarea}
          required={true}
          value={value}
          onChange={(e: KeyboardEvent) => this.handleChange(e)}
          onFocus={() => this.setState({ pristine: false })}
          onBlur={this.handleBlur}
        >
          {placeholder}
        </TextArea>

        {maxlength && (
          <span>
            {remaining}/{maxlength}
          </span>
        )}

        <EnterTextIndicator active={value || !pristine} value={value} error={error}></EnterTextIndicator>
      </TextAreaContainer>
    );
  }
}

export default withUI(Textarea);
