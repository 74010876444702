import styled from 'styled-components';
import createMediaQuery from '../../assets/styles/media';

export const desktopRightIndent = 20;
export const desktopLeftIndent = 100;

export const Fieldset = styled.fieldset<any>`
  position: relative;
  display: block;
  ${props => props.padding && ` padding: 22px ${desktopRightIndent}px 18px ${desktopLeftIndent}px;`}

  ${createMediaQuery().mobile()} {
    ${props => props.padding && ` padding: 22px 20px 18px 20px;`}
  }

  svg {
    position: absolute;
    top: 0;
    right: 46px;
    bottom: 0;
    margin: auto 0;

    ${createMediaQuery().mobile()} {
      right: 20px;
    }
  }

  & > div:last-child {
    border-bottom: 0;
  }
`;
