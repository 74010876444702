import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Image, ItemContainer, Wrapper } from './WhereYouCanTry.styles';
import Button from '../shared/Button/Button';
import Grid from '../shared/Grid/Grid';
import GridItem from '../shared/Grid/GridItem';
import { IGridItem } from '../shared/Grid/Grid.models';
import { locations } from '../../assets/listOfLocations';
import { withUI, UI } from '../../utils/UIContext';

const animationDuration = 500;

export interface IContactsFormProps {
  scrollTo: Function;
  ui: UI;
}

export interface IContactsFormState {
  expanded: boolean;
  itemsIsActive: boolean;
}

class WhereYouCanTry extends React.Component<IContactsFormProps, IContactsFormState> {
  state: IContactsFormState = {
    expanded: false,
    itemsIsActive: false
  };
  button!: HTMLButtonElement;
  timeoutID!: number;

  handleExpandField = () => {
    clearTimeout(this.timeoutID);

    if (!this.state.expanded) {
      this.setState(
        { expanded: true },
        () => (this.timeoutID = setTimeout(() => this.setState({ itemsIsActive: true }), 50))
      );
    } else {
      this.setState({ itemsIsActive: false });
      this.timeoutID = setTimeout(() => this.setState({ expanded: false }), animationDuration - 300);
    }
  };

  onAnimationEnd = () => {
    if (!this.button || !this.state.expanded) return;
    const pageScrollTop = this.props.ui.isMobile ? window.pageYOffset : this.props.ui.pageScrollTop;
    this.props.scrollTo(this.button.getBoundingClientRect().top + pageScrollTop);
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { isMobile } = this.props.ui;
    const { expanded, itemsIsActive } = this.state;
    return (
      <>
        <Button
          active={expanded}
          ariaLabel="Where you can try"
          expand
          forwardRef={(b: HTMLButtonElement) => (this.button = b)}
          type="button"
          onClick={() => this.handleExpandField()}
        >
          <p>Where can you get a taste?</p>
        </Button>
        <AnimateHeight
          duration={animationDuration}
          height={expanded ? 'auto' : 0}
          onAnimationEnd={() => this.onAnimationEnd()}
        >
          <Wrapper>
            <Grid>
              {locations.map((item: IGridItem, index: number) => (
                <GridItem hasInnerIndent={!isMobile} key={index} position={'relative'}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.image && (
                      <picture>
                        <source data-srcset={item.image.webp} type="image/webp" />
                        <source data-srcset={`${item.image.src}, ${item.image.src2x} 2x`} type="image/jpeg" />

                        <Image
                          alt={item.image.alt}
                          active={itemsIsActive}
                          delay={index * 0.18}
                          data-src={item.image.src}
                          data-srcset={`${item.image.src}, ${item.image.src2x} 2x`}
                          className="lazyload"
                        ></Image>
                      </picture>
                    )}

                    <ItemContainer active={itemsIsActive} delay={isMobile ? index * 0.2 : item.time}>
                      <h6>
                        <b>{item.boldText}</b>
                      </h6>
                      <p>{item.text}</p>
                    </ItemContainer>
                  </a>
                </GridItem>
              ))}
            </Grid>
          </Wrapper>
        </AnimateHeight>
      </>
    );
  }
}

export default withUI(WhereYouCanTry);

