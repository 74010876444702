import React from 'react';
import styled from 'styled-components';
import { GridContainer } from '../../shared/Grid/Grid.styles';
import createMediaQuery from '../../../assets/styles/media';

const indent = 23;
const indentTablet = 17;
const indentMobile = 12;

export const GridWhereYouCanBuy = styled(GridContainer)`
  grid-template-areas: 'text gallery locations locations';
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: calc((100vw - 100px) / 4);

  ${createMediaQuery().up(1440)} {
    grid-template-rows: calc((100vw - 140px) / 6);
  }

  ${createMediaQuery().mobile()} {
    grid-template-areas:
      'locations gallery'
      'locations .';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, minmax(calc((100vw - 60px) / 2), auto));
  }
`;

export const Grid = styled(GridContainer)`
  grid-template-areas:
    'main_text0 main_text0 person1 person2'
    'main_text0 main_text0 person1 person2'
    'gallery3 gallery3 text4 text5'
    'gallery3 gallery3 text4 letter8'
    'text9 text9 image6 image7'
    'additional additional additional additional';
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, minmax(calc((100vw - 100px) / 4), auto));

  ${createMediaQuery().up(1440)} {
    grid-template-areas:
      'gallery3 gallery3 main_text0 main_text0 text4 text5'
      'gallery3 gallery3 main_text0 main_text0 text4 letter8'
      'gallery3 gallery3 person1 person2 text9 image6'
      'gallery3 gallery3 person1 person2 text9 image7'
      '. . additional additional additional additional';
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, minmax(calc((100vw - 140px) / 6), auto));
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: calc(20px + 20pt);
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: calc(20px + 20pt);
  }

  ${createMediaQuery().mobile()} {
    grid-template-areas:
      'main_text0 main_text0'
      'image1 letter2'
      'letter3 image4'
      'gallery5 gallery5'
      'gallery5 gallery5'
      'text6 letter10'
      'text6 text7'
      'image8 text11'
      'image9 text11'
      'additional additional';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, minmax(calc((100vw - 60px) / 2), auto));
    padding-bottom: 50px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: ${indent}px;

  ${createMediaQuery().down(1024)} {
    margin-top: ${indentTablet}px;
  }

  ${createMediaQuery().mobile()} {
    margin-top: ${indentMobile}px;
  }
`;

export const Image = styled(({ alt, forwardRef, ...rest }) => <img alt={alt} ref={forwardRef} {...rest} />)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: calc(100% - ${indent * 2}px);
  margin: auto;
  clip-path: circle(0% at 100% 100%);
  webkitclippath: circle(0% at 100% 100%);
  border-radius: 50%;

  ${createMediaQuery().down(1024)} {
    width: calc(100% - ${indentTablet * 2}px);
  }

  ${createMediaQuery().mobile()} {
    width: calc(100% - ${indentMobile * 2}px);
  }
`;

export const List = styled.ul`
  ${createMediaQuery().mobile()} {
    p + & {
      margin-top: 15px;
    }
  }

  & > li {
    display: inline-block;
    width: 50%;
    vertical-align: top;

    ${createMediaQuery().mobile()} {
      display: block;
      width: auto;
    }

    & + li {
      padding-left: 46px;

      ${createMediaQuery().mobile()} {
        margin-top: 15px;
        padding-left: 0;
      }
    }
  }

  & > li > ul > li {
    position: relative;

    & + li {
      margin-top: 20px;

      ${createMediaQuery().mobile()} {
        margin-top: 15px;
      }
    }
  }
`;

export const Dot = styled(({ active, ...rest }) => <div {...rest} />)`
  position: absolute;
  top: 4px;
  left: -46px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: ${props => (!props.active ? '#000' : 'transparent')};
  border: 5px #000 solid;
  border-radius: 50%;
  transform: scale(${props => (props.active ? 1 : 0)});
  transition: transform 700ms ease, background 250ms ease;
  transition-delay: ${props => (!props.active ? 100 : 0)}ms;

  ${createMediaQuery().mobile()} {
    left: -24px;
    width: 8px;
    height: 8px;
    border: 3px #000 solid;
  }
`;
