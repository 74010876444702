export const SW = [
  {
    src: '/images/bottles/SW/SW_00000.jpg',
    src2x: '/images/bottles/SW/SW@x2_00000.jpg',
    webp: '/images/bottles/SW/SW@x2_00000.webp'
  },
  {
    src: '/images/bottles/SW/SW_00001.jpg',
    src2x: '/images/bottles/SW/SW@x2_00001.jpg',
    webp: '/images/bottles/SW/SW@x2_00001.webp'
  },
  {
    src: '/images/bottles/SW/SW_00002.jpg',
    src2x: '/images/bottles/SW/SW@x2_00002.jpg',
    webp: '/images/bottles/SW/SW@x2_00002.webp'
  },
  {
    src: '/images/bottles/SW/SW_00003.jpg',
    src2x: '/images/bottles/SW/SW@x2_00003.jpg',
    webp: '/images/bottles/SW/SW@x2_00003.webp'
  },
  {
    src: '/images/bottles/SW/SW_00004.jpg',
    src2x: '/images/bottles/SW/SW@x2_00004.jpg',
    webp: '/images/bottles/SW/SW@x2_00004.webp'
  },
  {
    src: '/images/bottles/SW/SW_00005.jpg',
    src2x: '/images/bottles/SW/SW@x2_00005.jpg',
    webp: '/images/bottles/SW/SW@x2_00005.webp'
  },
  {
    src: '/images/bottles/SW/SW_00006.jpg',
    src2x: '/images/bottles/SW/SW@x2_00006.jpg',
    webp: '/images/bottles/SW/SW@x2_00006.webp'
  },
  {
    src: '/images/bottles/SW/SW_00007.jpg',
    src2x: '/images/bottles/SW/SW@x2_00007.jpg',
    webp: '/images/bottles/SW/SW@x2_00007.webp'
  },
  {
    src: '/images/bottles/SW/SW_00008.jpg',
    src2x: '/images/bottles/SW/SW@x2_00008.jpg',
    webp: '/images/bottles/SW/SW@x2_00008.webp'
  },
  {
    src: '/images/bottles/SW/SW_00009.jpg',
    src2x: '/images/bottles/SW/SW@x2_00009.jpg',
    webp: '/images/bottles/SW/SW@x2_00009.webp'
  },
  {
    src: '/images/bottles/SW/SW_00010.jpg',
    src2x: '/images/bottles/SW/SW@x2_00010.jpg',
    webp: '/images/bottles/SW/SW@x2_00010.webp'
  },
  {
    src: '/images/bottles/SW/SW_00011.jpg',
    src2x: '/images/bottles/SW/SW@x2_00011.jpg',
    webp: '/images/bottles/SW/SW@x2_00011.webp'
  },
  {
    src: '/images/bottles/SW/SW_00012.jpg',
    src2x: '/images/bottles/SW/SW@x2_00012.jpg',
    webp: '/images/bottles/SW/SW@x2_00012.webp'
  },
  {
    src: '/images/bottles/SW/SW_00013.jpg',
    src2x: '/images/bottles/SW/SW@x2_00013.jpg',
    webp: '/images/bottles/SW/SW@x2_00013.webp'
  },
  {
    src: '/images/bottles/SW/SW_00014.jpg',
    src2x: '/images/bottles/SW/SW@x2_00014.jpg',
    webp: '/images/bottles/SW/SW@x2_00014.webp'
  },
  {
    src: '/images/bottles/SW/SW_00015.jpg',
    src2x: '/images/bottles/SW/SW@x2_00015.jpg',
    webp: '/images/bottles/SW/SW@x2_00015.webp'
  },
  {
    src: '/images/bottles/SW/SW_00016.jpg',
    src2x: '/images/bottles/SW/SW@x2_00016.jpg',
    webp: '/images/bottles/SW/SW@x2_00016.webp'
  },
  {
    src: '/images/bottles/SW/SW_00017.jpg',
    src2x: '/images/bottles/SW/SW@x2_00017.jpg',
    webp: '/images/bottles/SW/SW@x2_00017.webp'
  },
  {
    src: '/images/bottles/SW/SW_00018.jpg',
    src2x: '/images/bottles/SW/SW@x2_00018.jpg',
    webp: '/images/bottles/SW/SW@x2_00018.webp'
  },
  {
    src: '/images/bottles/SW/SW_00019.jpg',
    src2x: '/images/bottles/SW/SW@x2_00019.jpg',
    webp: '/images/bottles/SW/SW@x2_00019.webp'
  },
  {
    src: '/images/bottles/SW/SW_00020.jpg',
    src2x: '/images/bottles/SW/SW@x2_00020.jpg',
    webp: '/images/bottles/SW/SW@x2_00020.webp'
  },
  {
    src: '/images/bottles/SW/SW_00021.jpg',
    src2x: '/images/bottles/SW/SW@x2_00021.jpg',
    webp: '/images/bottles/SW/SW@x2_00021.webp'
  },
  {
    src: '/images/bottles/SW/SW_00022.jpg',
    src2x: '/images/bottles/SW/SW@x2_00022.jpg',
    webp: '/images/bottles/SW/SW@x2_00022.webp'
  },
  {
    src: '/images/bottles/SW/SW_00023.jpg',
    src2x: '/images/bottles/SW/SW@x2_00023.jpg',
    webp: '/images/bottles/SW/SW@x2_00023.webp'
  },
  {
    src: '/images/bottles/SW/SW_00024.jpg',
    src2x: '/images/bottles/SW/SW@x2_00024.jpg',
    webp: '/images/bottles/SW/SW@x2_00024.webp'
  },
  {
    src: '/images/bottles/SW/SW_00025.jpg',
    src2x: '/images/bottles/SW/SW@x2_00025.jpg',
    webp: '/images/bottles/SW/SW@x2_00025.webp'
  },
  {
    src: '/images/bottles/SW/SW_00026.jpg',
    src2x: '/images/bottles/SW/SW@x2_00026.jpg',
    webp: '/images/bottles/SW/SW@x2_00026.webp'
  },
  {
    src: '/images/bottles/SW/SW_00027.jpg',
    src2x: '/images/bottles/SW/SW@x2_00027.jpg',
    webp: '/images/bottles/SW/SW@x2_00027.webp'
  },
  {
    src: '/images/bottles/SW/SW_00028.jpg',
    src2x: '/images/bottles/SW/SW@x2_00028.jpg',
    webp: '/images/bottles/SW/SW@x2_00028.webp'
  },
  {
    src: '/images/bottles/SW/SW_00029.jpg',
    src2x: '/images/bottles/SW/SW@x2_00029.jpg',
    webp: '/images/bottles/SW/SW@x2_00029.webp'
  },
  {
    src: '/images/bottles/SW/SW_00000.jpg',
    src2x: '/images/bottles/SW/SW@x2_00000.jpg',
    webp: '/images/bottles/SW/SW@x2_00000.webp'
  }
];

export const SW_small = [
  {
    src: '/images/bottles/SW_small/SW_small_00000.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00000.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00000.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00001.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00001.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00001.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00002.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00002.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00002.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00003.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00003.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00003.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00004.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00004.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00004.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00005.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00005.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00005.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00006.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00006.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00006.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00007.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00007.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00007.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00008.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00008.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00008.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00009.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00009.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00009.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00010.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00010.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00010.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00011.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00011.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00011.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00012.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00012.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00012.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00013.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00013.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00013.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00014.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00014.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00014.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00015.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00015.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00015.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00016.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00016.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00016.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00017.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00017.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00017.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00018.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00018.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00018.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00019.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00019.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00019.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00020.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00020.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00020.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00021.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00021.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00021.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00022.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00022.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00022.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00023.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00023.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00023.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00024.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00024.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00024.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00025.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00025.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00025.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00026.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00026.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00026.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00027.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00027.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00027.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00028.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00028.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00028.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00029.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00029.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00029.webp'
  },
  {
    src: '/images/bottles/SW_small/SW_small_00000.jpg',
    src2x: '/images/bottles/SW_small/SW_small@x2_00000.jpg',
    webp: '/images/bottles/SW_small/SW_small@x2_00000.webp'
  }
];

export const TW = [
  { src: '/images/bottles/TW/TW_00000.jpg', src2x: '/images/bottles/TW/TW@x2_00000.jpg', webp: '/images/bottles/TW/TW@x2_00000.webp' },
  { src: '/images/bottles/TW/TW_00001.jpg', src2x: '/images/bottles/TW/TW@x2_00001.jpg', webp: '/images/bottles/TW/TW@x2_00001.webp' },
  { src: '/images/bottles/TW/TW_00002.jpg', src2x: '/images/bottles/TW/TW@x2_00002.jpg', webp: '/images/bottles/TW/TW@x2_00002.webp' },
  { src: '/images/bottles/TW/TW_00003.jpg', src2x: '/images/bottles/TW/TW@x2_00003.jpg', webp: '/images/bottles/TW/TW@x2_00003.webp' },
  { src: '/images/bottles/TW/TW_00004.jpg', src2x: '/images/bottles/TW/TW@x2_00004.jpg', webp: '/images/bottles/TW/TW@x2_00004.webp' },
  { src: '/images/bottles/TW/TW_00005.jpg', src2x: '/images/bottles/TW/TW@x2_00005.jpg', webp: '/images/bottles/TW/TW@x2_00005.webp' },
  { src: '/images/bottles/TW/TW_00006.jpg', src2x: '/images/bottles/TW/TW@x2_00006.jpg', webp: '/images/bottles/TW/TW@x2_00006.webp' },
  { src: '/images/bottles/TW/TW_00007.jpg', src2x: '/images/bottles/TW/TW@x2_00007.jpg', webp: '/images/bottles/TW/TW@x2_00007.webp' },
  { src: '/images/bottles/TW/TW_00008.jpg', src2x: '/images/bottles/TW/TW@x2_00008.jpg', webp: '/images/bottles/TW/TW@x2_00008.webp' },
  { src: '/images/bottles/TW/TW_00009.jpg', src2x: '/images/bottles/TW/TW@x2_00009.jpg', webp: '/images/bottles/TW/TW@x2_00009.webp' },
  { src: '/images/bottles/TW/TW_00010.jpg', src2x: '/images/bottles/TW/TW@x2_00010.jpg', webp: '/images/bottles/TW/TW@x2_00010.webp' },
  { src: '/images/bottles/TW/TW_00011.jpg', src2x: '/images/bottles/TW/TW@x2_00011.jpg', webp: '/images/bottles/TW/TW@x2_00011.webp' },
  { src: '/images/bottles/TW/TW_00012.jpg', src2x: '/images/bottles/TW/TW@x2_00012.jpg', webp: '/images/bottles/TW/TW@x2_00012.webp' },
  { src: '/images/bottles/TW/TW_00013.jpg', src2x: '/images/bottles/TW/TW@x2_00013.jpg', webp: '/images/bottles/TW/TW@x2_00013.webp' },
  { src: '/images/bottles/TW/TW_00014.jpg', src2x: '/images/bottles/TW/TW@x2_00014.jpg', webp: '/images/bottles/TW/TW@x2_00014.webp' },
  { src: '/images/bottles/TW/TW_00015.jpg', src2x: '/images/bottles/TW/TW@x2_00015.jpg', webp: '/images/bottles/TW/TW@x2_00015.webp' },
  { src: '/images/bottles/TW/TW_00016.jpg', src2x: '/images/bottles/TW/TW@x2_00016.jpg', webp: '/images/bottles/TW/TW@x2_00016.webp' },
  { src: '/images/bottles/TW/TW_00017.jpg', src2x: '/images/bottles/TW/TW@x2_00017.jpg', webp: '/images/bottles/TW/TW@x2_00017.webp' },
  { src: '/images/bottles/TW/TW_00018.jpg', src2x: '/images/bottles/TW/TW@x2_00018.jpg', webp: '/images/bottles/TW/TW@x2_00018.webp' },
  { src: '/images/bottles/TW/TW_00019.jpg', src2x: '/images/bottles/TW/TW@x2_00019.jpg', webp: '/images/bottles/TW/TW@x2_00019.webp' },
  { src: '/images/bottles/TW/TW_00020.jpg', src2x: '/images/bottles/TW/TW@x2_00020.jpg', webp: '/images/bottles/TW/TW@x2_00020.webp' },
  { src: '/images/bottles/TW/TW_00021.jpg', src2x: '/images/bottles/TW/TW@x2_00021.jpg', webp: '/images/bottles/TW/TW@x2_00021.webp' },
  { src: '/images/bottles/TW/TW_00022.jpg', src2x: '/images/bottles/TW/TW@x2_00022.jpg', webp: '/images/bottles/TW/TW@x2_00022.webp' },
  { src: '/images/bottles/TW/TW_00023.jpg', src2x: '/images/bottles/TW/TW@x2_00023.jpg', webp: '/images/bottles/TW/TW@x2_00023.webp' },
  { src: '/images/bottles/TW/TW_00024.jpg', src2x: '/images/bottles/TW/TW@x2_00024.jpg', webp: '/images/bottles/TW/TW@x2_00024.webp' },
  { src: '/images/bottles/TW/TW_00025.jpg', src2x: '/images/bottles/TW/TW@x2_00025.jpg', webp: '/images/bottles/TW/TW@x2_00025.webp' },
  { src: '/images/bottles/TW/TW_00026.jpg', src2x: '/images/bottles/TW/TW@x2_00026.jpg', webp: '/images/bottles/TW/TW@x2_00026.webp' },
  { src: '/images/bottles/TW/TW_00027.jpg', src2x: '/images/bottles/TW/TW@x2_00027.jpg', webp: '/images/bottles/TW/TW@x2_00027.webp' },
  { src: '/images/bottles/TW/TW_00028.jpg', src2x: '/images/bottles/TW/TW@x2_00028.jpg', webp: '/images/bottles/TW/TW@x2_00028.webp' },
  { src: '/images/bottles/TW/TW_00029.jpg', src2x: '/images/bottles/TW/TW@x2_00029.jpg', webp: '/images/bottles/TW/TW@x2_00029.webp' },
  { src: '/images/bottles/TW/TW_00000.jpg', src2x: '/images/bottles/TW/TW@x2_00000.jpg', webp: '/images/bottles/TW/TW@x2_00000.webp' }
];

export const TW_small = [
  { src: '/images/bottles/TW_small/TW_small_00000.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00000.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00000.webp' },
  { src: '/images/bottles/TW_small/TW_small_00001.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00001.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00001.webp' },
  { src: '/images/bottles/TW_small/TW_small_00002.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00002.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00002.webp' },
  { src: '/images/bottles/TW_small/TW_small_00003.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00003.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00003.webp' },
  { src: '/images/bottles/TW_small/TW_small_00004.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00004.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00004.webp' },
  { src: '/images/bottles/TW_small/TW_small_00005.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00005.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00005.webp' },
  { src: '/images/bottles/TW_small/TW_small_00006.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00006.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00006.webp' },
  { src: '/images/bottles/TW_small/TW_small_00007.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00007.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00007.webp' },
  { src: '/images/bottles/TW_small/TW_small_00008.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00008.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00008.webp' },
  { src: '/images/bottles/TW_small/TW_small_00009.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00009.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00009.webp' },
  { src: '/images/bottles/TW_small/TW_small_00010.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00010.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00010.webp' },
  { src: '/images/bottles/TW_small/TW_small_00011.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00011.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00011.webp' },
  { src: '/images/bottles/TW_small/TW_small_00012.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00012.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00012.webp' },
  { src: '/images/bottles/TW_small/TW_small_00013.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00013.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00013.webp' },
  { src: '/images/bottles/TW_small/TW_small_00014.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00014.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00014.webp' },
  { src: '/images/bottles/TW_small/TW_small_00015.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00015.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00015.webp' },
  { src: '/images/bottles/TW_small/TW_small_00016.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00016.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00016.webp' },
  { src: '/images/bottles/TW_small/TW_small_00017.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00017.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00017.webp' },
  { src: '/images/bottles/TW_small/TW_small_00018.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00018.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00018.webp' },
  { src: '/images/bottles/TW_small/TW_small_00019.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00019.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00019.webp' },
  { src: '/images/bottles/TW_small/TW_small_00020.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00020.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00020.webp' },
  { src: '/images/bottles/TW_small/TW_small_00021.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00021.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00021.webp' },
  { src: '/images/bottles/TW_small/TW_small_00022.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00022.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00022.webp' },
  { src: '/images/bottles/TW_small/TW_small_00023.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00023.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00023.webp' },
  { src: '/images/bottles/TW_small/TW_small_00024.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00024.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00024.webp' },
  { src: '/images/bottles/TW_small/TW_small_00025.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00025.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00025.webp' },
  { src: '/images/bottles/TW_small/TW_small_00026.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00026.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00026.webp' },
  { src: '/images/bottles/TW_small/TW_small_00027.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00027.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00027.webp' },
  { src: '/images/bottles/TW_small/TW_small_00028.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00028.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00028.webp' },
  { src: '/images/bottles/TW_small/TW_small_00029.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00029.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00029.webp' },
  { src: '/images/bottles/TW_small/TW_small_00000.jpg', src2x: '/images/bottles/TW_small/TW_small@x2_00000.jpg', webp: '/images/bottles/TW_small/TW_small@x2_00000.webp' }
];
