import styled from "styled-components";
import { desktopLeftIndent } from "../../pages/Pages.styles";
import createMediaQuery from "../../../assets/styles/media";

export const fieldsHeight = 34;

export const PhoneInputContainer = styled.div<any>`
  position: relative;
  display: block;
  width: 100%;
  border-top: solid 2px #000;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -${desktopLeftIndent}px;
    display: block;
    width: ${desktopLeftIndent}px;
    height: 2px;
    background-color: #000;
  }

  & > div > input[type="tel"] {
    width: 100%;
    min-height: 98px;
    padding: 32px 85px 32px 0;

    // FONT STYLES
    font-family: ItalianPlateNo1Expanded-Bold, Arial;
    font-weight: bold;
    font-size: 28px;
    font-style: normal;
    font-stretch: expanded;
    line-height: ${fieldsHeight}px;
    letter-spacing: 1px;
    color: #000;
    border: 0;

    &:invalid {
      box-shadow: 0 0 0 0;
    }

    ${createMediaQuery().down(1024)} {
      font-size: 24px;
    }

    ${createMediaQuery().mobile()} {
      padding-right: 60px;
      padding-left: 0;
      font-size: 20px;
      line-height: 1.2;
    }
  }

  & > .react-tel-input {
    padding-left: ${desktopLeftIndent}px;

    ${createMediaQuery().mobile()} {
      padding-left: 68px;
    }
  }

  & > .react-tel-input input[type="tel"].invalid-number,
  & > .react-tel-input input[type="tel"].invalid-number:focus {
    background-color: transparent;
    border: 0;
  }

  & > .react-tel-input .flag-dropdown {
    left: 0;
    margin-left: 40px;
    background-color: transparent;
    border: 0;

    ${createMediaQuery().mobile()} {
      margin-left: 20px;
    }
  }

  & > .react-tel-input .flag-dropdown .selected-flag {
    padding: 0;
    z-index: 1;
  }

  & > .react-tel-input .flag-dropdown .selected-flag .arrow {
    display: none;
  }
`;
