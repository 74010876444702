import React from 'react';
import { Indicator, Wrapper, indicatorTransition } from './EnterTextIndicator.styles';
import IconSmile from '../../icons/IconSmile';

const BUBBLES_DELAY = [0, 0.15, 0.5, 0.65];

interface EnterTextIndicatorProps {
  active?: any;
  value?: string | number;
  error?: boolean;
  pristine?: boolean;
}

interface EnterTextIndicatorState {
  entering?: boolean;
}

class EnterTextIndicator extends React.Component<EnterTextIndicatorProps, EnterTextIndicatorState> {
  state: EnterTextIndicatorState = {
    entering: false
  };

  componentDidUpdate(prevProps: EnterTextIndicatorProps) {
    if (this.props.value !== prevProps.value && !this.state.entering) {
      this.setState({ entering: true }, () => setTimeout(() => this.setState({ entering: false }), indicatorTransition));
    }
  }

  render() {
    const { error, pristine } = this.props;
    const { entering } = this.state;

    return (
      <Wrapper active={entering}>
        <Indicator active={entering} delay={BUBBLES_DELAY}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </Indicator>

        <IconSmile active={entering || !pristine} error={error}></IconSmile>
      </Wrapper>
    );
  }
}

export default EnterTextIndicator;
