import { IGridItem } from '../components/shared/Grid/Grid.models';

export const locations: IGridItem[] = [
  {
    type: 'text',
    boldText: 'Elixir',
    image: {
      alt: 'Elixir',
      src: '/images/logotypes/Elixir.jpg',
      src2x: '/images/logotypes/Elixir@2x.jpg',
      webp: '/images/logotypes/Elixir@2x.webp'
    },
    text: '9 Mee Lun Street, Sheung Wan',
    time: 0,
    url: 'https://www.instagram.com/elixir.coffee'
  },
  {
    type: 'text',
    boldText: 'Hooman',
    image: {
      alt: 'Hooman',
      src: '/images/logotypes/Hooman.jpg',
      src2x: '/images/logotypes/Hooman@2x.jpg',
      webp: '/images/logotypes/Hooman@2x.webp'
    },
    text: 'G/F, 7 Museum Drive, West Kowloon',
    time: 0.15,
    url: 'https://www.instagram.com/hooman.cafe'
  },
  {
    type: 'text',
    boldText: 'Tramline Liquor Co',
    image: {
      alt: 'Tramline Liquor Co',
      src: '/images/logotypes/Tramline_Liquor_Co.jpg',
      src2x: '/images/logotypes/Tramline_Liquor_Co@2x.jpg',
      webp: '/images/logotypes/Tramline_Liquor_Co@2x.webp'
    },
    text: '78 Catchick St, Sai Wan',
    time: 0.6,
    url: 'https://www.facebook.com/thetramlineliquorco'
  },
  {
    type: 'text',
    boldText: 'Bottle Shop',
    image: {
      alt: 'Bottle Shop',
      src: '/images/logotypes/Bottle_Shop.jpg',
      src2x: '/images/logotypes/Bottle_Shop@2x.jpg',
      webp: '/images/logotypes/Bottle_Shop@2x.webp'
    },
    text: 'G/F 114, Man Nin St, New Territories, Sai Kung',
    time: 0.433,
    url: 'https://www.facebook.com/thebottleshop.hk'
  },
  {
    type: 'text',
    boldText: 'Owl Cafe',
    image: {
      alt: 'Owl Cafe',
      src: '/images/logotypes/Owl_Cafe.jpg',
      src2x: '/images/logotypes/Owl_Cafe@2x.jpg',
      webp: '/images/logotypes/Owl_Cafe@2x.webp'
    },
    text: '32 Mody Rd, Tsim Sha Tsui',
    time: 0.31,
    url: 'https://www.instagram.com/owls_coffee'
  },
  {
    type: 'text',
    boldText: 'HK Liquor Store',
    image: {
      alt: 'HK Liquor Store',
      src: '/images/logotypes/HK_Liquor_Store.jpg',
      src2x: '/images/logotypes/HK_Liquor_Store@2x.jpg',
      webp: '/images/logotypes/HK_Liquor_Store@2x.webp'
    },
    text: '5 stores in Hong Kong',
    time: 0.72,
    url: 'https://www.facebook.com/HKLiquorStore'
  }
];
