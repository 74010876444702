import { IGridItem } from '../../shared/Grid/Grid.models';

export const gridItems: IGridItem[] = [
  {
    heading: 'We\u00A0are\u00A0modern craftsmen who cherish the\u00A0beauty\u00A0heritage ingredients . Reinvented in a bottle.',
    text: 'We are a team of like-minded people who care about quality and traditions, we love our business and are proud of our product.',
    type: 'main_text'
  },
  {
    type: 'person',
    heading: 'Daniella\nIeong',
    letters: {
      src: [
        ['/letters/1A_D.json', '/letters/4A_D.json'],
        ['/letters/3A_I.json', '/letters/6A_I.json']
      ]
    },
    image: {
      src: '/images/cofounders/daniella_ieong@2x.jpg'
    },
    time: 2300,
    whenVisible: 'desktop'
  },
  {
    type: 'image',
    image: {
      alt: 'Daniella\nIeong',
      src: '/images/cofounders/daniella_ieong_circle.png',
      src2x: '/images/cofounders/daniella_ieong_circle@2x.png',
      webp: '/images/cofounders/daniella_ieong_circle@2x.webp'
    },
    whenVisible: 'mobile'
  },
  {
    type: 'letter',
    letters: { src: ['/letters/1A_D.json', '/letters/4A_D.json'] },
    time: 5300,
    whenVisible: 'mobile'
  },
  {
    type: 'person',
    heading: 'Victor\nLi',
    letters: {
      src: [
        ['/letters/1A_V.json', '/letters/2A_V.json'],
        ['/letters/3A_L.json', '/letters/1A_L.json']
      ]
    },
    image: {
      src: '/images/cofounders/victor_li@2x.jpg'
    },
    time: 5300,
    whenVisible: 'desktop'
  },
  {
    type: 'letter',
    letters: { src: ['/letters/1A_V.json', '/letters/2A_V.json'] },
    time: 5300,
    whenVisible: 'mobile'
  },
  {
    type: 'image',
    image: {
      alt: 'Victor\nLi',
      src: '/images/cofounders/victor_li_circle.jpg',
      src2x: '/images/cofounders/victor_li_circle@2x.png',
      webp: '/images/cofounders/victor_li_circle@2x.webp'
    },
    whenVisible: 'mobile'
  },
  {
    type: 'gallery',
    heading: 'The\nproduction',
    gallery: [
      {
        src: '/images/about/About_1.jpg',
        src2x: '/images/about/About_1@2x.jpg',
        webp: '/images/about/About_1@2x.webp',
        textColor: '#fff'
      },
      {
        src: '/images/about/About_2.jpg',
        src2x: '/images/about/About_2@2x.jpg',
        webp: '/images/about/About_2@2x.webp',
        textColor: '#fff'
      },
      {
        src: '/images/about/About_3.jpg',
        src2x: '/images/about/About_3@2x.jpg',
        webp: '/images/about/About_3@2x.webp',
        textColor: '#161616'
      },
      {
        src: '/images/about/About_4.jpg',
        src2x: '/images/about/About_4@2x.jpg',
        webp: '/images/about/About_4@2x.webp',
        textColor: '#161616'
      },
      {
        src: '/images/about/About_5.jpg',
        src2x: '/images/about/About_5@2x.jpg',
        webp: '/images/about/About_5@2x.webp',
        textColor: '#161616'
      },
      {
        src: '/images/about/About_6.jpg',
        src2x: '/images/about/About_6@2x.jpg',
        webp: '/images/about/About_6@2x.webp',
        textColor: '#161616'
      }
    ]
  },
  {
    heading: 'The Carbonation Limited',
    text:
      'Every bottle of our Carbonation Mixers is crafted at our site in\nSan Po Kong.\n\nOur production method traces back to the roots of carbonated drinks, to which all ingredients are prepared and processed by hand.',
    type: 'text'
  },
  {
    text: '701\nMax Trade Centre,\n23 Luk Hop Street,\n\nKowloon, \nHong Kong',
    type: 'text'
  },
  {
    type: 'image',
    ingredients: [
      {
        alt: 'Lemons',
        src: '/images/ingredients/organic-lemons.jpg',
        src2x: '/images/ingredients/organic-lemons@2x.jpg',
        webp: '/images/ingredients/organic-lemons@2x.webp'
      },
      {
        alt: 'Cane sugar',
        src: '/images/ingredients/cane-sugar.jpg',
        src2x: '/images/ingredients/cane-sugar@2x.jpg',
        webp: '/images/ingredients/cane-sugar@2x.webp'
      },
      {
        alt: 'Pink salt',
        src: '/images/ingredients/pink-salt.jpg',
        src2x: '/images/ingredients/pink-salt@2x.jpg',
        webp: '/images/ingredients/pink-salt@2x.webp'
      }
    ],
    time: 7000
  },
  {
    type: 'image',
    ingredients: [
      {
        alt: 'Tangerine\nPeels',
        src: '/images/ingredients/tangerine-peels.jpg',
        src2x: '/images/ingredients/tangerine-peels@2x.jpg',
        webp: '/images/ingredients/tangerine-peels@2x.webp'
      },
      {
        alt: 'Cardamon &\nAllspice',
        src: '/images/ingredients/cardamon-and-allspice.jpg',
        src2x: '/images/ingredients/cardamon-and-allspice@2x.jpg',
        webp: '/images/ingredients/cardamon-and-allspice@2x.webp'
      },
      {
        alt: 'Сinamon',
        src: '/images/ingredients/cinamon.jpg',
        src2x: '/images/ingredients/cinamon@2x.jpg',
        webp: '/images/ingredients/cinamon@2x.webp'
      },
      {
        alt: 'Lychee wood\nscent',
        src: '/images/ingredients/lychee-wood-scent.jpg',
        src2x: '/images/ingredients/lychee-wood-scent@2x.jpg',
        webp: '/images/ingredients/lychee-wood-scent@2x.webp'
      }
    ],
    time: 9333
  },
  {
    letters: { src: ['/letters/4A_P.json', '/letters/1A_P.json'] },
    type: 'letter',
    time: 6000
  },
  {
    text:
      'The carbonation levels are specifically chosen to best compliment the flavour profiles of our unique range of Tonic Water and Smoked Water. Truly small batch. Distinctively different.',
    type: 'text'
  }
];
