import React from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

interface ILogotypeProps {
  animate?: boolean;
  changeColor: boolean;
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined;
}

class Logotype extends React.Component<ILogotypeProps> {
  private logotype!: any | undefined;

  animateLogotype = () => {
    if (!this.logotype) return;

    const onComplete = () => this.logotype && gsap.set(this.logotype, { rotate: 0 });
    gsap.to(this.logotype, { rotate: 360, duration: 1.2, onComplete });
  };

  componentDidUpdate(prevProps: ILogotypeProps) {
    if (this.props.animate !== prevProps.animate && this.props.animate) {
      this.animateLogotype();
    }
  }

  render() {
    return (
      <Link to={'/'} onClick={this.props.onClick}>
        <svg
          width="69"
          height="69"
          viewBox="0 0 69 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={(el: any) => (this.logotype = el)}
        >
          <g id="LOGO">
            <path
              id="Fill 1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.705 13.1602C22.6263 13.1599 23.5721 12.9231 24.4571 12.4267C26.4549 11.3059 27.5994 9.14593 27.3048 7.05225C27.2735 6.8233 27.1769 6.72983 27.1011 6.69193C27.034 6.65814 26.92 6.63635 26.7611 6.72572L24.7451 7.85656C24.4932 7.99804 24.3622 8.14519 24.3199 8.33656C24.1754 9.08656 23.7598 9.68814 23.1503 10.0301C21.9529 10.7015 20.5066 10.2247 19.7856 8.92014C19.0651 7.61593 19.4245 6.1254 20.622 5.45372C21.2559 5.09783 21.9548 5.03151 22.5884 5.26614C22.8059 5.35267 22.9247 5.37162 23.2271 5.20204L25.2431 4.07088C25.3938 3.98625 25.4759 3.8874 25.4872 3.77656C25.4979 3.66856 25.4405 3.55898 25.3139 3.45004C23.6875 2.12498 21.2688 1.97814 19.2961 3.08498C16.6198 4.58625 15.6586 7.81709 17.1077 10.44C18.0748 12.1905 19.8404 13.1602 21.705 13.1602Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M35.5646 6.52453L33.5421 6.52295C33.5248 6.52295 33.517 6.51979 33.516 6.51979C33.5157 6.51979 33.5157 6.51979 33.5157 6.51979C33.5142 6.51505 33.5129 6.49516 33.5286 6.45884L34.5762 3.78189L35.5756 6.45347C35.5897 6.49674 35.5844 6.51916 35.5646 6.52453ZM39.653 10.4397C39.767 10.4397 39.8614 10.3936 39.9184 10.3102C39.9814 10.218 39.9892 10.0917 39.94 9.96253L36.1124 0.315789C36.0867 0.238105 36.0084 0.00157895 35.7489 0.00157895L33.5913 0H33.5909C33.485 0 33.3365 0.0508421 33.2296 0.293053L29.2528 9.9221C29.1926 10.0598 29.1945 10.1893 29.2578 10.2872C29.3177 10.3797 29.4255 10.4324 29.5533 10.4324L31.726 10.434C31.8887 10.434 32.0106 10.3352 32.0886 10.1391L32.6135 8.76632C32.6483 8.67916 32.7028 8.66811 32.7461 8.66811L36.2675 8.67063C36.385 8.67063 36.412 8.69589 36.4443 8.78274L36.9504 10.1353C37.0052 10.3276 37.1324 10.4381 37.3001 10.4381L39.6527 10.4397C39.6527 10.4397 39.6527 10.4397 39.653 10.4397Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.3113 7.64088L48.169 6.15225C48.2069 6.08688 48.2386 6.0512 48.2662 6.04393C48.2931 6.03636 48.3386 6.0512 48.4034 6.08941L49.9067 6.9692C50.4297 7.2752 50.5636 7.76783 50.2646 8.28636C50.1142 8.5472 49.8926 8.74015 49.6407 8.82983C49.3802 8.92204 49.1161 8.89709 48.8769 8.75688L47.3737 7.87709C47.2445 7.8013 47.2364 7.77067 47.3113 7.64088ZM42.0568 11.6299L43.9484 12.7374C44.1189 12.8363 44.3536 12.7737 44.4517 12.6029L45.9418 10.0172C46.0192 9.88299 46.08 9.86372 46.2041 9.93604L46.8782 10.3305C46.9597 10.3781 47.0267 10.4385 47.0173 10.6917L46.8616 14.1787C46.8506 14.3543 46.9077 14.4689 47.0456 14.5497L49.1837 15.8006C49.2433 15.8356 49.305 15.853 49.3646 15.853C49.4175 15.853 49.4686 15.8391 49.5153 15.8116C49.6225 15.7475 49.6842 15.6206 49.6805 15.475L49.7087 11.4272C51.028 11.5387 52.1261 10.9526 52.8058 9.77341C53.9111 7.8553 53.2875 6.13457 51.0026 4.7972L47.2577 2.60562C47.0922 2.5093 46.8682 2.57404 46.7673 2.74772L41.9365 11.131C41.887 11.2166 41.8732 11.3214 41.8989 11.4184C41.923 11.5096 41.9791 11.5848 42.0568 11.6299Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M54.692 18.3913L55.9793 17.5329C56.0219 17.5045 56.052 17.4897 56.0777 17.4897C56.1119 17.4897 56.1382 17.5162 56.1755 17.5731L57.3081 19.2982C57.6575 19.8309 57.4607 20.3631 57.0856 20.6135C56.7076 20.8655 56.1182 20.8216 55.785 20.3138L54.6525 18.5886C54.5867 18.4885 54.5929 18.4573 54.692 18.3913ZM58.0288 16.1668L59.141 15.4254C59.1836 15.3966 59.2178 15.3821 59.2416 15.3821C59.2451 15.3821 59.2485 15.3824 59.2513 15.3834C59.2755 15.3881 59.3043 15.4159 59.3369 15.4655L60.4776 17.2033C60.7753 17.6561 60.7079 18.09 60.2974 18.3635C59.9646 18.5858 59.4641 18.6111 59.1545 18.1399L57.9887 16.3645C57.9229 16.2644 57.9295 16.2328 58.0288 16.1668ZM51.1395 17.3858C51.116 17.4827 51.132 17.5803 51.1843 17.6602L54.0116 21.9666C54.7302 23.0612 55.8164 23.6564 56.9004 23.6564C57.4701 23.6564 58.0385 23.4919 58.5528 23.1493C59.388 22.5925 59.6205 21.69 59.6841 21.2072C59.6941 21.1563 59.7114 21.1225 59.7355 21.1067C59.7596 21.0903 59.7976 21.0872 59.8483 21.0976C60.2943 21.1895 61.1648 21.2631 61.973 20.7243C62.6615 20.2655 63.1097 19.5458 63.2344 18.6982C63.3585 17.8582 63.1529 16.9784 62.6562 16.2218L59.8207 11.9027C59.7684 11.8228 59.6854 11.7698 59.5876 11.7534C59.4939 11.7385 59.3961 11.7591 59.319 11.8105L51.2965 17.1591C51.2185 17.2105 51.1614 17.2933 51.1395 17.3858Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60.6804 28.8496C61.0979 28.3251 61.7312 27.9831 62.4642 27.8867C62.6015 27.8687 62.7359 27.8599 62.8685 27.8599C64.1724 27.8599 65.222 28.7106 65.384 29.9615C65.5635 31.3481 64.578 32.5412 63.0919 32.7366C61.6121 32.9302 60.3646 32.0312 60.1853 30.6448C60.1007 29.9943 60.2768 29.3571 60.6804 28.8496ZM62.0521 24.8179C59.0659 25.2101 57.1179 27.8593 57.5216 30.9796C57.8898 33.8217 60.1355 35.8399 62.7826 35.8399C63.0095 35.8399 63.2404 35.8254 63.4724 35.7947C64.9286 35.6031 66.1959 34.8502 67.0408 33.6736C67.8684 32.5209 68.2191 31.0737 68.0286 29.5989C67.6315 26.5301 65.0104 24.4279 62.0521 24.8179Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 7"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M67.2156 40.5777L58.121 37.4214C58.0332 37.3908 57.9333 37.3955 57.8471 37.434C57.7562 37.4744 57.6895 37.5468 57.6584 37.6371L56.995 39.5782C56.9634 39.6708 56.9696 39.7743 57.0126 39.8628C57.0549 39.9512 57.1301 40.0175 57.2247 40.05L62.4854 41.736L55.7916 43.8013C55.6086 43.8644 55.4964 43.963 55.4488 44.1035L54.8092 45.9733C54.7471 46.1555 54.854 46.3652 55.0423 46.4302L64.1366 49.5866C64.1752 49.6001 64.2159 49.6064 64.257 49.6064C64.3096 49.6064 64.3623 49.5957 64.4105 49.5739C64.5014 49.5335 64.5682 49.4612 64.5992 49.3709L65.2629 47.4294C65.3265 47.2424 65.22 47.0227 65.0329 46.9579L59.7302 45.2571L66.3748 43.1921C66.5713 43.1283 66.7621 43.0421 66.8286 42.8476L67.4484 41.035C67.4776 40.9491 67.4713 40.855 67.4303 40.7703C67.3867 40.6803 67.3084 40.6099 67.2156 40.5777Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 8"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M53.9875 56.718L51.4642 55.4201C51.425 55.398 51.4141 55.3775 51.425 55.3605L52.922 53.9893C52.9352 53.9773 52.943 53.9748 52.943 53.9741C52.9477 53.9767 52.9618 53.9906 52.9747 54.0281L53.9875 56.718ZM53.8233 48.5663C53.7757 48.4241 53.6876 48.3294 53.5758 48.3C53.4686 48.2719 53.3542 48.306 53.2599 48.3929L51.6522 49.8657C51.5319 49.9759 51.5078 50.1316 51.5811 50.3299L52.1098 51.7008C52.1424 51.7886 52.1094 51.834 52.0775 51.8637L49.4717 54.2498C49.3849 54.3294 49.3479 54.3297 49.2665 54.2871L47.9882 53.6299C47.8193 53.5241 47.6513 53.5298 47.5275 53.6432L45.7867 55.2376C45.7021 55.3153 45.6632 55.4135 45.6767 55.5139C45.692 55.6248 45.7704 55.7233 45.8929 55.7858L55.1671 60.3231C55.21 60.3474 55.3056 60.4017 55.4175 60.4017C55.4902 60.4017 55.5698 60.3787 55.6456 60.3092L57.2423 58.8468C57.3206 58.7748 57.3968 58.6368 57.3144 58.3845L53.8233 48.5663Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 9"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48.27 62.855C48.2095 62.6722 47.9986 62.5711 47.8096 62.6346L45.3922 63.4446C45.2979 63.4762 45.1857 63.4133 45.1515 63.311L42.8867 56.4862C42.8576 56.3974 42.7934 56.32 42.7103 56.2743C42.6238 56.2263 42.526 56.2165 42.4358 56.2468L40.3596 56.9422C40.2713 56.9715 40.1948 57.0397 40.1503 57.1294C40.1058 57.2191 40.097 57.3214 40.1265 57.4108L42.3916 64.2356C42.4085 64.2874 42.4045 64.3462 42.3794 64.396C42.3574 64.4406 42.3214 64.4728 42.2782 64.4873L39.8607 65.297C39.7724 65.3264 39.6959 65.3914 39.6505 65.4745C39.6025 65.5626 39.5931 65.6608 39.6232 65.7514L40.1851 67.457C40.2355 67.6083 40.3875 67.709 40.542 67.709C40.5784 67.709 40.6154 67.7033 40.6508 67.6916L48.5994 65.0292C48.688 64.9995 48.7642 64.9313 48.8087 64.8416C48.8535 64.7519 48.862 64.6496 48.8322 64.5603L48.27 62.855Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 10"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M35.863 57.8941C35.7966 57.8277 35.7016 57.7876 35.6198 57.7924L33.4324 57.8091C33.339 57.8097 33.2456 57.8514 33.1761 57.9225C33.1062 57.9938 33.0667 58.0889 33.0676 58.1824L33.1407 67.8752C33.1422 68.0719 33.314 68.2431 33.5089 68.2431C33.5098 68.2431 33.5108 68.2431 33.5117 68.2431L35.6988 68.2261C35.7937 68.2254 35.8831 68.1844 35.9501 68.1108C36.0137 68.0407 36.0498 67.9466 36.0491 67.8528L35.9755 58.1603C35.9749 58.0617 35.9338 57.9645 35.863 57.8941Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 11"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.3791 62.2576C27.1566 62.9691 26.711 63.54 26.1243 63.8662C25.5558 64.1824 24.9015 64.2484 24.2816 64.0516C22.9585 63.6313 22.3001 62.2282 22.7505 60.7879C22.972 60.0787 23.4192 59.5106 24.0093 59.1888C24.3609 58.9971 24.7454 58.8998 25.1347 58.8998C25.376 58.8998 25.6191 58.9371 25.8573 59.0126C27.1729 59.4307 27.8269 60.8255 27.3791 62.2576ZM26.678 56.4382C25.2685 55.9895 23.795 56.0808 22.5292 56.6918C21.2343 57.3168 20.2785 58.4375 19.8379 59.8475C19.3967 61.2591 19.5405 62.7341 20.2434 64.0017C20.9319 65.244 22.0867 66.174 23.4941 66.6209C24.0776 66.8062 24.6633 66.8944 25.2349 66.8944C27.5333 66.8944 29.5978 65.4667 30.3148 63.174C30.7561 61.7625 30.6157 60.294 29.919 59.0385C29.2368 57.8085 28.0858 56.8851 26.678 56.4382Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
            <path
              id="Fill 12"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.096 53.4366C21.0919 53.3368 21.0496 53.2474 20.9775 53.1852L19.4269 51.8526C19.2724 51.7203 19.0396 51.7392 18.907 51.8955L15.4257 56.2127L15.9663 49.1794C15.975 48.9846 15.9249 48.8431 15.813 48.7471L14.3191 47.4634C14.1737 47.3387 13.9415 47.3621 13.8121 47.5149L7.57018 54.8905C7.50625 54.9656 7.47429 55.0661 7.48212 55.1662C7.48933 55.2603 7.53132 55.3446 7.59964 55.4033L9.15024 56.7356C9.22138 56.7969 9.32041 56.8294 9.41787 56.8193C9.5169 56.8117 9.60872 56.7659 9.67014 56.693L13.1813 52.3405L12.6579 59.3289C12.6451 59.5367 12.6541 59.7473 12.8102 59.8806L14.258 61.1251C14.3191 61.1778 14.3972 61.2063 14.4799 61.2063C14.4896 61.2063 14.4993 61.2056 14.509 61.205C14.6081 61.1968 14.7015 61.1491 14.7651 61.0739L21.0073 53.6981C21.0678 53.627 21.1001 53.5313 21.096 53.4366Z"
              fill={this.props.changeColor ? '#fff' : '#131514'}
            />
          </g>
        </svg>
      </Link>
    );
  }
}

export default Logotype;
