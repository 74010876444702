import styled from 'styled-components';
import createMediaQuery from '../../../assets/styles/media';

export const Address = styled.address`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  padding-left: 42px;
  height: 100%;
  padding-top: 73px;

  ${createMediaQuery().up(1440)} {
    grid-template-columns: 1fr;
    padding-right: 26px;
    padding-left: 48px;
  }

  ${createMediaQuery().down(1024)} {
    grid-template-columns: 1fr 1fr;
    padding-left: 33px;
    padding-right: 33px;
  }

  ${createMediaQuery().mobile()} {
    grid-template-columns: 1fr;
    grid-gap: 40px 20px;
    padding-top: 0;
    padding-right: 0px;
    padding-left: 24px;
  }

  h1 {
    vertical-align: top;

    ${createMediaQuery().up(1440)} {
      align-self: flex-end;

      & + section {
        align-self: flex-start;
        margin-top: 85px;
      }
    }

    ${createMediaQuery().down(1440)} {
      padding-right: 35px;

      & + section {
        padding-left: 46px;
      }
    }

    ${createMediaQuery().down(1024)} {
      & + section {
        padding-left: 33px;
      }
    }

    ${createMediaQuery().mobile()} {
      padding-right: 0;

      & + section {
        padding-left: 0;
      }
    }
  }

  section > p,
  section > div {
    display: inline-block;
    width: 50%;
  }

  section > p {
    padding-right: 10px;

    ${createMediaQuery().down(1024)} {
      padding-left: 33px;

      ${createMediaQuery().mobile()} {
        padding-left: 0;
      }
    }
  }

  section > p + div {
    padding-left: 10px;

    ${createMediaQuery().down(1440)} {
      padding-left: 56px;
    }

    ${createMediaQuery().down(1024)} {
      padding-left: 33px;
    }

    ${createMediaQuery().mobile()} {
      padding-left: 20px;
    }
  }

  section > div {
    vertical-align: top;

    a {
      position: relative;
      display: block;
      width: max-content;
      border: 0;

      & + a {
        margin-top: 20px;
      }

      &:hover::before {
        background-color: transparent;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 3px;
        right: 0;
        left: 0;
        height: 1px;
        background-color: #000;
        transition: background 150ms ease;

        ${createMediaQuery().up(1024)} {
          bottom: 4px;
        }
      }
    }
  }
`;

export const LettersContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr;
  grid-gap: 0 20px;
  height: 100%;

  ${createMediaQuery().up(1440)} {
    grid-template-columns: 2fr 2fr;
  }

  & > div {
    width: 100%;
    height: 100%;
  }

  ${createMediaQuery().down(1024)} {
    grid-template-columns: 1fr 1fr;
  }

  ${createMediaQuery().mobile()} {
    grid-template-columns: 1fr;
  }
`;

export const Section = styled.section`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 1fr;
  height: calc(100vh - 190px - 81px);
  min-height: 470px;
  padding: 0 20px;

  ${createMediaQuery().up(1440)} {
    grid-template-columns: 2fr 4fr;
    grid-template-rows: 1fr;
  }

  ${createMediaQuery().down(1024)} {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    height: calc(100vh - 263px);
    min-height: unset;
    padding-bottom: 33px;
  }

  ${createMediaQuery().mobile()} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 60px 20px 20px;
  }
`;
