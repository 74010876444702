import { IGrid } from '../../shared/Grid/Grid.models';
import { SW, TW } from '../../../assets/listOfBottles';

export const grid: IGrid[] = [
  {
    items: [
      {
        type: 'letter',
        letters: { src: ['/letters/4A_C.json', '/letters/2A_C.json', '/letters/5A_C.json'], timeout: 8800 }
      },
      {
        type: 'letter',
        letters: { src: ['/letters/1A_T.json', '/letters/7A_T.json'], timeout: 7300 }
      },
      {
        images: TW,
        image: {
          src: '/images/bottles/TW/TW_00000.jpg',
          src2x: '/images/bottles/TW/TW@x2_00000.jpg',
          webp: '/images/bottles/TW/TW@x2_00000.webp'
        },
        type: 'bottle',
        time: 30000,
        url: '/products'
      },
      {
        type: 'image',
        image: {
          alt: 'Gin Tonic',
          src: '/images/cocktails/gin-and-tonic.jpg',
          src2x: '/images/cocktails/gin-and-tonic@2x.jpg',
          webp: '/images/cocktails/gin-and-tonic@2x.webp'
        },
        url: '/cocktails'
      },
      {
        type: 'text',
        heading: 'Artisanal \ncraft mixers \nmade 100% \nin Hong Kong',
        url: ''
      },
      {
        type: 'letter',
        letters: { src: ['/letters/7A_W.json', '/letters/4A_W.json', '/letters/3A_W.json'], timeout: 9800 }
      },
      {
        type: 'text',
        heading: 'Tonic Water',
        text: 'You must be \nhaving\na\u00A0bubble.',
        url: '/products'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/1A_B.json', '/letters/5A_B.json', '/letters/6A_B.json'], timeout: 6100 },
        whenVisible: 'desktop'
      },
      {
        type: 'image',
        ingredients: [
          {
            alt: 'Lemons',
            src: '/images/ingredients/organic-lemons.jpg',
            src2x: '/images/ingredients/organic-lemons@2x.jpg',
            webp: '/images/ingredients/organic-lemons@2x.webp'
          },
          {
            alt: 'Cane sugar',
            src: '/images/ingredients/cane-sugar.jpg',
            src2x: '/images/ingredients/cane-sugar@2x.jpg',
            webp: '/images/ingredients/cane-sugar@2x.webp'
          }
        ],
        time: 5000,
        whenVisible: 'desktop',
        url: '/about'
      },
      {
        type: 'image',
        ingredients: [
          {
            alt: 'Сinamon',
            src: '/images/ingredients/cinamon.jpg',
            src2x: '/images/ingredients/cinamon@2x.jpg',
            webp: '/images/ingredients/cinamon@2x.webp'
          },
          {
            alt: 'Cardamon &\nAllspice',
            src: '/images/ingredients/cardamon-and-allspice.jpg',
            src2x: '/images/ingredients/cardamon-and-allspice@2x.jpg',
            webp: '/images/ingredients/cardamon-and-allspice@2x.webp'
          },
          {
            alt: 'Tangerine peels',
            src: '/images/ingredients/tangerine-peels.jpg',
            src2x: '/images/ingredients/tangerine-peels@2x.jpg',
            webp: '/images/ingredients/tangerine-peels@2x.webp'
          }
        ],
        time: 6333,
        whenVisible: 'desktop',
        url: '/about'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/5A_N.json', '/letters/6A_N.json', '/letters/2A_N.json'], timeout: 7600 },
        whenVisible: 'desktop'
      }
    ]
  },
  {
    items: [
      {
        type: 'letter',
        letters: { src: ['/letters/1A_S.json', '/letters/2A_S.json', '/letters/7A_S.json'], timeout: 10200 }
      },
      {
        images: SW,
        image: {
          src: '/images/bottles/SW/SW_00000.jpg',
          src2x: '/images/bottles/SW/SW@x2_00000.jpg',
          webp: '/images/bottles/SW/SW@x2_00000.webp'
        },
        type: 'bottle',
        time: 37000,
        url: '/products'
      },
      {
        type: 'image',
        image: {
          alt: 'Highball',
          src: '/images/cocktails/whiskey-highball.jpg',
          src2x: '/images/cocktails/whiskey-highball@2x.jpg',
          webp: '/images/cocktails/whiskey-highball@2x.webp'
        },
        url: '/cocktails'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/3A_T.json', '/letters/1A_T.json', '/letters/4A_T.json'], timeout: 7800 },
        whenVisible: 'desktop'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/1A_W.json', '/letters/2A_W.json', '/letters/5A_W.json'], timeout: 8600 }
      },
      {
        heading: 'Smoked\nWater',
        text: 'Considerably\nbubbly\u00A0once\nyou\u00A0open me \nup\u00A0and\u00A0get\nto know me.',
        type: 'text',
        url: '/products'
      },
      {
        type: 'image',
        image: {
          alt: 'Lychee',
          src: '/images/ingredients/lychee-wood-scent.jpg',
          src2x: '/images/ingredients/lychee-wood-scent@2x.jpg',
          webp: '/images/ingredients/lychee-wood-scent@2x.webp'
        },
        whenVisible: 'desktop',
        url: '/about'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/6A_C.json', '/letters/3A_C.json', '/letters/1A_C.json'], timeout: 8400 }
      },
      {
        type: 'image',
        image: {
          alt: 'Pink salt',
          src: '/images/ingredients/pink-salt.jpg',
          src2x: '/images/ingredients/pink-salt@2x.jpg',
          webp: '/images/ingredients/pink-salt@2x.webp'
        },
        text: 'Pink salt',
        whenVisible: 'desktop',
        url: '/about'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/1A_B.json', '/letters/6A_B.json', '/letters/3A_B.json'], timeout: 13300 },
        whenVisible: 'desktop'
      },
      {
        heading: 'Remember,\nlife is\na marathon\n& not a sprint.',
        type: 'text'
      },
      {
        type: 'address',
        heading: 'The Carbonation\nLimited',
        url:
          'https://www.google.com/maps/place/Max+Trade+Centre/@22.337066,114.2001557,15z/data=!4m5!3m4!1s0x0:0x2792a59da588fe83!8m2!3d22.337066!4d114.2001557'
      },
      {
        type: 'address',
        text: '701\n Max Trade Centre, \n23 Luk Hop Street,\n\nSon Po Kong\nKowloon, \nHong Kong',
        url:
          'https://www.google.com/maps/place/Max+Trade+Centre/@22.337066,114.2001557,15z/data=!4m5!3m4!1s0x0:0x2792a59da588fe83!8m2!3d22.337066!4d114.2001557'
      },
      {
        type: 'external_link',
        urls: [
          { url: '/', name: 'Facebook' },
          {
            url: 'https://BWw.instagram.com/thecarbonation/',
            name: 'Instagram'
          }
        ]
      },
      {
        type: 'letter',
        letters: { src: ['/letters/4A_N.json', '/letters/2A_N.json', '/letters/1A_N.json'], timeout: 8700 },
        whenVisible: 'desktop'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/7A_T.json', '/letters/6A_T.json', '/letters/5A_T.json'], timeout: 8200 },
        whenVisible: 'desktopLarge'
      },
      {
        type: 'letter',
        letters: { src: ['/letters/4A_C.json', '/letters/5A_C.json', '/letters/2A_C.json'], timeout: 7500 },
        whenVisible: 'desktopLarge'
      }
    ]
  }
];
