export const cofeeTonic = [
  {
    alt: 'Cofee Tonic',
    src: '/images/cocktails/cofee-tonic_1.jpg',
    src2x: '/images/cocktails/cofee-tonic_1@2x.jpg',
    webp: '/images/cocktails/cofee-tonic_1@2x.webp'
  },
  {
    alt: 'Cofee Tonic',
    src: '/images/cocktails/cofee-tonic_2.jpg',
    src2x: '/images/cocktails/cofee-tonic_2@2x.jpg',
    webp: '/images/cocktails/cofee-tonic_3@2x.webp'
  },
  {
    alt: 'Cofee Tonic',
    src: '/images/cocktails/cofee-tonic_3.jpg',
    src2x: '/images/cocktails/cofee-tonic_3@2x.jpg',
    webp: '/images/cocktails/cofee-tonic_3@2x.webp'
  },
  {
    alt: 'Cofee Tonic',
    src: '/images/cocktails/cofee-tonic_4.jpg',
    src2x: '/images/cocktails/cofee-tonic_4@2x.jpg',
    webp: '/images/cocktails/cofee-tonic_4@2x.webp'
  }
];

export const whiskeyHighball = [
  {
    alt: 'Highball',
    src: '/images/cocktails/whiskey-highball_1.jpg',
    src2x: '/images/cocktails/whiskey-highball_1@2x.jpg',
    webp: '/images/cocktails/whiskey-highball_1@2x.webp'
  },
  {
    alt: 'Highball',
    src: '/images/cocktails/whiskey-highball_2.jpg',
    src2x: '/images/cocktails/whiskey-highball_2@2x.jpg',
    webp: '/images/cocktails/whiskey-highball_2@2x.webp'
  },
  {
    alt: 'Highball',
    src: '/images/cocktails/whiskey-highball_3.jpg',
    src2x: '/images/cocktails/whiskey-highball_3@2x.jpg',
    webp: '/images/cocktails/whiskey-highball_3@2x.webp'
  }
];

export const ginTonic = [
  {
    alt: 'Gin tonic',
    src: '/images/cocktails/gin-and-tonic_1.jpg',
    src2x: '/images/cocktails/gin-and-tonic_1@2x.jpg',
    webp: '/images/cocktails/gin-and-tonic_1@2x.webp'
  },
  {
    alt: 'Gin tonic',
    src: '/images/cocktails/gin-and-tonic_2.jpg',
    src2x: '/images/cocktails/gin-and-tonic_2@2x.jpg',
    webp: '/images/cocktails/gin-and-tonic_2@2x.webp'
  },
  {
    alt: 'Gin tonic',
    src: '/images/cocktails/gin-and-tonic_3.jpg',
    src2x: '/images/cocktails/gin-and-tonic_3@2x.jpg',
    webp: '/images/cocktails/gin-and-tonic_3@2x.webp'
  }
];
