import React from 'react';
import { GridContainer } from './Grid.styles';

interface IGridProps {
  children: React.ReactNode;
  forwardRef?: any;
}

const Grid = ({ children, forwardRef }: IGridProps) => {
  return <GridContainer ref={forwardRef}>{children}</GridContainer>;
};

export default Grid;
