import React, { useEffect, useCallback, useState } from 'react';
import { gsap } from 'gsap';
import { IImage } from '../../shared/Grid/Grid.models';
import { withUI, UI } from '../../../utils/UIContext';

interface Props {
  activeSide: '' | 'leftSide' | 'rightSide';
  colWidth: number;
  image: IImage;
  side: 'leftSide' | 'rightSide';
  ui: UI;
}

const BottleImage = (props: Props) => {
  const { activeSide, colWidth, image, side } = props;
  const { isMobile, tabletOrientation, windowWidth } = props.ui;
  const duration = 0.35;
  const [height, setHeight] = useState<number>(0);
  const elementRef = React.useRef<HTMLImageElement>(null);

  const shift = useCallback(
    (resize?: boolean) => {
      if (!elementRef.current || isMobile) return;

      const el = elementRef.current;

      const x = (colWidth + 40) / 2;
      const xForActive = x * 2 - 40;
      const tabletHasPortraitOrientation = tabletOrientation === 'portraitOrientation';
      const tabletHasLandscapeOrientation = tabletOrientation === 'landscapeOrientation';
      const y = tabletHasPortraitOrientation
        ? x
        : window.innerHeight - height * (tabletHasLandscapeOrientation ? 0.7 : 0.4);

      const setScale = window.innerWidth >= 1920 || tabletHasLandscapeOrientation;
      const scale = setScale ? 1.3 : 1;

      // IF LEFT SIDE ACTIVE
      if (activeSide === 'leftSide') {
        if (side === 'leftSide') {
          /** Left bottle animation */
          gsap.to(el, { x: 0, y: 0, scale: 1, duration: resize ? 0 : duration });
        } else {
          /** Right bottle animation */
          gsap.to(el, { x: windowWidth >= 1440 ? -xForActive : -x, y: y, scale, duration: resize ? 0 : duration });
        }
      }
      // IF RIGHT SIDE ACTIVE
      else if (activeSide === 'rightSide') {
        if (side === 'leftSide') {
          /** Left bottle animation */
          gsap.to(el, { x: windowWidth >= 1440 ? -xForActive : -x, y: y, scale, duration: resize ? 0 : duration });
        } else {
          /** Right bottle animation */
          gsap.to(el, { x: 0, y: 0, scale: 1, duration: resize ? 0 : duration });
        }
      } else if (!tabletHasLandscapeOrientation) {
        /** INIT */
        gsap.set(el, { x: -x, y: y, scale });
      }
    },
    [activeSide, height, colWidth, side, isMobile, tabletOrientation, windowWidth]
  );

  useEffect(() => {
    shift();
  }, [shift]);

  return (
    <picture>
      <source srcSet={image.webp} type="image/webp" />
      <source srcSet={`${image.src}, ${image.src2x} 2x`} type="image/jpeg" />

      <img
        alt={image.alt}
        ref={elementRef}
        src={image.src}
        srcSet={`${image.src}, ${image.src2x} 2x`}
        onLoad={() => elementRef.current && setHeight(elementRef.current.offsetHeight)}
      ></img>
    </picture>
  );
};

export default withUI(BottleImage);
