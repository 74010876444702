export default function createMediaQuery() {
  function up(from: any) {
    return `@media (min-width: ${from || 0}px)`;
  }

  function down(from: any) {
    return `@media (max-width: ${from > 0 ? from - 2 / 100 : 0}px)`;
  }

  function between(from: any, to: any) {
    return `@media (min-width: ${from}px) and (max-width: ${to - 2 / 100}px)`;
  }

  function mobile() {
    return `@media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px)`;
  }

  return {
    up,
    down,
    between,
    mobile
  };
}
