import React from 'react';
import { gsap } from 'gsap';
import { Heading, Image, Progress, ProgressDot, Wrapper } from './Gallery.styles';
import { withUI, UI } from '../../../utils/UIContext';

const duration = 1.3;

interface IGalleryProps {
  activeIndex: number;
  heading?: string;
  images: any[];
  withProgress?: boolean;
  progressIsHidden?: boolean;
  ui: UI;
}

interface IGalleryState {
  activeIndex: number;
  isAnimating: boolean;
}

class Gallery extends React.Component<IGalleryProps, IGalleryState> {
  state: IGalleryState = {
    activeIndex: this.props.activeIndex,
    isAnimating: false
  };

  transitionID!: number;
  private images: HTMLImageElement[] = [];

  changeImages = (activeIndex: number, init?: boolean, prevIndex?: number) => {
    if (!this.images || this.state.isAnimating) return;

    if (init) {
      gsap.set(this.images[activeIndex], { webkitClipPath: `circle(100% at 50% 50%)`, zIndex: 2 });
    } else {
      const onStart = () => {
        this.setState({ isAnimating: true });
        gsap.set(this.images[prevIndex ? prevIndex : 0], { zIndex: 1 });
      };
      const onComplete = () => {
        this.setState({ isAnimating: false });
        gsap.set(this.images[prevIndex ? prevIndex : 0], { webkitClipPath: `circle(0% at 100% 100%)`, zIndex: 1 });
      };

      gsap.fromTo(
        this.images[activeIndex],
        { webkitClipPath: `circle(0% at 100% 100%)`, duration, zIndex: 2, onStart },
        { webkitClipPath: `circle(100% at 50% 50%)`, duration, onComplete }
      );
    }
  };

  componentDidMount() {
    this.changeImages(this.props.activeIndex, true);
  }

  componentDidUpdate(prevProps: IGalleryProps) {
    const { activeIndex } = this.props;
    const { isAnimating } = this.state;

    if (activeIndex !== prevProps.activeIndex) {
      if (!isAnimating) {
        this.changeImages(activeIndex, false, prevProps.activeIndex);
      } else {
        clearTimeout(this.transitionID);
        this.transitionID = setTimeout(() => {
          this.changeImages(activeIndex, false, prevProps.activeIndex);
        }, duration * 1000);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.transitionID);
  }

  render() {
    const { activeIndex, heading, images, progressIsHidden, withProgress } = this.props;
    const { isMobile } = this.props.ui;

    return (
      <Wrapper>
        {heading && (
          <Heading color={(images && images[activeIndex] && images[activeIndex].textColor) || '#161616'}>
            <b>{heading}</b>
          </Heading>
        )}
        {images.map((img: any, index: number) => (
          <picture key={index}>
            {/* <source srcSet={img.webp} type="image/webp" /> */}
            <source data-srcset={img.webp} type="image/webp" />
            <source data-srcset={`${img.src}, ${img.src2x} 2x`} type="image/jpeg" />
            <Image
              alt={img.alt}
              // loading={'lazy'}
              // src={img.src}
              // srcSet={`${img.src}, ${img.src2x} 2x`}

              data-sizes="auto"
              data-src={img.src}
              data-srcset={`${img.src}, ${img.src2x} 2x`}
              className="lazyload"
              ref={(el: HTMLImageElement) => (this.images[index] = el)}
            />
          </picture>
        ))}

        {isMobile && withProgress && (
          <Progress>
            {images.map((_img: any, index: number) => (
              <ProgressDot active={activeIndex === index} key={index} visible={!progressIsHidden}></ProgressDot>
            ))}
          </Progress>
        )}
      </Wrapper>
    );
  }
}

export default withUI(Gallery);
