import React from 'react';
import * as ReactDOM from 'react-dom';
import { gsap } from 'gsap';
import { BubblesContainer, Bubble } from './Bubbles.styles';
import { withUI, UI } from '../../../utils/UIContext';

interface IBubblesProps {
  active: boolean;
  bottom?: number;
  innerRef?: HTMLElement;
  withTimeout?: boolean;
  ui: UI;
}

interface IBubblesState {
  countOfBubbles: number;
  initialPositon: Array<{ bottom: number; left: number }>;
}

class BubblesT extends React.Component<IBubblesProps, IBubblesState> {
  state: IBubblesState = {
    countOfBubbles: 0,
    initialPositon: []
  };

  private bubbles = React.createRef<HTMLDivElement>();
  timeoutID!: number | undefined;

  bubblesGenerator = (min: number, max: number) => {
    const countOfBubbles = Math.round(min + Math.random() * (max - min));
    this.setState({ countOfBubbles });
    this.bubblesInitialPosition(countOfBubbles);
  };

  bubblesInitialPosition = (countOfBubbles: number) => {
    let initialPositon = this.state.initialPositon;
    const rightindent = 100;

    for (let i = 0; i < countOfBubbles; i++) {
      const bottom = Math.floor(Math.random() * (100 - i + 1)) + i; // only px
      let left = Math.floor(Math.random() * (this.props.ui.windowWidth - rightindent - i) + i); // get px
      left = (left / this.props.ui.windowWidth) * 100; // get percentage
      initialPositon.push({ bottom, left });
    }

    this.setState({ initialPositon });
  };

  aniomateBubbles = () => {
    if (!this.bubbles.current) return;


    if (this.props.ui.isMobile || this.props.ui.isTablet) document.body.style.overflow = 'hidden';

    gsap.to(this.bubbles.current.children, {
      y: -window.innerHeight - 200,
      x: gsap.utils.wrap([100, -100]),
      duration: 2,
      stagger: {
        amount: 1.5,
        ease: 'Linear.easeNone',
        from: this.state.countOfBubbles / 2
      },
      onComplete: () => {
        this.props.ui.changePage(false);
        if (this.props.ui.isMobile || this.props.ui.isTablet) document.body.style.overflow = '';
      }
    });
  };

  componentDidMount() {
    if (this.props.withTimeout) {
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => this.bubblesGenerator(42, 50), 10);
    } else {
      this.bubblesGenerator(42, 50);
    }
  }

  componentDidUpdate(prevProps: IBubblesProps) {
    if (this.props.active && !prevProps.active) {
      if (this.props.withTimeout) {
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() => this.aniomateBubbles(), 100);
      } else {
        this.aniomateBubbles();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const { countOfBubbles, initialPositon } = this.state;

    if (!this.props.active) return null;

    return ReactDOM.createPortal(
      <BubblesContainer changePosition={this.props.ui.isTablet || this.props.ui.isMobile} forwardRef={this.bubbles}>
        {Array.from({ length: countOfBubbles }).map((_b: any, key: number) => (
          <Bubble key={key} left={initialPositon[key].left} bottom={initialPositon[key].bottom}></Bubble>
        ))}
      </BubblesContainer>,
      this.props.innerRef ? this.props.innerRef : document.body
    );
  }
}

export default withUI(BubblesT);
