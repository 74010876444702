import * as React from 'react';
import { Subscription, fromEvent, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

export interface UI {
  animateChangePage: boolean;
  isMobile: boolean;
  isTablet: boolean;
  tabletOrientation: string | null;
  isWindowResizing: boolean;
  pageScrollTop: number;
  resizeObservable: Subject<Event>;
  windowHeight: number;
  windowWidth: number;
  changePage: Function;
  setPageScrollTop: Function;
}

const isMobile = () => {
  return window.matchMedia
    ? window.matchMedia('(max-width: 767.98px), (max-width: 896px) and (max-height: 414px)').matches
    : window.innerWidth < 768 || (window.innerWidth <= 896 && window.innerHeight <= 414);
};


const isTablet = () => !!navigator.userAgent.match(/Tablet|iPad|iPod/i);

const tabletOrientation = () => {
  return window.matchMedia(
    '(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)'
  ).matches
    ? 'portraitOrientation'
    : window.matchMedia(
        '(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)'
      ).matches
    ? 'landscapeOrientation'
    : null;
};

export const UiContext = React.createContext<UI>({
  animateChangePage: false,
  isMobile: isMobile(),
  isTablet: isTablet(),
  tabletOrientation: tabletOrientation(),
  isWindowResizing: false,
  resizeObservable: new Subject(),
  pageScrollTop: 0,
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  changePage: () => {},
  setPageScrollTop: () => {}
});
export const withUIProvider = (Component: any) => {
  return class extends React.Component<any, UI> {
    state: UI = {
      animateChangePage: false,
      isMobile: isMobile(),
      isTablet: isTablet(),
      tabletOrientation: tabletOrientation(),
      isWindowResizing: false,
      pageScrollTop: 0,
      resizeObservable: new Subject(),
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      changePage: (change: boolean) => this.changePage(change),
      setPageScrollTop: (scroll: number) => this.setPageScrollTop(scroll)
    };

    resizeSubscription!: Subscription;

    setPageScrollTop(pageScrollTop: number) {
      this.setState({ pageScrollTop });
    }

    changePage(change: boolean) {
      this.setState({ animateChangePage: change });
    }

    handleWindowResize = () => {
      const { innerWidth, innerHeight } = window;

      this.setState({
        windowWidth: innerWidth,
        windowHeight: innerHeight,
        isMobile: isMobile(),
        isTablet: isTablet(),
        tabletOrientation: tabletOrientation()
      });
    };

    componentDidMount() {
      this.resizeSubscription = fromEvent(window, 'resize')
        .pipe(throttleTime(100, undefined, { trailing: true }))
        .subscribe(() => {
          this.handleWindowResize();
          this.state.resizeObservable.next();
        });
    }

    componentWillUnmount() {
      this.resizeSubscription.unsubscribe();
    }

    render() {
      return (
        <UiContext.Provider value={this.state}>
          <Component {...this.props} />
        </UiContext.Provider>
      );
    }
  };
};

export const withUI = (Component: any) => {
  return function withUI(props: any) {
    return <UiContext.Consumer>{({ ...ui }) => <Component {...props} ui={{ ...ui }} />}</UiContext.Consumer>;
  };
};
