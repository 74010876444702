import styled from 'styled-components';
import { GridContainer, gap, indent } from '../../shared/Grid/Grid.styles';
import createMediaQuery from '../../../assets/styles/media';

export const columnsX = 6;
export const columns = 4;
export const columnsM = 2;

export const Block = styled.div<any>`
  pointer-events: ${props => (props.active ? 'auto' : 'none')};

  ${createMediaQuery().mobile()} {
    pointer-events: auto;
  }

  & > div {
    background-color: ${props => (props.side === 'leftSide' ? '#000' : '#fff')};
    transform: translate3d(
      ${props => (props.side === 'rightSide' ? `calc((-100vw - ${gap * 2}px) / ${columns})` : 0)},
      0,
      0
    );

    ${createMediaQuery().up(1440)} {
      transform: translate3d(
        ${props => (props.side === 'rightSide' ? `calc((-100vw - ${gap * 3}px - ${indent * 2}px) / ${columnsX})` : 0)},
        0,
        0
      );
    }

    ${createMediaQuery().mobile()} {
      transform: none;
    }
  }

  h2,
  h6,
  p {
    color: ${props => (props.side === 'leftSide' ? '#fff' : '#000')};
  }
`;

export const Column = styled.div<any>`
  text-align: ${props => (props.columnType === 'bottle' ? 'center' : '')};

  ${createMediaQuery().mobile()} {
    display: ${props => (props.horizontal ? 'inline-block' : 'block')};
    width: ${props => (props.horizontal ? `calc(50% - 10px)` : 'auto')};
    vertical-align: top;
  }

  & > picture > img {
    width: 100%;
    max-width: 420px;
    padding-top: 46px;

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      transform: translate(calc(((-100vw - 100px) / 4 + 40px) / 2), calc((100vw - 100px) / 4)) scale(1.3);
    }
  }

  & + & {
    padding-top: 20px;

    ${createMediaQuery().mobile()} {
      padding-top: 0;
      margin-left: ${props => (props.horizontal ? '20px' : 0)};
    }
  }

  & > div > h2 {
    padding: 46px 0 0 15%;
    white-space: normal;

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      font-size: 42px;
      height: 105px;

      b {
        position: absolute;
      }
    }

    ${createMediaQuery().down(1024)} {
      padding: 33px 0 0 33px;
    }

    ${createMediaQuery().mobile()} {
      height: auto;
      padding: 24px 0 0 24px;

      b {
        position: static;
      }
    }
  }

  & > div > h2 + p,
  & > p {
    padding: 46px 15% 0;

    ${createMediaQuery().down(1024)} {
      padding: 33px 0 0 33px;
    }

    ${createMediaQuery().mobile()} {
      padding: 24px 24px 0;
    }
  }

  & > p {
    ${createMediaQuery().mobile()} {
      opacity: 1;
    }
  }

  & > div > h2 + p {
    margin-top: 35px;
    padding-top: 0;
    font-size: 24px;
    line-height: 28px;
    transform: translateY(20px);
    opacity: 0;

    & + div {
      margin-top: 10px;
    }

    ${createMediaQuery().mobile()} {
      opacity: 1;
      transform: translateY(0);
      font-size: 14px;
      line-height: 20px;
    }
  }

  & > div > a {
    display: block;
    padding: 46px 40px 0 15%;

    ${createMediaQuery().down(1024)} {
      padding: 33px 20px 0 33px;
    }

    ${createMediaQuery().mobile()} {
      padding: 24px 24px 0;
    }
  }
`;

export const Grid = styled(GridContainer)`
  position: relative;
  grid-template-areas:
    'column0 column1 column2'
    'column0 column1 column2'
    'column0 column1 .';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, minmax(calc((100vw - 140px) / 6), auto));
  padding-top: 81px;

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: calc(20px + 20pt);
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: calc(20px + 20pt);
  }

  ${props =>
    !props.active &&
    `&::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;    
      height: 100%;
      min-height:100vh;
      z-index: 2;
      cursor: pointer;
      pointer-events: auto;

      ${createMediaQuery().mobile()} {
        display: none;
      }
    }`}

  ${createMediaQuery().up(1440)} {
    grid-template-areas:
      'column0 column1 column1 column2'
      'column0 column1 column1 column2'
      'column0 column1 column1 .';
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, minmax(calc((100vw - 140px) / 6), auto));
  }

  ${createMediaQuery().mobile()} {
    grid-template-areas:
      'column0 column1'
      'column0 column1'
      'column2 column2';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    padding: ${props => (props.side === 'leftSide' ? 81 + gap : 20)}px 20px 50px;
  }
`;

export const Container = styled.div`
  display: grid;
  width: calc(100vw + ((100vw + ${gap}px + ${indent * 2}px) / ${columns}) * 2);
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${gap}px;

  ${createMediaQuery().up(1440)} {
    width: calc(100vw + ((100vw + ${gap * 3}px + ${indent * 2}px) / ${columnsX}) * 2);
  }

  ${createMediaQuery().mobile()} {
    width: 100%;
    grid-gap: 0 ${gap}px;
    grid-template-columns: 1fr;
  }
`;

export const StartElement = styled.div<any>`
  position: absolute;
  top: ${props => (props.bottom ? 'auto' : '110px')};
  right: ${props => (props.bottom ? 'auto' : `calc((100vw - ${gap * (columns - 1)}px) /${columns} + ${indent * 2}px)`)};
  bottom: ${props => (props.bottom ? `${indent}px` : 'auto')};
  left: ${props => (props.bottom ? `${indent}px` : 'auto')};
  width: calc((100vw - ${gap * (columns - 1) + indent * 2}px) / ${columns});
  height: calc((100vw - ${gap * (columns - 1) + indent * 2}px) / ${columns});
  pointer-events: none;
  z-index: 10;

  ${createMediaQuery().up(1440)} {
    right: ${props =>
      props.bottom ? 'auto' : `calc((100vw - ${gap * (columnsX - 1)}px) /${columnsX} + ${indent * 2}px)`};
    width: calc((100vw - ${gap * (columnsX - 1) + indent * 2}px) / ${columnsX});
    height: calc((100vw - ${gap * (columnsX - 1) + indent * 2}px) / ${columnsX});
  }

  ${createMediaQuery().mobile()} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;

  ${createMediaQuery().mobile()} {
    bottom: auto;
  }
`;
