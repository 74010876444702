import React from "react";
import { NavLink } from "react-router-dom";
import { grid } from "./HomeGridItems";
import { ExternalLink, Text, Wrapper } from "./Home.styles";
import WhereYouCanTry from "../../where-you-can-try/WhereYouCanTry";
import ContactsForm from "../../form/ContactsForm";
import AnimatedBottle from "../../shared/AnimatedBottle/AnimatedBottle";
import Grid from "../../shared/Grid/Grid";
import GridItem from "../../shared/Grid/GridItem";
import { IGrid, IGridItem, IUrl } from "../../shared/Grid/Grid.models";
import Image from "../../shared/Image/Image";
import Ingredients from "../../shared/Ingredients/Ingredients";
import Letters from "../../shared/Letters/Letters";
import { withUI, UI } from "../../../utils/UIContext";

interface IHomeProps {
  ui: UI;
  scrollTo: Function;
}

class Home extends React.Component<IHomeProps> {
  filterGridItems = (item: IGridItem) => {
    const desktopX = item.whenVisible === "desktopLarge";
    const desktop = item.whenVisible === "desktop";
    const mobile = item.whenVisible === "mobile";
    return this.props.ui.isMobile ? !desktop && !desktopX : !mobile;
  };

  render() {
    return (
      <>
        <Wrapper>
          {grid.map((grid: IGrid, key: number) => (
            <Grid key={key}>
              {grid.items
                .filter((item: IGridItem) => this.filterGridItems(item))
                .map((item: IGridItem, index: number) => (
                  <GridItem
                    gridArea={`${item.type}` + index}
                    key={index}
                    className={`${item.type}` + index}
                    position={item.type === "image" ? "relative" : "static"}
                    visible={item.whenVisible}
                  >
                    {item.type !== "external_link" &&
                    item.type !== "address" &&
                    item.url ? (
                      <NavLink to={item.url}>
                        {item.type === "image" && item.ingredients && (
                          <Ingredients
                            images={item.ingredients}
                            timeout={item.time}
                          ></Ingredients>
                        )}

                        {item.type === "image" && !item.ingredients && (
                          <Image
                            hasInnerIndent
                            heading={item.image ? item.image.alt : ""}
                            image={item.image}
                          />
                        )}

                        {item.type === "bottle" && (
                          <AnimatedBottle
                            autoplay
                            images={
                              !this.props.ui.isMobile
                                ? item.images
                                : [item.image]
                            }
                            timeout={item.time}
                          />
                        )}

                        {item.type === "text" && (
                          <Text>
                            {item.heading && (
                              <h6>
                                <b>{item.heading}</b>
                              </h6>
                            )}
                            {item.text && <p>{item.text}</p>}
                          </Text>
                        )}
                      </NavLink>
                    ) : (
                      <>
                        {item.type === "letter" && item.letters && (
                          <Letters
                            autoplay={true}
                            letters={item.letters.src}
                            timeoutBetweenLoop={item.letters.timeout}
                          ></Letters>
                        )}

                        {item.type === "text" && (
                          <Text>
                            {item.heading && (
                              <h6>
                                <b>{item.heading}</b>
                              </h6>
                            )}
                            {item.text && <p>{item.text}</p>}
                          </Text>
                        )}

                        {item.type === "address" && (
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Text>
                              {item.heading && (
                                <h6>
                                  <b>{item.heading}</b>
                                </h6>
                              )}
                              {item.text && <p>{item.text}</p>}
                            </Text>
                          </a>
                        )}

                        {item.type === "external_link" && item.urls && (
                          <ExternalLink>
                            {item.urls.map((l: IUrl, k: number) => (
                              <li key={k}>
                                <a
                                  href={l.url}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {l.name}
                                </a>
                              </li>
                            ))}
                          </ExternalLink>
                        )}
                      </>
                    )}
                  </GridItem>
                ))}
            </Grid>
          ))}
        </Wrapper>

        <ContactsForm scrollTo={this.props.scrollTo}></ContactsForm>
        <WhereYouCanTry scrollTo={this.props.scrollTo}></WhereYouCanTry>
      </>
    );
  }
}

export default withUI(Home);
