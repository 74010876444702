import styled from 'styled-components';
export const size = 11.85;

export const StyledArrow = styled.div<any>`
  height: ${size * 3}px;
  margin-top: 20px;

  span {
    display: block;
    width: ${size}px;
    height: ${size}px;
    border-radius: 50%;
    border: 3px solid ${props => props.color || '#fff'};

    &:nth-child(2) {
      margin-left: ${size}px;
    }
  }
`;
