import styled from 'styled-components';

export const Container = styled.div<any>`
  position: relative;
  width: 105%;
  height: 105%;
  z-index: 1;

  svg {
    position: absolute;
    top: -5%;
    right: -5%;
    bottom: -5%;
    left: -5%;
    margin: auto;
    pointer-events: none;

    &:nth-child(n + 2) {
      display: ${props => (props.hideElements ? 'none' : '')};
      opacity: ${props => (props.hideElements ? 0 : 1)};
    }
  }
`;
