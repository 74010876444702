import React from 'react';
import { TexFieldContainer, TexField } from './Textfield.styles';
import EnterTextIndicator from '../EnterTextIndicator/EnterTextIndicator';

export interface TextfieldProps {
  autoComplete?: string;
  form?: string;
  placeholder?: string;
  name: string;
  type?: string;
  value: string;
  onChange: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
export interface TextfieldState {
  error: boolean;
  pristine: boolean;
  errorMessage?: string;
}

class Texfield extends React.Component<TextfieldProps, TextfieldState> {
  state = { error: false, errorMessage: '', pristine: true };

  errorMessages = {
    nameError: 'Input Your Name',
    phoneError: 'Incorrect phone number',
    emailError: 'Incorrect email'
  };
  input = React.createRef<HTMLInputElement>();

  handleBlur = () => {
    const { nameError, phoneError, emailError } = this.errorMessages;
    const error = this.input.current ? !this.input.current.checkValidity() : false;

    this.setState({ error }, () => {
      if (!this.state.error) return;

      if (this.props.name === 'name') this.setState({ errorMessage: nameError });
      if (this.props.name === 'phone') this.setState({ errorMessage: phoneError });
      if (this.props.name === 'email') this.setState({ errorMessage: emailError });
    });
  };

  render() {
    const { autoComplete, form, name, placeholder, type, value, onChange } = this.props;
    const { error, pristine } = this.state;
    const pattern = type === 'email' ? '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$' : null;

    return (
      <TexFieldContainer>
        <TexField
          autocorrect="off"
          aria-invalid={error}
          aria-label="Textfield"
          aria-required={true}
          autoComplete={autoComplete}
          error={this.state.error}
          form={form}
          name={name}
          placeholder={placeholder}
          ref={this.input}
          required={true}
          pattern={pattern}
          type={type}
          value={value}
          onChange={onChange}
          onFocus={() => this.setState({ pristine: false })}
          onBlur={this.handleBlur}
        />

        <EnterTextIndicator active={value || !pristine} value={value} error={error}></EnterTextIndicator>
      </TexFieldContainer>
    );
  }
}

export default Texfield;
